import { FC, ReactNode, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FaQuestion } from 'react-icons/fa';
import { alertError, alertSuccess } from '../../services/alert';
import { useAccessToken } from '../../utils/use-access-token';
import getEnv from '../../utils/get-env';
import { TestID } from '../../utils/constants/DataTestId';

type DeletePersonalDataModalProps = {
  runId: uuid;
  deleteUserCallback?: () => void;
  defaultOpen?: boolean;
  children?: ReactNode;
};

const DeletePersonalDataModal: FC<DeletePersonalDataModalProps> = ({
  runId,
  deleteUserCallback,
  children,
  defaultOpen = false,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(defaultOpen);
  const { accessToken } = useAccessToken();

  const handleDelete = () => {
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Token ${accessToken}`);
    myHeaders.append('content-type', 'application/json');

    const requestOptions: RequestInit = {
      method: 'PATCH',
      headers: myHeaders,
    };

    fetch(
      `${getEnv(
        'REACT_APP_API_URL'
      )}/mail-automation/wmas/${runId}/deleteclientdata`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          alertSuccess(
            'Die Personenbezogenen Daten werden gelöscht. Dies kann einige Minuten dauern.',
            TestID.SuccessAlert
          );
        }
      })
      .catch((error) =>
        alertError(
          'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.',
          error
        )
      )
      .finally(() => deleteUserCallback?.());
    setIsModalVisible(false);
  };

  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => setIsModalVisible(true)}
      >
        {children}
      </span>
      <Modal
        data-testid={TestID.DeletePersonalDataModal}
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
      >
        <Modal.Body>
          <Row className="mb-2 mt-4">
            <Col lg={2} className="text-center">
              <FaQuestion size={32} />
            </Col>
            <Col lg={10}>
              <h3>Personenbezogene Daten löschen?</h3>
              <p>
                Wenn Sie diesen Dialog mit &quot;Bestätigen&quot; beenden,
                bleibt der Datensatz anonymisiert erhalten, die
                personenbezogenen Daten des Nutzers werden jedoch gelöscht, da
                es keine weitere Berechtigung zur Kontaktaufnahme gibt.
                <br />
                <br />
                Etwaige Überorganisationen von denen das iib als
                Datenverarbeiter beauftragt wurde werden automatisiert zur
                Löschung aufgefordert.
                <br />
                <br />
                Sollten Sie die personenbezogenen Daten des Nutzers in anderen
                Systemen (z.B. CRM, Excel, etc.) abgespeichert haben, müssen Sie
                diese gesondert löschen.
                <br />
                <br />
                Gelöschte Daten können nicht wiederhergestellt werden.
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
          <Button
            data-testid={TestID.DeletePersonalDataModalAbbrechenButton}
            variant="primary"
            onClick={() => setIsModalVisible(false)}
          >
            Abbrechen
          </Button>
          <Button
            data-testid={TestID.DeletePersonalDataModalBestätigenButton}
            variant="primary"
            onClick={handleDelete}
          >
            Bestätigen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeletePersonalDataModal;
