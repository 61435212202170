export type AddressCategory =
  | 'PRIMARY'
  | 'REALTOR'
  | 'FINANCE'
  | 'BAUSPAREN'
  | 'INSURANCE';

export const PRIMARY_LABEL = 'Primärkundenberater';
export const REALTOR_LABEL = 'Makler';
export const FINANCE_LABEL = 'Finanzieren';
export const BAUSPAREN_LABEL = 'Bausparen';
export const INSURANCE_LABEL = 'Versichern';
