import { Line } from '@ant-design/charts';
import { MarkerCfg } from '@antv/g2/lib/interface';
import gql from 'graphql-tag';
import moment from 'moment';
import { FC, useMemo } from 'react';
import Box from '../Box';

import DiagramWrapper from '../DiagramWrapper';
import Loading from '../Loading';
import {
  makeSquareMarker,
  STANDALONE_CHART_COLORS,
} from '../../utils/chart-colors';
// eslint-disable-next-line import/no-unresolved
import { RequestTypeTimeCardFragment } from './__generated__/RequestTypeTimeCard';

type RequestTypeTimeCardProps = {
  fragments: (RequestTypeTimeCardFragment | undefined)[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  size?: number;
  loading?: boolean;
};

type RequestTypeAggregate = {
  label: string;
  date: string;
  count: number;
};

const RequestTypeTimeCard: FC<RequestTypeTimeCardProps> = ({
  fragments,
  startDate,
  endDate,
  size = 12,
  loading,
}) => {
  const data = useMemo((): RequestTypeAggregate[] => {
    const dayCount = Math.max(endDate.diff(startDate, 'days') + 1, 1);

    const nestedData = Array.from(Array(dayCount).keys()).map((dayDiff) => {
      const date = startDate.clone().add(dayDiff, 'days').format('ll (dd)');
      return {
        internal: { label: 'Interne Abrufe', date, count: 0 },
        external: { label: 'Externe Abrufe', date, count: 0 },
      };
    });

    fragments.forEach((fragment) => {
      const acceptanceDate = fragment?.acceptance_date;
      const isInternalRequest = fragment?.is_internal_request;

      if (
        acceptanceDate === undefined ||
        acceptanceDate === null ||
        isInternalRequest === undefined ||
        isInternalRequest === null
      ) {
        return;
      }
      const dayDiff = moment(acceptanceDate).diff(startDate, 'days');

      if (isInternalRequest) {
        nestedData[dayDiff].internal.count++;
      } else {
        nestedData[dayDiff].external.count++;
      }
    });

    return nestedData
      .map(({ internal, external }) => [internal, external])
      .flatMap((value) => value);
  }, [fragments, startDate, endDate]);

  return (
    <Box
      size={size}
      title="Interne und externe Abrufe im zeitlichen Verlauf"
      tooltipMessage={TOOLTIP_MESSAGE}
    >
      {loading && <Loading />}
      {!loading && (
        <DiagramWrapper>
          <Line
            style={{ height: 300, marginBottom: 30 }}
            data={data}
            // isGroup
            xField="date"
            yField="count"
            seriesField="label"
            legend={{
              marker: (_name, _index, item) =>
                makeSquareMarker(item as MarkerCfg),
              position: 'bottom',
              flipPage: false,
              itemName: {
                style: { fontSize: 14 },
              },
            }}
            color={STANDALONE_CHART_COLORS}
          />
        </DiagramWrapper>
      )}
    </Box>
  );
};

export const REQUEST_TYPE_TIME_CARD_FRAGMENT = gql`
  fragment RequestTypeTimeCard on wma_metadata {
    acceptance_date
    is_internal_request
  }
`;

const TOOLTIP_MESSAGE = `
Interne Abrufe (gelb): <br />
Wie viele Abrufe wurden von Ihnen und Ihren Kollegen eingegeben? <br />
Externe Abrufe (rot): <br />
Wie viele Abrufe wurden über das Frontend-Tool auf ihrer Internetseite getätigt? <br />
<br />
Unser Tipp: <br />
Das ImmoBanking Konzept funktioniert besonders gut, wenn etwa die Hälfte Ihrer Abrufe <br />
intern und die andere Hälfte extern getätigt werden. Denn so ist gewährleistet, <br />
dass die Berater in Ihrer Bank das Tool auch aktiv im Arbeitsalltag einsetzen."
`;

export default RequestTypeTimeCard;
