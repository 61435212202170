import { saveAs } from 'file-saver';
import { FC, ReactNode, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

type EditLogModalProps = {
  log: string;
  children?: ReactNode;
};

const EditLogModal: FC<EditLogModalProps> = ({ log, children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDownload = () => {
    const filename = 'protokoll.txt';
    const blob = new Blob([log], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(blob, filename);
  };

  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => setIsModalVisible(true)}
      >
        {children}
      </span>
      <Modal
        size="lg"
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
      >
        <Modal.Body>
          <h3>Bearbeitungsprotokoll</h3>
          <Row className="mb-2 mt-4">
            <Col>
              {log.split('\n').map((edit, index) => (
                <p key={index}>{edit}</p>
              ))}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
          <Button variant="primary" onClick={handleDownload}>
            Download
          </Button>
          <Button variant="primary" onClick={() => setIsModalVisible(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditLogModal;
