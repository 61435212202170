import moment from 'moment';
import { wma_metadata_bool_exp } from '../graphql.types';
import momentToQueryDate from './moment-to-query-date';

const today = moment().startOf('day');
const NEW_BUILDING_CUTOFF_YEAR = moment(today).subtract(2, 'years').year();

export const getCustomerIdFilter = (
  customerId: number | undefined
): {
  commercialAgentFilter: wma_metadata_bool_exp;
  customerNumberFilter: wma_metadata_bool_exp;
} => {
  const commercialAgentFilter = customerId
    ? { output: { commercial_agent_id: { _eq: customerId } } }
    : {};

  const customerNumberFilter = customerId
    ? { input: { customer_number: { _eq: customerId } } }
    : {};

  return { commercialAgentFilter, customerNumberFilter };
};

export const getDateFilter = (
  startDate: moment.Moment,
  maxDate: moment.Moment
): wma_metadata_bool_exp[] => {
  return [
    { acceptance_date: { _gte: momentToQueryDate(startDate) } },
    { acceptance_date: { _lt: momentToQueryDate(maxDate) } },
  ];
};

export const getInternalRequestFilter = (
  isInternal: boolean | undefined
): wma_metadata_bool_exp => {
  return isInternal === true
    ? { is_internal_request: { _eq: true } }
    : isInternal === false
    ? {
        _or: [
          { is_internal_request: { _neq: true } },
          { is_internal_request: { _is_null: true } },
        ],
      }
    : {};
};

export const getNewBuildingFilter = (
  isNewBuilding: boolean | undefined
): wma_metadata_bool_exp => {
  return isNewBuilding !== undefined
    ? {
        input: {
          object_data__construction_year: isNewBuilding
            ? { _gte: NEW_BUILDING_CUTOFF_YEAR }
            : { _lt: NEW_BUILDING_CUTOFF_YEAR },
        },
      }
    : {};
};

export const getUserConsentFilter = (
  hasUserConsent: boolean | undefined
): wma_metadata_bool_exp => {
  return hasUserConsent !== undefined && hasUserConsent
    ? { input: { user_consent: { _eq: true } } }
    : {};
};
