import { ICache, Cacheable } from '@auth0/auth0-spa-js';
import secureLocalStorage from 'react-secure-storage';

type MaybePromise<T> = Promise<T> | T;

/**
 * !IMPORTANT!
 * Always use with env REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY.
 */
export class EncryptedStorageCache implements ICache {
  public set<T = Cacheable>(key: string, entry: T): void {
    const stringified = JSON.stringify(entry);
    secureLocalStorage.setItem(key, stringified);
  }

  public get<T = Cacheable>(key: string): MaybePromise<T | null> {
    const json = secureLocalStorage.getItem(key) as string;
    if (!json) return null;

    try {
      const payload = JSON.parse(json) as T;
      return payload;
    } catch (e) {
      return null;
    }
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public allKeys(): string[] {
    return Object.keys(localStorage);
  }
}
