import type { NamedProps, OptionTypeBase } from 'react-select';

const SELECT_PROPS = <T extends OptionTypeBase>(): Partial<NamedProps<T>> => ({
  theme: (theme) => ({
    ...theme,
    borderRadius: 0,
    spacing: { ...theme.spacing, menuGutter: 2, controlHeight: 32 },
  }),
  styles: {
    control: (base) => ({ ...base, height: 32 }),
    valueContainer: (base) => ({ ...base, position: 'static' }),
    indicatorsContainer: (base) => ({ ...base, height: 30 }),
  },
});

export default SELECT_PROPS;
