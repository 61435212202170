import { useMutation } from '@apollo/client';
import type { FetchResult } from '@apollo/client';
import gql from 'graphql-tag';
import {
  ContactUserMutation,
  ContactUserMutationVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/contact-user';

const CONTACT_USER_MUTATION = gql`
  mutation ContactUser($runId: uuid!) {
    contact_user(metadata_uuid: $runId) {
      cockpit_telephone_displayed_at
    }
  }
`;

type ContactUserReturnType = () => Promise<
  FetchResult<
    ContactUserMutation,
    Record<string, unknown>,
    Record<string, unknown>
  >
>;

const useContactUser = (runId: uuid): ContactUserReturnType => {
  const [mutation] = useMutation<
    ContactUserMutation,
    ContactUserMutationVariables
  >(CONTACT_USER_MUTATION, { variables: { runId } });

  return () => mutation();
};

export default useContactUser;
