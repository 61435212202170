import { useQuery } from '@apollo/client';
import capitalize from 'capitalize';
import gql from 'graphql-tag';
import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomCheckbox from '../CustomCheckbox';
// eslint-disable-next-line import/no-unresolved
import { GoalMultiSelectQuery } from './__generated__/GoalMultiSelect';

type GoalMultiSelectProps = {
  goals?: number[];
  setGoals: (goals: number[]) => void;
  setGoalsLabel?: (label?: string) => void;
};

const GoalMultiSelect: FC<GoalMultiSelectProps> = ({
  goals: selectedGoals,
  setGoals: setSelectedGoals,
  setGoalsLabel: setSelectedGoalsLabel,
}) => {
  const setSelectedGoalsWithoutValue = (value: number) => {
    const valueSet = new Set(selectedGoals);
    valueSet.delete(value);
    setSelectedGoals(Array.from(valueSet));
  };

  const setSelectedGoalsWithValue = (value: number) => {
    const valueSet = new Set(selectedGoals);
    valueSet.add(value);
    setSelectedGoals(Array.from(valueSet));
  };

  const goals = useGoals();

  useEffect(() => {
    if (selectedGoals === undefined && goals !== undefined) {
      setSelectedGoals(goals.map(({ value }) => value));
    }
  }, [goals]);

  useEffect(() => {
    const label = goals
      ?.filter((goal) => selectedGoals?.includes(goal.value))
      .map((goal) => capitalize(goal.label_de || ''))
      .join(', ');
    setSelectedGoalsLabel?.(label);
  }, [goals, selectedGoals]);

  return (
    <div className="mb-2" data-cy="Motiv">
      <label>Motive</label>
      <Row>
        {goals?.map((goal) => (
          <Col lg={6} key={goal.id}>
            <CustomCheckbox
              label={capitalize(goal.label_de || '')}
              checked={selectedGoals?.includes(goal.value) || false}
              onCheck={(checked) =>
                checked
                  ? setSelectedGoalsWithValue(goal.value)
                  : setSelectedGoalsWithoutValue(goal.value)
              }
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

const GOAL_QUERY = gql`
  query GoalMultiSelect {
    wma_conversion_request_motivation {
      id: value
      value
      label_de
    }
  }
`;

const useGoals = () => {
  const { data } = useQuery<GoalMultiSelectQuery>(GOAL_QUERY);
  return data?.wma_conversion_request_motivation;
};

export default GoalMultiSelect;
