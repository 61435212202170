import { useMutation } from '@apollo/client';
import type { FetchResult } from '@apollo/client';
import gql from 'graphql-tag';
import {
  SetNoteMutation,
  SetNoteMutationVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/set-note';

type SetNoteReturnType = (
  note: string
) => Promise<
  FetchResult<SetNoteMutation, Record<string, unknown>, Record<string, unknown>>
>;

const SET_NOTE_MUTATION = gql`
  mutation SetNote($runId: uuid!, $note: String!) {
    set_metadata_cockpit_note(metadata_uuid: $runId, cockpit_note: $note) {
      success
    }
  }
`;

const useSetNote = (runId: uuid): SetNoteReturnType => {
  const [mutation] = useMutation<SetNoteMutation, SetNoteMutationVariables>(
    SET_NOTE_MUTATION
  );

  return (note: string) => mutation({ variables: { runId, note } });
};

export default useSetNote;
