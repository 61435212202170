import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import JwtPayload from 'jwt-decode';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { alertWarning } from '../services/alert';
import { ROLES } from './constants';

type HookReturnType = {
  accessToken?: string;
  setAccessToken: Dispatch<SetStateAction<string | undefined>>;
};

type DecodedTokenType = typeof JwtPayload & {
  permissions?: string[];
};

export const useAccessToken = (): HookReturnType => {
  const [accessToken, setAccessToken] = useState<string>();
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently()
      .then(async (token) => {
        const permissions = getPermissions(token);
        if (permissions.includes(ROLES.ERFOLGSCOCKPIT_USE)) {
          setAccessToken(token);
        } else {
          alertWarning(
            `Sie haben keine Berechtigung zur Nutzung der Anwendung.`
          );
          await new Promise((resolve) => setTimeout(resolve, 10000));
          logout({ returnTo: window.location.origin });
          return;
        }
      })
      .catch(() => loginWithRedirect({}));
  }, [getAccessTokenSilently]);

  return { accessToken, setAccessToken };
};

export const getPermissions = (accessToken: string): string[] => {
  const decoded = jwtDecode<DecodedTokenType>(accessToken);
  const permissions: string[] = decoded.permissions as string[];
  return permissions;
};

export default useAccessToken;
