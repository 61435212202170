import React, { FC } from 'react';
import { PulseLoader } from 'react-spinners';

type LoadingProps = {
  size?: number;
};

const Loading: FC<LoadingProps> = ({ size = 5 }) => (
  <div style={{ width: '100%', padding: size * 4, textAlign: 'center' }}>
    <PulseLoader size={size * 1.5} margin={size} />
  </div>
);

export default Loading;
