import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React, { FC, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Row } from 'react-bootstrap';

import Box from '../components/Box';
import ExcelExport from '../components/ExcelExport';
import Layout from '../components/Layout';
import TableFilter, {
  TableFilterValues,
  useTableFilter,
} from '../components/TableFilter';
import WMATable, { WMA_TABLE_FRAGMENT } from '../components/WMATable';
import { useDownloadExcel } from '../utils/hooks';
import {
  AddTestRunFilter,
  getMetadataTableFilterExpr,
  getMetadataTableSearchQueryExpr,
} from '../utils/where-exprs';
// eslint-disable-next-line import/no-unresolved
import { wma_metadata_bool_exp } from '../graphql.types';
import {
  WMAFilterQuery,
  WMAFilterQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/WMAFilter';
import { getCustomerIdFilter } from '../utils/where-exprs-helpers';

const WMAFilter: FC = () => {
  const { user } = useAuth0();
  const {
    filter,
    updateFilter,
    filterLabels,
    updateFilterLabels,
    getStoredValues,
  } = useTableFilter();
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const filterExpr: wma_metadata_bool_exp = {
    _and: [
      getMetadataTableFilterExpr(filter),
      getMetadataTableSearchQueryExpr(searchQuery),
    ],
  };
  const { commercialAgentFilter, customerNumberFilter } = getCustomerIdFilter(
    filter.customerId
  );
  const downloadExcel = useDownloadExcel({ filterLabels, filterExpr });
  const { data, fetchMore, loading } = useQuery<
    WMAFilterQuery,
    WMAFilterQueryVariables
  >(WMA_FILTER_QUERY, {
    variables: {
      where: filterExpr,
      beforeAcceptance: '2999-01-01',
      offset: page === 1 ? 0 : (page - 1) * 30,
      commercialAgentFilter,
      customerNumberFilter,
    },
  });
  const currentCount = data?.wma_metadata.length || 0;
  const customerFilterCount =
    data?.wma_metadata_aggregate_customer_filter_count?.aggregate?.count || 0;
  const CommercialAgentFilterCount =
    data?.wma_metadata_aggregate_commercial_agent_filter_count?.aggregate
      ?.count || 0;
  const totalCount = filter.customerId
    ? customerFilterCount + CommercialAgentFilterCount
    : customerFilterCount;
  const handleLoadMore =
    totalCount > currentCount
      ? (selectedCurrentPage: number) => {
          setPage(selectedCurrentPage);
          // offset calculates where to start to fetch data. Limit = max items to fetch. Page 1 = 0-30, Page 2= 30-60.
          fetchMore({
            variables: {
              where: filterExpr,
              beforeAcceptance: '2999-01-01',
              offset:
                selectedCurrentPage === 1 ? 0 : (selectedCurrentPage - 1) * 30,
            },
          });
          return {
            wma_metadata_aggregate_customer_filter_count:
              data?.wma_metadata_aggregate_customer_filter_count,
            wma_metadata_aggregate_commercial_agent_filter_count:
              data?.wma_metadata_aggregate_commercial_agent_filter_count,
            wma_metadata: [...(data?.wma_metadata || [])],
          };
        }
      : undefined;

  useEffect(() => {
    const previousLoggedUserName = (
      getStoredValues() as unknown as TableFilterValues
    )?.userId;
    if (!previousLoggedUserName || !user?.name) return;
    if (previousLoggedUserName !== user?.name) localStorage.clear();
  }, []);

  return (
    <Layout title="Wohnmarktanalysen">
      <Row>
        <Box size={12} title="Filter" toggle initialShow={false}>
          <TableFilter
            initialValues={filter}
            onValuesChange={(values) => updateFilter(values)}
            onLabelsChange={(labels) => updateFilterLabels(labels)}
          />
        </Box>
        <Box
          size={12}
          title={
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">Einzelabrufe</div>
              <ExcelExport style={{ fontSize: 13 }} onExport={downloadExcel} />
            </div>
          }
        >
          <WMATable
            fragments={data?.wma_metadata || []}
            totalCount={totalCount}
            searchQuery={searchQuery}
            onSearchQueryChange={setSearchQuery}
            loadMore={handleLoadMore}
            loading={loading}
          />
        </Box>
      </Row>
    </Layout>
  );
};

// To change max items per page -> edit the limit and adjust the offset in above function.
const WMA_FILTER_QUERY = gql`
  ${WMA_TABLE_FRAGMENT}
  query WMAFilter(
    $where: wma_metadata_bool_exp!
    $beforeAcceptance: timestamp!
    $offset: Int!
    $commercialAgentFilter: wma_metadata_bool_exp!
    $customerNumberFilter: wma_metadata_bool_exp!
  ) {
    

    wma_metadata_aggregate_customer_filter_count: wma_metadata_aggregate(
      where: {
        _and: [
          $where,
          $customerNumberFilter,
          { is_failure: { _eq: false } ${AddTestRunFilter()} }
          { acceptance_date: { _lt: $beforeAcceptance } }
          { input: { is_esg: { _neq: true } } }
        ]
      }
    ) {
      aggregate {
        count
        __typename
      }
      __typename
    }
    wma_metadata_aggregate_commercial_agent_filter_count: wma_metadata_aggregate(
    where: {
      _and: [
        $where,
        $commercialAgentFilter,
        { is_failure: { _eq: false } ${AddTestRunFilter()} }
        { acceptance_date: { _lt: $beforeAcceptance } }
        { input: { is_esg: { _neq: true } } }
      ]
    }
  ) {
    aggregate {
      count
      __typename
    }
    __typename
  }
    wma_metadata(
      offset: $offset
      limit: 30
      where: {
        _and: [
          $where
          {
            _or: [
              $commercialAgentFilter,
              $customerNumberFilter,
            ]
          },
          { is_failure: { _eq: false } ${AddTestRunFilter()} }
          { acceptance_date: { _lt: $beforeAcceptance } }
          { input: { is_esg: { _neq: true } } }
        ]
      }
      order_by: { acceptance_date: desc }
    ) {
      id: uuid
      acceptance_date
      is_test_run
      ...WMATable
    }
  }
`;

export default WMAFilter;
