import { FC, useEffect } from 'react';
import Select from 'react-select';
import {
  APPARTMENT_LABEL,
  HOUSE_LABEL,
  MULTI_HOUSE_LABEL,
  ObjectType,
  RENTAL_APPARTMENT_LABEL,
} from '../../utils/object-type';
import SELECT_PROPS from './select-props';

type SelectValue = { value?: ObjectType; label: string };

type ObjectTypeSelectProps = {
  objectType?: ObjectType;
  setObjectType: (objectType?: ObjectType) => void;
  setObjectTypeLabel?: (label?: string) => void;
};

const ObjectTypeSelect: FC<ObjectTypeSelectProps> = ({
  objectType,
  setObjectType,
  setObjectTypeLabel,
}) => {
  const value = SELECT_OPTIONS?.find(({ value }) => value === objectType);
  useEffect(() => setObjectTypeLabel?.(value?.label), [value]);

  return (
    <div className="mb-2">
      <label>Objekttyp</label>
      <Select<SelectValue>
        {...SELECT_PROPS<SelectValue>()}
        isClearable={objectType !== undefined}
        options={SELECT_OPTIONS}
        value={value}
        onChange={(value) => setObjectType(value?.value)}
      />
    </div>
  );
};

const SELECT_OPTIONS: SelectOptionUndefined<ObjectType>[] = [
  { value: undefined, label: 'Alle' },
  { value: 'RENTAL_APPARTMENT', label: RENTAL_APPARTMENT_LABEL },
  { value: 'APPARTMENT', label: APPARTMENT_LABEL },
  { value: 'HOUSE', label: HOUSE_LABEL },
  { value: 'MULTI_HOUSE', label: MULTI_HOUSE_LABEL },
];

export default ObjectTypeSelect;
