import { useMutation } from '@apollo/client';
import type { FetchResult } from '@apollo/client';
import gql from 'graphql-tag';
import {
  SaleMutation,
  SaleMutationVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/upsert-sale';

const UPSERT_SALE_MUTATION = gql`
  mutation Sale($runId: uuid!, $price: Float) {
    upsert_wma_sale_by_metadata_uuid(
      metadata_uuid: $runId
      is_sold: true
      sale_price: $price
    ) {
      success
    }
  }
`;

type UpsertScaleReturnType = (
  price?: number
) => Promise<
  FetchResult<SaleMutation, Record<string, unknown>, Record<string, unknown>>
>;

type DeletePriceReturnType = () => Promise<
  FetchResult<SaleMutation, Record<string, unknown>, Record<string, unknown>>
>;

const useUpsertSale = (runId: uuid): UpsertScaleReturnType => {
  const [mutation] = useMutation<SaleMutation, SaleMutationVariables>(
    UPSERT_SALE_MUTATION
  );

  return (price?: number) => mutation({ variables: { runId, price } });
};

const DELETE_PRICE_MUTATION = gql`
  mutation DeletePrice($runId: uuid!) {
    upsert_wma_sale_by_metadata_uuid(
      metadata_uuid: $runId
      is_sold: false
      sale_price: null
    ) {
      success
    }
  }
`;

const useDeletePrice = (runId: uuid): DeletePriceReturnType => {
  const [mutation] = useMutation<SaleMutation, SaleMutationVariables>(
    DELETE_PRICE_MUTATION
  );
  return () => mutation({ variables: { runId } });
};

export { useUpsertSale, useDeletePrice };
