// eslint-disable-next-line import/no-unresolved
import { type wma_metadata_bool_exp } from '../graphql.types';

export type StatusFilterValues =
  | 'ALL'
  | 'UNPROCESSED'
  | 'PROCESSING'
  | 'LEAD_FORWARDED'
  | 'SOLD'
  | 'DELETED';

export type StatusFilterLabels =
  | 'Alle'
  | 'Unbearbeitet'
  | 'In Bearbeitung'
  | 'Lead weitergeleitet'
  | 'Auftrag erfasst'
  | 'Gelöscht';

export const STATUS_FILTER_LABELS = new Map<
  StatusFilterValues,
  StatusFilterLabels
>([
  ['ALL', 'Alle'],
  ['UNPROCESSED', 'Unbearbeitet'],
  ['PROCESSING', 'In Bearbeitung'],
  ['LEAD_FORWARDED', 'Lead weitergeleitet'],
  ['SOLD', 'Auftrag erfasst'],
  ['DELETED', 'Gelöscht'],
]);

export const getStatusFilterBoolExpr = (
  statusFilter: StatusFilterValues
): wma_metadata_bool_exp => {
  switch (statusFilter) {
    case 'ALL':
      return {};
    case 'UNPROCESSED':
      return {
        _and: [
          { input: { user_uuid: { _is_null: false } } },
          { input: { _not: { sale: { is_sold: { _eq: true } } } } },
          {
            cockpit_sent_at: { _is_null: true },
          },
          {
            cockpit_note_added_at: { _is_null: true },
          },
          {
            cockpit_telephone_displayed_at: { _is_null: true },
          },
        ],
      };
    // case 'LEAD_FORWARDED': // TODO: uncomment this when LEAD_FORWARDED is implemented, and add correct filter logic
    //   return {
    //     _and: [
    //       { input: { user_uuid: { _is_null: false } } },
    //       { input: { _not: { sale: { is_sold: { _eq: true } } } } },
    //       { cockpit_sent_at: { _is_null: false } },
    //     ],
    //   };
    case 'PROCESSING':
      return {
        _and: [
          { input: { user_uuid: { _is_null: false } } },
          { input: { _not: { sale: { is_sold: { _eq: true } } } } },
          {
            _or: [
              { cockpit_sent_at: { _is_null: false } },
              {
                cockpit_note_added_at: { _is_null: false },
              },
              {
                cockpit_telephone_displayed_at: { _is_null: false },
              },
            ],
          },
        ],
      };
    case 'SOLD':
      return {
        _and: [
          {
            input: { sale: { is_sold: { _eq: true } } },
          },
        ],
      };
    case 'DELETED':
      return {
        _and: [
          { input: { user_uuid: { _is_null: true } } },
          { input: { _not: { sale: { is_sold: { _eq: true } } } } },
        ],
      };
    default:
      return {};
  }
};
