import { FC, useEffect } from 'react';
import Select from 'react-select';
import SELECT_PROPS from './select-props';

type SelectValue = { value?: boolean; label: string };

type IsInternalSelectProps = {
  isInternal?: boolean;
  setIsInternal: (isInternal?: boolean) => void;
  setIsInternalLabel?: (label?: string) => void;
};

const IsInternalSelect: FC<IsInternalSelectProps> = ({
  isInternal,
  setIsInternal,
  setIsInternalLabel,
}) => {
  const value = SELECT_OPTIONS.find(({ value }) => value === isInternal);
  useEffect(() => setIsInternalLabel?.(value?.label), [value]);

  return (
    <div className="mb-2">
      <label>Interne / Externe Abrufe</label>
      <Select<SelectValue>
        {...SELECT_PROPS<SelectValue>()}
        isClearable={isInternal !== undefined}
        options={SELECT_OPTIONS}
        value={value}
        onChange={(value) => setIsInternal(value?.value)}
      />
    </div>
  );
};

const SELECT_OPTIONS: SelectOptionUndefined<boolean>[] = [
  { value: undefined, label: 'Alle' },
  { value: true, label: 'Eigene' },
  { value: false, label: 'Endnutzer' },
];

export default IsInternalSelect;
