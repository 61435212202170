import type { Options, Plot } from '@antv/g2plot';
import React, { FC, ReactElement, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';

type DiagramWrapperProps = {
  children: ReactElement;
};

const DiagramWrapper: FC<DiagramWrapperProps> = ({ children: diagram }) => {
  const chartRef = useRef<Plot<Options> & { downloadImage?: () => void }>();

  return (
    <div style={{ position: 'relative' }}>
      {React.cloneElement(diagram, {
        ...diagram.props,
        onReady: (chart: Plot<Options>) => (chartRef.current = chart),
      })}
      <Button
        style={{ position: 'absolute', top: 0, right: 0 }}
        variant="light"
        size="sm"
        onClick={() => chartRef.current?.downloadImage?.()}
      >
        <FaDownload />
      </Button>
    </div>
  );
};

export default DiagramWrapper;
