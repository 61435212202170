// eslint-disable-next-line import/no-unresolved
import { PhaseData } from '../../types/onboarding';

export const PHASE3_DATA: PhaseData[] = [
  {
    label: 'Prozessbegleitung & Controlling',
    text: 'Die Anforderungen der Kunden sowie die eigenen Betriebsprozesse unterliegen stetigem Wandel. Stellen Sie sicher, dass ihr Wohnmarktanalyse-Prozess an relevante Veränderungen angepasst wird und mit dem Institut mitwachsen kann.',
    tags: ['Projektleiter', 'iib'],
    status: 1,
    subtasks: [
      {
        label: 'Optimierungen & Verbesserungen',
        text: 'Die jeweiligen Verantwortlichen bzw. der Projektleiter sollten in regelmäßigem Turnus die Prozesse der Mitarbeiter überprüfen, Feedback einholen und diverse Hilfestellungen geben. Wie wird telefoniert? Gibt es ein Erfolgsmodell? Wer macht es besonders gut, wer benötigt ggf. noch Unterstützung? Wie unterscheide ich mich von den Mitbewerbern? Wie können wir noch effizienter mit der Wohnmarktanalyse arbeiten? Was haben wir für Ziele und sind sie bisher erreicht worden? Wie werden diese umgesetzt? Für besondere Erfolge kann ein Zertifikat, oder ein Bonusmodell an die Mitarbeiter erfolgen usw.',
        tags: [
          'Projektleiter',
          'Leiter Kunden Service Center',
          'Leiter Immobilienabteilung',
          'Leiter Privatkundenberatung',
        ],
        status: 1,
      },
    ],
  },
  {
    label: 'Interne Schulung',
    text: 'Interne Schulungen dienen zum Zwecke der gemeinsamen Kommunikation und der gezielten Unterrichtung der Mitarbeiter.',
    tags: ['Leiter Kunden Service Center'],
    status: 1,
    subtasks: [
      {
        label: 'WMA einfach erklärt',
        text: 'Fach und Infomaterial zum Thema WMA bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen.',
        tags: ['Mitarbeiter / Nutzer', 'Projektleiter', 'iib'],
        status: 1,
      },
      {
        label: 'ErfolgsCockpit',
        text: 'Fach und Infomaterial zum Thema Cockpit bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen.',
        tags: ['Mitarbeiter / Nutzer', 'Projektleiter', 'iib'],
        status: 1,
      },
      {
        label: 'Leadprozess',
        text: 'Die jeweiligen Verantwortlichen bzw. der Projektleiter sollten in regelmäßigem Turnus die Prozesse der Mitarbeiter überprüfen, Feedback einholen und diverse Hilfestellungen geben. Wie wird telefoniert? Gibt es ein Erfolgsmodell? Wer macht es besonders gut, wer benötigt ggf. noch Unterstützung? Wie unterscheide ich mich von den Mitbewerbern? Wie können wir noch effizienter mit der Wohnmarktanalyse arbeiten? Was haben wir für Ziele und sind sie bisher erreicht worden? Wie werden diese umgesetzt? Für besondere Erfolge kann ein Zertifikat, oder ein Bonusmodell an die Mitarbeiter erfolgen usw.Fach und Infomaterial zum Thema Leadprozess bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen.',
        tags: ['Mitarbeiter / Nutzer', 'Projektleiter', 'iib'],
        status: 1,
      },
      {
        label: 'eLearning',
        text: 'In unseren neuen eLearning Kursen finden Sie vielerlei Hilfen und Tipps zum richtigen Umgang mit der Wohnmarktanalyse.',
        tags: ['Mitarbeiter / Nutzer', 'Projektleiter', 'iib'],
        status: 1,
      },
    ],
  },
  {
    label: 'Marketingmaßnahmen',
    text: 'Marketingmaßnahmen sollten nicht nur zur Einführung der Wohnmarktanalyse geplant werden, sondern das ganze Jahr über in den Kampagnenplan integriert werden.',
    tags: ['Marketing', 'Medialer Vertrieb', 'Projektleiter'],
    status: 1,
    subtasks: [
      {
        label: 'Wohnmarktbericht',
        text: 'Spätestens jetzt den Wohnmarktbericht bestellen, um die Verzahnung zwischen Makro und Mikroebene für das gesamte Marktgebiet darzustellen. Fach und Infomaterial zum Thema WMA & WMB bekommen Sie vom iib Dr. Hettenbach Institut und können es hier herunterladen.',
        tags: ['Marketing', 'Medialer Vertrieb'],
        status: 1,
      },
      {
        label: 'Homepage',
        text: 'Wichtig ist die technische Einbindung der Wohnmarktanalyse auf Ihrer Homepage und damit die Sichtbarkeit. ',
        tags: ['Marketing', 'Medialer Vertrieb'],
        status: 1,
      },
      {
        label: 'On- & Offline Marketing',
        text: 'Auf Ihrer Homepage können Sie ständig verschiedene Teilgebiete aus dem WMB Ihres Marktes online stellen. Dieses stellt die absolute Professionalität Ihres Instituts klar. Fach und Infomaterial zum Thema Promotage & Marketing bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen.',
        tags: ['Marketing', 'Medialer Vertrieb'],
        status: 1,
      },
      {
        label: 'Client-Farming',
        text: 'Mit welchen Maßnahmen schaffe ich maximale Vermarktung in meinem Marktgebiet? Wie können Sie Ihre Bestandskunden nutzen? Ein Beispiel wäre, alle Girokonten Kunden anschreiben und über die Wohnmarktanalyse informieren.',
        tags: ['Marketing', 'Medialer Vertrieb', 'Projektleiter'],
        status: 1,
      },
      {
        label: 'Email Marketing Newsletter',
        text: 'Stetiger, relevanter Content zum Thema rund um die Immobilie.',
        tags: ['Marketing'],
        status: 1,
      },
    ],
  },
  {
    label: 'ErfolgsCockpit Controlling',
    text: '',
    tags: [],
    status: 1,
    subtasks: [
      {
        label: 'Messbare Erfolgskontrolle',
        text: 'Das Cockpit ist ein enorm wichtiger Bestandteil der Wohnmarktanalyse, weil es eine anschauliche Prozesskette darstellt. Es liefert fundierte Ergebnisse, statt spekulativer Einschätzungen. Somit vermitteln Sie Kompetenz und Fachwissen. Jeder Mitarbeiter, der dazu berechtigt ist, sollte es sicher auswerten können, denn alle Erfolge sind darin messbar und kontrollierbar.',
        tags: [
          'Leiter Kunden Service Center',
          'Leiter Immobilienabteilung',
          'Leiter Privatkundenberatung',
          'Projektleiter',
        ],
        status: 1,
      },
      {
        label: 'Controlling',
        text: 'Abarbeitung der Leads/ WMA. Veredeln der Leads/ WMA Cross Selling. Fach und Infomaterial zum Thema Cross-Selling bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen.In welchem Zeitrahmen geschieht das alles? Fach und Infomaterial zum Thema Cockpit bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen.',
        tags: [
          'Leiter Kunden Service Center',
          'Leiter Immobilienabteilung',
          'Leiter Privatkundenberatung',
          'Projektleiter',
        ],
        status: 1,
      },
    ],
  },
  {
    label: 'CRM Controlling',
    text: 'Wie sieht die Ablage im CRM aus? Was wurde dort abgelegt? Es sollte ständig gründlich durchgesehen werden, damit kalte Leads bearbeitet werden, bevor die DSGVO in Kraft tritt.',
    tags: [
      'Leiter Kunden Service Center',
      'Leiter Immobilienabteilung',
      'Leiter Privatkundenberatung',
      'Projektleiter',
    ],
    status: 1,
  },
];

export const PHASE2_DATA: PhaseData[] = [
  {
    label: 'Auslieferung der Wohnmarktanalyse',
    text: 'Nach Bestellung und Erstellung des Frontends kommt es zur Auslieferung. Vom iib Dr. Hettenbach Institut bekommen Sie eine Begrüßungsmail und den Cockpitzugang für die in der Bestellung genannten Mitarbeiter. Zur verständlichen Erklärung und Nutzung des ErfolgsCockpits können Sie hier das Handbuch herunterladen.',
    tags: ['iib'],
    status: 1,
  },
  {
    label: 'Family & Friends Aktion',
    text: 'Die Family & Friends Aktion ist eine Test- und Übungsphase für die gesamten Mitarbeiter, aber auch ein wirkungsvolles Modell zur Akquise von Bestandsimmobilien. Hierzu gibt es vom iib Dr. Hettenbach Institut das nötige Fach- und Infomaterial, welches Sie hier herunterladen können.',
    tags: [
      'Mitarbeiter / Nutzer',
      'Leiter Kunden Service Center',
      'Leiter Privatkundenberatung',
      'Leiter Immobilienabteilung',
      'Bank / Institut',
    ],
    status: 1,
  },
  {
    label: 'Marketingmaßnahmen',
    text: 'Ein gutes Tool kann auf Dauer nicht allein erfolgreich werden, sondern benötigt die Unterstützung durch gezielte Vermarktung, die Interessenten auf das Tool aufmerksam macht.',
    tags: ['Marketing', 'Medialer Vertrieb', 'Vertriebssteuerung'],
    status: 1,
    subtasks: [
      {
        label: 'Wohnmarktbericht',
        text: 'Es besteht die Möglichkeit zum Marktgebiet Ihres Instituts ebenfalls einen Wohnmarktbericht zu erstellen, um die Verzahnung zwischen Makro und Mikroebene für das gesamte Marktgebiet darzustellen. Auf Ihrer Homepage können Sie ständig verschiedene Teilgebiete Ihres Marktes präsentieren. So stellen Sie die absolute Professionalität Ihres Instituts klar und stärken Ihren Ruf als Immobilienexperte. Fach- und Infomaterial zum Thema WMA & WMB bekommen Sie vom iib Dr. Hettenbach Institut und können es hier herunterladen.',
        tags: ['Marketing', 'Medialer Vertrieb'],
        status: 1,
      },
      {
        label: 'On-/offline Marketing',
        text: 'Um das Frontend-Tool mit Pauken und Trompeten vorzustellen, können Sie die Presse bestellen, Bürgermeister, Ortsvorsteher verschiedener Verbände usw. einladen. Berücksichtigen Sie auch Social Media Marketing.',
        tags: ['Marketing', 'Medialer Vertrieb'],
        status: 1,
      },
      {
        label: 'Promotion-Tage',
        text: 'Hierbei gehen Mitarbeiter in den Filialen vor Ort mit den Kunden die WMA gemeinsam durch. Fach und Infomaterial zum Thema Promo-Tage & Marketing bekommen Sie vom iib Dr. Hettenbach Institut und können es hier herunterladen.',
        tags: ['Marketing', 'Medialer Vertrieb'],
        status: 1,
      },
      {
        label: 'Print/ Digital Werbung',
        text: 'Zusätzlich können Sie örtliche Zeitungsanzeigen aufgeben sowie digitale Werbung auf den gängigen Plattformen schalten.',
        tags: ['Marketing', 'Medialer Vertrieb'],
        status: 1,
      },
      {
        label: 'Banner diverses Marketingmaterial',
        text: 'Hierzu können wir Ihnen gern Inspirationen liefern.',
        tags: [],
        status: 1,
      },
      {
        label: 'Client Farming',
        text: 'Mit welchen Maßnahmen schaffen Sie eine maximale Nutzung in Ihrem Marktgebiet? Wie können Sie Ihre Bestandskunden nutzen? Ihr Institut hat hierzu gegenüber herkömmlichen Maklern einen entscheidenden Vorteil: Sie haben Dauerkunden – und davon viele. Diese können ebenfalls in einer koordinierten Farming-Kampagne über die Wohnmarktanalyse informiert werden. Wir helfen Ihnen bei der Planung Ihres Farmings gern weiter.',
        tags: ['Projektleiter', 'Medialer Vertrieb', 'Marketing'],
        status: 1,
      },
    ],
  },
  {
    label: 'ErfolgsCockpit',
    text: 'Mit dem ErfolgsCockpit erhalten Sie ein einmaliges Tool, welches in Echtzeit Ihren Erfolg mit der Wohnmarktanalyse verfolgt, die effiziente Leadbearbeitung ermöglicht sowie Ihnen die Möglichkeit gibt weitere Maßnahmen einfach und effektiv zu planen.',
    tags: ['Cockpit Nutzer', 'Projektleiter'],
    status: 1,
    subtasks: [
      {
        label: 'Messbare Erfolgskontrolle',
        text: 'Jeder Cockpit Nutzer hat die eigenen Arbeitsabläufe unmittelbar vor Augen und kann somit sämtliche Ereignisse innerhalb der Leadbearbeitung auf einen Blick nachvollziehen. Das ErfolgsCockpit ermöglicht einen vollständig digitalen Prozess zur Interessentenbearbeitung, sowie dem nachvollziehbaren und koordinierten Cross-Selling innerhalb des Instituts.',
        tags: ['Mitarbeiter / Nutzer', 'Projektleiter'],
        status: 1,
      },
      {
        label: 'Abarbeitung der Leads/ Wohnmarktanalysen',
        text: 'In welchem Zeitraum wurden die Leads/Wohnmarktanalysen (WMA) bearbeitet?. Geschieht dies zeitnah, oder wird zu viel Zeit verloren? Wir liefern Ihnen mit dem iib ErfolgsCockpit einen Wettbewerbsvorteil, welcher Ihnen und Ihren Mitarbeitern nicht nur Zeit spart, sondern noch effizienteres Arbeiten möglich macht.',
        tags: ['Mitarbeiter / Nutzer', 'Projektleiter'],
        status: 1,
      },
      {
        label: 'Veredeln der Leads / Wohnmarktanalysen',
        text: 'Mit dem ErfolgsCockpit herrscht Nachvollziehbarkeit bei der Verarbeitung und Veredlung der Leads. Wann wurde der Interessent kontaktiert? Besteht unmittelbar eine Verkaufsabsicht? Wurden bereits Termine vereinbart? Wann soll der Kunde erneut kontaktiert werden? Wie und wann wird in es in die zuständige Fachabteilung weitergeleitet? Diese Transparenz ermöglicht einen effizienten Prozess, sowie eine reibungslose Kundenbetreuung, da alle Beteiligten auf demselben Wissensstand sind und beispielsweise keine doppelte Kontaktaufnahme erfolgt, die den Kunden möglicherweise verärgern könnte. Fach und Infomaterial zum Thema ErfolgsCockpit bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen. Fach und Infomaterial zum Thema Cross-Selling bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen.',
        tags: ['Mitarbeiter / Nutzer', 'Projektleiter'],
        status: 1,
      },
      {
        label: 'eLearning',
        text: 'In unseren neuen eLearning Kursen finden Sie vielerlei Hilfen und Tipps zum richtigen Umgang mit der Wohnmarktanalyse.',
        tags: ['Mitarbeiter / Nutzer'],
        status: 1,
      },
    ],
  },
  {
    label: 'Bankeninterne Schulung',
    text: 'Wir entwickeln die Wohnmarktanalyse stetig weiter. Da auch Ihr Mitarbeiterstamm stetiger Veränderung unterlegt, ist es wichtig in regelmäßigen Abständen interne Schulungen abzuhalten um jederzeit auf dem neusten Stand zu sein.',
    tags: [],
    status: 1,
    subtasks: [
      {
        label: '"Make you best"',
        text: 'Die erfolgreiche Einführung als Teil des Gesamtkonzepts. Fach und Infomaterial bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen.',
        tags: ['Mitarbeiter / Nutzer', 'Projektleiter'],
        status: 1,
      },
      {
        label: 'Telefonie',
        text: 'Fach und Infomaterial bekommen Sie vom iib Dr. Hettenbach Institut und können Sie hier herunterladen.',
        tags: ['Mitarbeiter / Nutzer', 'Projektleiter'],
        status: 1,
      },
    ],
  },
];

export const PHASE1_DATA: PhaseData[] = [
  {
    label: 'Präsentation für den Kreis der Entscheider',
    text: 'Vorstellung des Erfolgskonzepts ImmoBanking mit dem Produkt Wohnmarktanalyse. Dies kann persönlich vor Ort, oder via Webinar stattfinden. Zur Unternehmensvorstellung klicken Sie hier.',
    tags: ['iib'],
    status: 1,
  },
  {
    label: 'Strategie und Jahresplanung',
    text: 'Zunächst muss vor Beginn der Arbeit mit der Wohnmarktanalyse geklärt werden, welche Ziele damit verfolgt werden und welche strategische Rolle in der weiteren geschäftlichen Entwicklung dieser einnimmt. Dies ist nötig, damit bereits vor Bereitstellung des Tools alle beteiligten und verantwortlichen Personen sich im Klaren darüber sind, welcher Zeit- und Arbeitsaufwand aufgewendet werden muss, damit das Produkt effizient und effektiv genutzt werden kann. Bei der Wohnmarktanalyse handelt es sich um ein wirkungsvolles Cross-Selling Tool mit dem automatisierten ErfolgsCockpit im Hintergrund, welches die Arbeit und den Erfolg mit der Wohnmarktanalyse messbar macht. Um einen Erfolg sicherzustellen, ist die Verzahnung der verschiedenen Abteilungen besonders wichtig und eröffnet intern ein vollkommen neues Umsatzpotenzial. Eine gute Planung vor der Bestellung der Wohnmarktanalyse erspart hierbei während dem laufendem Betrieb Zeit und ermöglicht einen möglichst reibungslosen Ablauf. Die Wohnmarktanalyse ist bei Inbetriebnahme bereits zu 100% einsatzbereit und kann zu Generierung und Veredlung von Leads genutzt werden.',
    tags: [
      'Medialer Vertrieb',
      'Leiter Kunden Service Center',
      'Leiter Privatkundenberatung',
      'Leiter Immobilienabteilung',
      'Vorstände',
    ],
    status: 1,
    subtasks: [
      {
        label: 'Hauptverantwortlichen/ Projektleiter festlegen',
        text: 'Der Projektleiter sollte von Anfang festgelegt werden und an sämtlichen Gesprächen und Entscheidungen zur Wohnmarktanalyse mitwirken. Er ist der Hauptansprechpartner für sämtliche Fragen hierzu und ist für die erfolgreiche Implementierung in Ihrem Institut maßgeblich.',
        tags: [
          'Vorstände',
          'Leiter Privatkundenberatung',
          'Leiter Immobilienabteilung',
          'Leiter Kunden Service Center',
        ],
        status: 1,
      },
      {
        label: 'Zielsetzung',
        text: 'Was wollen Sie als Sparkasse mit der Wohnmarktanalyse im laufenden Jahr erreichen? Wie wollen Sie sich dem Marktwettbewerb stellen? Wie können Sie innerhalb des ImmoBanking Prozesses das Frontend-Tool einsetzen? Wie können alle Mitarbeiter dazu angehalten werden, das Tool in die tägliche Arbeit zu integrieren?',
        tags: [
          'Vorstände',
          'Leiter Privatkundenberatung',
          'Leiter Immobilienabteilung',
          'Leiter Kunden Service Center',
        ],
        status: 1,
      },
      {
        label: 'Marketing / Budgetplanung WMA /WMB',
        text: 'Es sollte eine Budgetplanung erfolgen, damit der Projektleiter und die Marketingabteilung im Vorfeld wissen, welche Maßnahmen in der Werbung genutzt werden sollen und diese bereits im Voraus geplant werden können. Fach- und Infomaterial zum Thema WMA & WMB stellt Ihnen das iib Dr. Hettenbach Institut gern hier zum Download bereit.',
        tags: [
          'Vorstände',
          'Leiter Privatkundenberatung',
          'Leiter Immobilienabteilung',
          'Leiter Kunden Service Center',
          'Medialer Vertrieb',
        ],
        status: 1,
      },
    ],
  },
  {
    label: 'Technische Einbindung',
    text: 'Welche technischen Maßnahmen sind vorab zu ergreifen, um einen reibungslosen Betrieb zu garantieren.',
    tags: ['Marketing', 'Medialer Vertrieb', 'Vertriebssteuerung'],
    status: 1,
    subtasks: [
      {
        label: 'Platzierung des Frontend-Tools auf der Homepage',
        text: 'Die technische Einbindung trägt maßgeblich zur erfolgreichen Vermarktung der Wohnmarktanalyse bei, wenn das Front End Innerhalb des Onlineauftritts prominent eingebunden ist. Hierzu zählt beispielsweise der Slider auf der Startseite oder das Logout aus dem Online Banking. Grundsätzlich gelten die allgemeinen Regeln des Onlinemarketings: Möglichst wenig nötige Klicks von Startseite bis Wohnmarktanalyse und eine Positionierung am oberen Ende einer Scroll-Seite. Ebenfalls empfehlen wir über Widgets Querverlinkungen zur Wohnmarktanalyse innerhalb der Website herzustellen.',
        tags: [
          'Projektleiter',
          'Marketing',
          'Leiter Immobilienabteilung',
          'Leiter Kunden Service Center',
          'Leiter Privatkundenberatung',
        ],
        status: 1,
      },
      {
        label: 'Marketingstrategie festlegen',
        text: 'Wann, was, wo soll welche Marketingmaßnahme durchgeführt werden? Wann und wie soll das Tool publik gemacht werden? Welche on-/offline-Maßnahmen sollten getroffen werden?',
        tags: ['Projektleiter', 'Marketing'],
        status: 1,
      },
      {
        label: 'E-Mail Adressen einlegen',
        text: 'E-Mail-Postfächer müssen den Beratern / Kollegen zugeordnet werden, die das eingegangene Lead bearbeiten und weiterleiten.',
        tags: ['Medialer Vertrieb', 'IT Betreuung'],
        status: 1,
      },
      {
        label: 'Implementierung des Frontend-Tools',
        text: 'Das Frontend-Tool wurde bereitgestellt. Jetzt muss es nur noch auf der Homepage eingebunden werden. Hierzu steht Ihnen eine ausführliche Anleitung zur Verfügung. Fach- und Infomaterial zum Thema Technische Einbindung stellt Ihnen das iib Dr. Hettenbach Institut hier zum Download zur Verfügung. Fach- und Infomaterial zum Thema Promo-Tage stellt Ihnen das iib Dr. Hettenbach Institut hier zum Download zur Verfügung.',
        tags: ['Medialer Vertrieb', 'IT Betreuung'],
        status: 1,
      },
    ],
  },
  {
    label: 'Interne Kommunikation',
    text: 'Zur Verbesserung und Vereinfachung der internen Kommunikation im späteren Betrieb sind ein paar Vorbereitungen zu treffen.',
    tags: [
      'Leiter Privatkundenberatung',
      'Leiter Immobilienabteilung',
      'Leiter Kunden Service Center',
    ],
    status: 1,
    subtasks: [
      {
        label: 'Motivauswahl',
        text: 'Verkaufen, Kaufen, Bausparen, Finanzieren, Vererben, Versichern und Vermieten. Diese Motive können mit der Wohnmarktanalyse abgefragt werden. Wir empfehlen eine gezielte Auswahl der Motive, damit diese im folgenden Schritt auch vom zuständigen Mitarbeiter bzw. der zuständigen Abteilung bearbeitet werden können.',
        tags: [
          'Leiter Privatkundenberatung',
          'Leiter Immobilienabteilung',
          'Leiter Kunden Service Center',
        ],
        status: 1,
      },
      {
        label: 'Wer übernimmt die Leadbearbeitung',
        text: 'Es wird klar definiert und im Back End hinterlegt welcher Mitarbeiter welche Leads entgegennimmt und bearbeitet. Somit ist jedes Leads zügig beim dafür zuständigen Mitarbeiter und es besteht keine Notwendigkeit einer manuellen Übermittlung von Leads. Dies ermöglicht einen digitalen und effizienten Prozess innerhalb der bestehenden Strukturen des jeweiligen Instituts.',
        tags: [
          'Leiter Privatkundenberatung',
          'Leiter Immobilienabteilung',
          'Leiter Kunden Service Center',
        ],
        status: 1,
      },
      {
        label: 'Mitarbeiter werden über das neue Tool informiert',
        text: 'Alle Mitarbeiter müssen über die Einführung und den Einführungszeitraum des ImmoBanking Modells in Kenntnis sein. Dies ist wichtig, da die Wohnmarktanalyse davon lebt, dass dieser auch von den Mitarbeitern selbst in Beratungsgesprächen angewandt wird. Zudem sollen Kunden, welche auf den Wohnmarktanalyse aufmerksam geworden ist, in jedem Mitarbeiter einen Ansprechpartner finden, der hierzu weiterhelfen kann.',
        tags: [
          'Leiter Privatkundenberatung',
          'Leiter Immobilienabteilung',
          'Leiter Kunden Service Center',
        ],
        status: 1,
      },
      {
        label: 'Testphase',
        text: 'Alle Mitarbeiter sollten Basisschulungen zum Frontend-Tool erhalten. Die Testphase umfasst u.a. die Family & Friends Aktion die im folgenden Schritt erläutert wird. ',
        tags: [
          'Leiter Privatkundenberatung',
          'Leiter Immobilienabteilung',
          'Leiter Kunden Service Center',
        ],
        status: 1,
      },
    ],
  },
];
