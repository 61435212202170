import moment from 'moment';
import 'moment/locale/de';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import AppLoading from './components/AppLoading';
import { Error404 } from './pages/Error404';
import CampaginFilter from './pages/CampaginFilter';
import Onboarding from './pages/Onboarding';
import ReportingOverview from './pages/ReportingOverview';
import ReportingAnalysis from './pages/ReportingAnalysis';
import ReportingTiming from './pages/ReportingTiming';
import { Servicekontakt } from './pages/Servicekontakt';
import WMAFilter from './pages/WMAFilter';
import { useAuth0 } from '@auth0/auth0-react';
import useAccessToken from './utils/use-access-token';
import ApolloProvider from './components/ApolloProvider';
import Datenschutz from './components/Datenschutz';
import { MapProvider } from '@iib/pandora-box';

moment.locale('de');

const App: React.FC = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { accessToken } = useAccessToken();

  if (isLoading) {
    return <AppLoading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({});
    return <div />;
  }

  return (
    <div>
      {accessToken ? (
        <BrowserRouter>
          <ApolloProvider accessToken={accessToken}>
            <MapProvider>
              <Switch>
                <Route exact path="/" component={WMAFilter} />
                <Route exact path="/wohnmarktanalysen" component={WMAFilter} />
                <Route
                  exact
                  path="/kampagnenfilter"
                  component={CampaginFilter}
                />
                <Route exact path="/reporting" component={ReportingOverview} />
                <Route
                  exact
                  path="/servicekontakt"
                  component={Servicekontakt}
                />
                <Route
                  exact
                  path="/reporting_analysis"
                  component={ReportingAnalysis}
                />
                <Route
                  exact
                  path="/reporting_timing"
                  component={ReportingTiming}
                />
                <Route exact path="/onboarding" component={Onboarding} />
                <Route exact path="/datenschutz" component={Datenschutz} />

                <Route component={Error404} />
              </Switch>
            </MapProvider>
          </ApolloProvider>
        </BrowserRouter>
      ) : (
        <AppLoading />
      )}
    </div>
  );
};

export default App;
