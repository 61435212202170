import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ContactTimingCard, {
  CONTACT_TIMING_CARD_FRAGMENT,
} from '../components/cards/ContactTiminigCard';
import LateContactCard, {
  LATE_CONTACT_CARD_FRAGMENT,
} from '../components/cards/LateContactCard';
import LateDownloadCard, {
  LATE_DOWNLOAD_CARD_FRAGMENT,
} from '../components/cards/LateDownloadCard';
import Layout from '../components/Layout';
import ReportingNav from '../components/ReportingNav';
import StatsFilter, { useStatsFilter } from '../components/StatsFilter';
import {
  AddTestRunFilter,
  getMetadataStatsFilterExpr,
} from '../utils/where-exprs';

import KeinAbrufCard from '../components/cards/KeinAbrufCard';

import {
  ReportingTimingQuery,
  ReportingTimingQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/ReportingTiming';

const ReportingTiming: FC = () => {
  const { filter, updateFilter } = useStatsFilter();

  const { data, loading } = useQuery<
    ReportingTimingQuery,
    ReportingTimingQueryVariables
  >(REPORTING_TIMING_QUERY, {
    variables: { where: getMetadataStatsFilterExpr(filter) },
  });

  const fragments = data?.wma_metadata || [];

  return (
    <Layout title="Reporting // Timing">
      <ReportingNav />
      <Row className="pt-3 pb-3">
        <Col lg={12}>
          <Card body border="light">
            <StatsFilter
              initialValues={filter}
              onValuesChange={(value) => updateFilter(value)}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <ContactTimingCard fragments={fragments} size={6} loading={loading} />
        <Col lg={6}>
          <LateDownloadCard fragments={fragments} loading={loading} />
          <LateContactCard fragments={fragments} loading={loading} />
          <KeinAbrufCard fragments={fragments} loading={loading} />
        </Col>
      </Row>
    </Layout>
  );
};

const REPORTING_TIMING_QUERY = gql`
  ${CONTACT_TIMING_CARD_FRAGMENT}
  ${LATE_CONTACT_CARD_FRAGMENT}
  ${LATE_DOWNLOAD_CARD_FRAGMENT}
  query ReportingTiming($where: wma_metadata_bool_exp!) {
    wma_metadata(
      where: {
        _and: [
          $where
          { is_failure: { _eq: false } ${AddTestRunFilter()} }
          { input: { is_esg: { _neq: true } } }
        ]
      }
    ) {
      id: uuid
      ...ContactTimingCard
      ...LateContactCard
      ...LateDownloadCard
    }
  }
`;

export default ReportingTiming;
