import React, { FC } from 'react';
import {
  FaBullhorn,
  FaChartLine,
  FaFileAlt,
  FaQuestion,
  // FaGraduationCap,
} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

export const Navigation: FC = () => {
  const years: number[] = [];
  for (let year = new Date().getFullYear() - 1; year >= 2017; year--) {
    years.push(year);
  }

  const getNavLinkClass = (path: string): '' | 'active' => {
    return window.location.pathname === path ? 'active' : '';
  };

  return (
    <React.Fragment>
      <ul className="nav metismenu" id="side-menu">
        <li className="nav-header">
          <div className="logo-big" />
          <div className="logo-element" />
        </li>
        {/* <li className={getNavLinkClass('/overview')}>
            <NavLink to="/overview">
              <FaHome />
              <span className="nav-label">Übersicht</span>
            </NavLink>
          </li> */}
        <li
          className={
            getNavLinkClass('/reporting') ||
            getNavLinkClass('/reporting_analysis') ||
            getNavLinkClass('/reporting_timing')
          }
        >
          <NavLink activeClassName="active" to="/reporting" data-cy="Übersicht">
            <FaFileAlt />
            <span className="nav-label">Übersicht</span>
          </NavLink>
        </li>
        <li
          className={getNavLinkClass('/wohnmarktanalysen')}
          id="wohnmarktanalysen"
        >
          <NavLink activeClassName="active" to="/wohnmarktanalysen">
            <FaChartLine />
            <span className="nav-label">Wohnmarktanalysen</span>
          </NavLink>
        </li>
        <li
          className={getNavLinkClass('/kampagnenfilter')}
          id="kampagnenfilter"
        >
          <NavLink activeClassName="active" to="/kampagnenfilter">
            <FaBullhorn />
            <span className="nav-label">Kampagnenfilter</span>
          </NavLink>
        </li>
        <li className={getNavLinkClass('/servicekontakt')} id="Servicekontakt">
          <NavLink activeClassName="active" to="/servicekontakt">
            <FaQuestion />
            <span className="nav-label">Servicekontakt</span>
          </NavLink>
        </li>
      </ul>
      {/* <ul className="nav metismenu" id="side-menu">
          <li className={getNavLinkClass('/onboarding')} id="onboarding">
            <NavLink activeClassName="active" to="/onboarding">
              <FaTasks />
              <span className="nav-label">Onboarding</span>
            </NavLink>
          </li>
        </ul> */}
      {/* <ul className="nav metismenu" id="side-menu">
          <li className={getNavLinkClass('/schulung')}>
            <NavLink
              activeClassName="active"
              to={{
                pathname:
                  'https://api.reteach.io/saml/00ed1ebb-8c75-4abc-a989-57fd47ceea38/login?redirectPath=%2F',
              }}
              target="_blank"
            >
              <FaGraduationCap />
              <span className="nav-label">Schulung</span>
            </NavLink>
          </li>
        </ul> */}
    </React.Fragment>
  );
};

// data-cy="Übersicht"
