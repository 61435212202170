import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import useAccessToken from '../use-access-token';
import getEnv from '../get-env';
import { alertError } from '../../services/alert';

type HookReturnType = {
  loading: boolean;
  isCommercialAgent: boolean;
};

type ContractArea = {
  cin?: string;
  zipcode?: string;
};

type Contract = {
  contractTypeId: string;
  title: string;
  active: boolean;
  areas: ContractArea[];
  iFrameConfig: Record<string, unknown>;
  matchCode: string;
};

const CommercialAgentContractTypeId = 7;

export const useIsUserCommercialAgent = (): HookReturnType => {
  const { user } = useAuth0();
  const { accessToken } = useAccessToken();
  const [isCommercialAgent, setIsCommercialAgent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const getCustomer = React.useCallback(
    async (customerId: number) => {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('authorization', `Token ${accessToken}`);
      myHeaders.append('content-type', 'application/json');

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
      };

      const contractData = await fetch(
        `${getEnv(
          'REACT_APP_API_URL'
        )}/customers/customers/${customerId}/contracts`,
        requestOptions
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .catch((error) =>
          alertError(
            'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.',
            error
          )
        );

      const customer = await fetch(
        `${getEnv('REACT_APP_API_URL')}/customers/customers/${customerId}`,
        requestOptions
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .catch((error) =>
          alertError(
            'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.',
            error
          )
        );

      const contracts = contractData?.items as Contract[];

      const hasCommercialAgentContract = contracts.some(
        (contract) =>
          Number(contract.contractTypeId) === CommercialAgentContractTypeId
      );

      setIsCommercialAgent(
        customer?.type === 'commercialAgent' || hasCommercialAgentContract
      );
      setLoading(false);
    },
    [user, accessToken]
  );

  useEffect(() => {
    if (!user || !accessToken) return;

    getCustomer(user['https://iib-it.de/customer']);
  }, [user, accessToken]);

  return { isCommercialAgent, loading };
};
