// TODO indicate isSold isCrmExported ...

export type Status =
  | 'HAS_NO_INTEREST'
  | 'IS_SOLD'
  | 'IS_CRM_EXPORTED'
  | 'IS_TELEPHONE_DISPLAYED'
  | 'IS_UNPROCESSED'
  | 'IS_NOTE_DISPLAYED';

export const HAS_NO_INTEREST_LABEL = 'Kein Interesse';
export const IS_SOLD_LABEL = 'Auftrag erhalten';
export const IS_CRM_EXPORTED_LABEL = 'an CRM übergeben';
export const IS_TELEPHONE_DISPLAYED_LABEL = 'Telefonnummer angezeigt';
export const IS_NOTE_DISPLAYED_LABEL = 'Notiz hinzugefügt';
export const IS_UNPROCESSED_LABEL = 'unbearbeitet';

export const STATUS_LABELS = new Map<Status, string>([
  ['HAS_NO_INTEREST', HAS_NO_INTEREST_LABEL],
  ['IS_SOLD', IS_SOLD_LABEL],
  ['IS_CRM_EXPORTED', IS_CRM_EXPORTED_LABEL],
  ['IS_TELEPHONE_DISPLAYED', IS_TELEPHONE_DISPLAYED_LABEL],
  ['IS_NOTE_DISPLAYED', IS_NOTE_DISPLAYED_LABEL],
  ['IS_UNPROCESSED', IS_UNPROCESSED_LABEL],
]);

type StatusInput = {
  hasNoInterest: boolean;
  isSold: boolean;
  isCrmExported: boolean;
  isTelephoneDisplayed: boolean;
  isNoteDisplayed: boolean;
  exportStatusIndex: number;
};

export const getStatus = (input: Partial<StatusInput>): Status => {
  if (input.isSold) return 'IS_SOLD';
  if (input.hasNoInterest) return 'HAS_NO_INTEREST';
  if (input.exportStatusIndex === 0) return 'IS_CRM_EXPORTED';
  if (input.exportStatusIndex === 1) return 'IS_TELEPHONE_DISPLAYED';
  if (input.exportStatusIndex === 2) return 'IS_NOTE_DISPLAYED';
  return 'IS_UNPROCESSED';
};

export const compareDates = (datesArray: string[]): number => {
  // Replacing with 946681200000 which represents 0 || start of the time
  const tempDatesArray = datesArray
    .map((e) => Date.parse(e))
    .map((date) => new Date(date).getTime())
    .map((item) => (Number.isNaN(item) ? 946681200000 : item));
  if (
    tempDatesArray.every(
      (val, i, arr) => JSON.stringify(val) === JSON.stringify(arr[0])
    )
  ) {
    return -1;
  } else {
    const maxDate = Math.max(...tempDatesArray);
    const indexofdate = tempDatesArray.findIndex(
      (e) => JSON.stringify(e) === JSON.stringify(maxDate)
    );
    return indexofdate;
  }
};
