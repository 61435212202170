import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { FC, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { UNIFIED_API_CLIENT_NAME } from '../ApolloProvider';
import { TableFilterValues, useTableFilter } from '../TableFilter';
import SELECT_PROPS from './select-props';
// eslint-disable-next-line import/no-unresolved
import { CustomerSelectQuery } from './__generated__/CustomerSelect';
import { TestID } from '../../utils/constants/DataTestId';

type SelectValue = { value: number; label: string };

type CustomerSelectProps = {
  customerId?: number;
  setCustomerId: (customerId?: number) => void;
  setCustomerIdLabel?: (label?: string) => void;
  handleInitialChange?: (value: number, label?: string) => void;
  PreselectKunde?: boolean;
};

const CustomerSelect: FC<CustomerSelectProps> = ({
  customerId,
  setCustomerId,
  setCustomerIdLabel,
  handleInitialChange,
  PreselectKunde,
}) => {
  const { getStoredValues } = useTableFilter();
  const selectOptions = useCustomerSelectOptions();
  const value = selectOptions?.find(({ value }) => value === customerId);
  useEffect(() => setCustomerIdLabel?.(value?.label), [value, selectOptions]);

  useEffect(() => {
    if (!PreselectKunde) return;
    const filterContainsCustomerID = !!(
      getStoredValues() as unknown as TableFilterValues
    )?.customerId;

    if (selectOptions?.[0]) {
      if (!filterContainsCustomerID) {
        handleInitialChange?.(
          selectOptions?.[0].value,
          selectOptions?.[0].label
        );
      }
    }
  }, [selectOptions, getStoredValues]);

  return (
    <div className="mb-2" data-testid={TestID.CustomerSelectField}>
      <label>Kunde</label>
      <Select<SelectValue>
        {...SELECT_PROPS<SelectValue>()}
        isClearable={!PreselectKunde}
        placeholder="Kunden auswählen"
        options={selectOptions}
        value={value}
        onChange={(value) => setCustomerId(value?.value)}
      />
    </div>
  );
};

const CUSTOMER_QUERY = gql`
  query CustomerSelect {
    customer(where: { active: { _eq: true } }) {
      id
      name: companyName
      active
    }
  }
`;

const useCustomerSelectOptions = () => {
  const { data } = useQuery<CustomerSelectQuery>(CUSTOMER_QUERY, {
    context: { clientName: UNIFIED_API_CLIENT_NAME },
  });
  // Adding filtering data as well so that if something get passed with inactive or null it will handle it
  return useMemo(
    () =>
      data?.customer
        .filter((element) => element.active === true)
        ?.map(({ id, name }) => ({
          value: Number(id),
          label: name,
        })),
    [data]
  );
};

export default CustomerSelect;
