import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Joi from 'joi';
import { FC, ReactNode, useState } from 'react';
import { Button, Col, FormControl, Modal, Row } from 'react-bootstrap';
import { FaPaperPlane, FaShieldAlt, FaTrashAlt } from 'react-icons/fa';
import Select from 'react-select';
import { alertSuccess } from '../../services/alert';
import {
  AddressCategory,
  BAUSPAREN_LABEL,
  FINANCE_LABEL,
  INSURANCE_LABEL,
  PRIMARY_LABEL,
  REALTOR_LABEL,
} from '../../utils/address-category';
import SELECT_PROPS from '../filter-items/select-props';
import { useSendXmlEmail } from '../../utils/hooks';
import {
  ExportModalAddressQuery,
  ExportModalDeleteAddressMutation,
  ExportModalDeleteAddressMutationVariables,
  ExportModalCreateAddressMutation,
  ExportModalCreateAddressMutationVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/ExportModal';

type ExportModalProps = {
  runId: uuid;
  exportUserCallback?: () => void;
  children?: ReactNode;
};

const ExportModal: FC<ExportModalProps> = ({
  runId,
  exportUserCallback,
  children,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [email, setEmail] = useState<string>();
  const [emailToDelete, setEmailToDelete] = useState<string | undefined>();

  const [newAddress, setNewAddress] = useState<Address>();

  const createAddress = useCreateAddress();
  const deleteAddress = useDeleteAddress();
  const { sendEmail } = useSendXmlEmail();
  const { refetch } = useQuery<ExportModalAddressQuery>(ADDRESS_QUERY);

  const handleAction = () => {
    if (!emailToDelete) {
      handleExport();
      return;
    }
    handleDelete();
  };

  const handleDelete = () => {
    if (!emailToDelete) return;
    deleteAddress(emailToDelete).then((_) => {
      refetch();
      alertSuccess('E-Mail-Adresse wurde entfernt.');
      setEmailToDelete(undefined);
      setIsModalVisible(false);
    });
  };

  const handleExport = () => {
    const emailToSend = email || newAddress?.email;
    if (!emailToSend) return;
    if (newAddress?.email) {
      createAddress(newAddress?.email, newAddress?.category).catch(() => null);
    }
    sendEmail(runId, emailToSend).finally(() => exportUserCallback?.());
    setIsModalVisible(false);
  };

  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => setIsModalVisible(true)}
      >
        {children}
      </span>
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Body>
          <Row className="mb-2 mt-4">
            <Col lg={2} className="text-center">
              <FaPaperPlane size={32} />
            </Col>
            <Col lg={10}>
              <h3>Visitenkarte & XML per E-Mail versenden:</h3>
              <SelectAddress
                email={email}
                newAddress={newAddress}
                onEmailChange={setEmail}
                onNewAddressChange={setNewAddress}
              />
            </Col>
          </Row>
          {!email && !newAddress && (
            <Row className="mb-2 mt-4">
              <Col lg={2} className="text-center">
                <FaTrashAlt size={32} />
              </Col>
              <Col lg={10}>
                <h3>E-Mail-Adresse löschen:</h3>
                <DeleteAddress
                  email={emailToDelete}
                  onEmailChange={setEmailToDelete}
                />
              </Col>
            </Row>
          )}

          <Row className="mb-2 mt-4">
            <Col lg={2} className="text-center">
              <FaShieldAlt size={32} />
            </Col>
            <Col lg={10}>
              <h3>Datenschutz beachten!</h3>
              <p>
                Bei dieser Aktion werden personenbezogene Daten bearbeitet oder
                exportiert. Bitte gehen Sie besonders sorgfältig vor, um nicht
                gegen die aktuellen Vorschriften des Datenschutzes zu verstoßen.
              </p>
              <p>Sprechen Sie im Zweifel Ihren Datenschutzbeauftragten an.</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
          <Button variant="primary" onClick={() => setIsModalVisible(false)}>
            Abbrechen
          </Button>
          <Button
            variant="primary"
            onClick={handleAction}
            disabled={!email && !newAddress?.email && !emailToDelete}
          >
            {emailToDelete ? 'Löschen' : 'Exportieren'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

type SelectAddressProps = {
  email?: string;
  newAddress?: Address;
  onEmailChange: (email?: string) => void;
  onNewAddressChange: (newAddress?: Address) => void;
};

const SelectAddress: FC<SelectAddressProps> = ({
  email,
  newAddress,
  onEmailChange,
  onNewAddressChange,
}) => {
  const { data } = useQuery<ExportModalAddressQuery>(ADDRESS_QUERY);
  const [emailValidationError, setEmailValidationError] =
    useState<Joi.ValidationError>();

  const addressOptions: AddressSelectOption[] = [
    { value: true, label: 'Neue E-Mail-Adresse anlegen' },
    ...(data?.wma_address_book.map(({ email_address: email }) => ({
      value: email,
      label: email,
    })) || []),
  ];

  const handleSelectChange = (value?: string | NewAddressOption) => {
    if (value === undefined) {
      onEmailChange(undefined);
      onNewAddressChange(undefined);
      return;
    }
    if (value === true) {
      onEmailChange(undefined);
      onNewAddressChange({
        email: newAddress?.email,
        category: newAddress?.category,
      });
      return;
    }
    onEmailChange(value);
    onNewAddressChange(undefined);
  };

  const categoryOptions: CategorySelectOption[] = [
    { value: undefined, label: 'Kategorie wählen' },
    { value: 'PRIMARY', label: PRIMARY_LABEL },
    { value: 'REALTOR', label: REALTOR_LABEL },
    { value: 'FINANCE', label: FINANCE_LABEL },
    { value: 'BAUSPAREN', label: BAUSPAREN_LABEL },
    { value: 'INSURANCE', label: INSURANCE_LABEL },
  ];

  const handleNewAddressChange = (
    email?: string,
    category?: AddressCategory
  ) => {
    const { error } = emailSchema.validate(email);
    setEmailValidationError(error);
    if (!error) {
      onNewAddressChange({ email, category });
    } else {
      onNewAddressChange({ email: undefined, category });
    }
  };

  return (
    <>
      <Select<AddressSelectOption>
        {...SELECT_PROPS<AddressSelectOption>()}
        isClearable
        placeholder="E-Mail-Adresse auswählen"
        options={addressOptions}
        value={addressOptions?.find(
          ({ value }) => (newAddress && value === true) || value === email
        )}
        onChange={(value) => handleSelectChange(value?.value)}
      />

      {newAddress && (
        <>
          <FormControl
            className="form-control mt-4"
            placeholder="E-Mail Adresse eingeben"
            onChange={(e) =>
              handleNewAddressChange(e.target.value, newAddress.category)
            }
          />
          {emailValidationError && (
            <span className="text-danger">
              E-Mail hat nicht das korrekte Format.
            </span>
          )}
          <Select<CategorySelectOption>
            className="mt-4"
            {...SELECT_PROPS<CategorySelectOption>()}
            isClearable
            options={categoryOptions}
            value={categoryOptions.find(
              ({ value }) => value === newAddress.category
            )}
            onChange={(value) =>
              handleNewAddressChange(newAddress.email, value?.value)
            }
          />
          <p>
            Wählen Sie bei neuen Adressen aus, als was der Empfänger
            kategorisiert werden soll.
          </p>
        </>
      )}
    </>
  );
};

type DeleteAddressProps = {
  email?: string;
  onEmailChange: (email?: string) => void;
};

const DeleteAddress: FC<DeleteAddressProps> = ({ email, onEmailChange }) => {
  const { data } = useQuery<ExportModalAddressQuery>(ADDRESS_QUERY);

  const addressOptions: DeleteSelectOption[] = [
    ...(data?.wma_address_book.map(({ email_address: email }) => ({
      value: email,
      label: email,
    })) || []),
  ];

  return (
    <>
      <Select<DeleteSelectOption>
        key={`my_unique_select_key__${email}`}
        {...SELECT_PROPS<DeleteSelectOption>()}
        isClearable
        placeholder="E-Mail-Adresse löschen"
        options={addressOptions}
        value={addressOptions?.find(({ value }) => value === email)}
        onChange={(value) => onEmailChange(value?.value)}
      />
    </>
  );
};

const emailSchema = Joi.string().email({ tlds: { allow: false } });

const ADDRESS_QUERY = gql`
  query ExportModalAddress {
    wma_address_book {
      id: uuid
      email_address
      category
    }
  }
`;

const CREATE_ADDRESS_MUTATION = gql`
  mutation ExportModalCreateAddress($email: String!, $category: String) {
    insert_wma_address_book_one(
      object: { email_address: $email, category: $category }
    ) {
      uuid
      email_address
      category
    }
  }
`;

const DELETE_ADDRESS_MUTATION = gql`
  mutation ExportModalDeleteAddress($email: String!) {
    delete_wma_address_book(where: { email_address: { _eq: $email } }) {
      affected_rows
    }
  }
`;

const useDeleteAddress = () => {
  const [mutation] = useMutation<
    ExportModalDeleteAddressMutation,
    ExportModalDeleteAddressMutationVariables
  >(DELETE_ADDRESS_MUTATION);

  return (email: string) => mutation({ variables: { email } });
};

const useCreateAddress = () => {
  const [mutation] = useMutation<
    ExportModalCreateAddressMutation,
    ExportModalCreateAddressMutationVariables
  >(CREATE_ADDRESS_MUTATION);

  return (email: string, category?: string) =>
    mutation({ variables: { email, category } });
};

type NewAddressOption = true;
type AddressSelectOption = {
  value: string | NewAddressOption;
  label: string;
};

type DeleteSelectOption = {
  value: string;
  label: string;
};

type Address = {
  email?: string;
  category?: AddressCategory;
};

type CategorySelectOption = {
  value?: AddressCategory;
  label: string;
};

export default ExportModal;
