import gql from 'graphql-tag';
import moment from 'moment';
import React, { FC } from 'react';
import { formatPercent } from '../../utils/format';
import Box from '../Box';
import Loading from '../Loading';
// eslint-disable-next-line import/no-unresolved
import { LateContactCardFragment } from './__generated__/LateContactCard';

type LateContactCardProps = {
  fragments: (LateContactCardFragment | undefined)[];
  size?: number;
  loading?: boolean;
};

const LateContactCard: FC<LateContactCardProps> = ({
  fragments,
  size = 12,
  loading,
}) => {
  const totalCount = fragments.length;
  const lateContactCount = fragments.filter((fragment) => {
    const acceptanceDate = fragment?.acceptance_date;
    const contactDate = fragment?.cockpit_telephone_displayed_at;

    if (acceptanceDate) {
      const dayDiff = moment(contactDate).diff(moment(acceptanceDate), 'days');
      if (!contactDate || dayDiff > 7) {
        return true;
      }
    }
    return false;
  }).length;

  const percent = formatPercent(
    totalCount !== 0 ? (lateContactCount / totalCount) * 100 : 0
  );

  return (
    <Box size={size} title="Kontaktaufnahme später als 7 Tage">
      {loading && <Loading />}
      {!loading && (
        <>
          <p className="fs-2">
            {lateContactCount}
            &nbsp;
            <span className="text-secondary">({percent})</span>
          </p>
          <p>
            Kontakte wurden nie oder erst nach über einer Woche kontaktiert.
          </p>
        </>
      )}
    </Box>
  );
};

export const LATE_CONTACT_CARD_FRAGMENT = gql`
  fragment LateContactCard on wma_metadata {
    acceptance_date
    cockpit_telephone_displayed_at
  }
`;

export default LateContactCard;
