import classNames from 'classnames';
import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { getContactDeadline } from '../../utils/contact';
import { TestID } from '../../utils/constants/DataTestId';
import type { Loop5Type } from './types';

export type ContactDeadlineBadgeProps = {
  userExists: boolean;
  permissionGranted: boolean;
  acceptedAt?: string;
  verbose?: boolean;
  loopType?: Loop5Type;
  isSparkasse?: boolean;
};

const ContactDeadlineBadge: FC<ContactDeadlineBadgeProps> = ({
  userExists,
  permissionGranted,
  acceptedAt,
  verbose = false,
  loopType = 'NOT_LOOP',
  isSparkasse = false,
}) => {
  const isLoop5 = loopType !== 'NOT_LOOP';
  const contactDeadline = getContactDeadline({
    userExists,
    permissionGranted,
    acceptedAt,
    isLoop5,
    isSparkasse,
  });
  const userRemovedText = userExists
    ? '-'
    : 'Personenbezogene Daten wurden gelöscht.';
  const permissionGrantedText =
    'Der Kunde hat seine Werbeeinwilligung gegeben, und darf bis zu seinem Widerruf kontaktiert werden. Der Kunde hat jederzeit die Möglichkeit seine Einwilligung zu widerrufen. Bitte beachten Sie auch, dass Sie den Kunden ggf. nicht zu jedem Themenbereich kontaktieren dürfen.';
  const normalWmaText = `Gemäß DSGVO werden die personenbezognen Daten zu diesem Datensatz in ${contactDeadline} Tagen gelöscht.`;
  const loopWmaText = `Dieser Datensatz bekommt alle 3 Monate eine Aktualisierung seines Abrufs. Um diesen Service anbieten zu können, halten wir die personenbezogenen Daten für 365 Tage vor. Gemäß DSGVO werden die personenbezognen Daten zu diesem Datensatz in ${contactDeadline} Tagen gelöscht.`;

  if (verbose) {
    if (contactDeadline === 'USER_REMOVED') return <>{userRemovedText}</>;
    if (contactDeadline === 'PERMISSION_GRANTED')
      return <>{permissionGrantedText}</>;
    return <>{isLoop5 ? loopWmaText : normalWmaText}</>;
  }

  if (contactDeadline === 'USER_REMOVED')
    return (
      <span data-tip={userRemovedText}>
        <span
          style={{ width: '40px', paddingLeft: '36px' }}
          className="badge badge-secondary"
        >
          -
        </span>
        <ReactTooltip />
      </span>
    );

  if (contactDeadline === 'PERMISSION_GRANTED')
    return (
      <span data-tip={permissionGrantedText}>
        <span
          style={{ width: '40px', paddingLeft: '36px' }}
          className="badge badge-primary"
        >
          -
        </span>
        <ReactTooltip />
      </span>
    );

  return (
    <div
      style={{
        minWidth: '100px',
      }}
      data-testid={TestID.BadgeContactDeadline}
    >
      <span data-tip={isLoop5 ? loopWmaText : normalWmaText}>
        <span
          style={{
            paddingLeft: contactDeadline >= 1 ? '18px' : '36px',
          }}
          className={classNames({
            'badge-primary': contactDeadline > 30,
            'badge-warning': contactDeadline <= 30 && contactDeadline > 0,
          })}
        >
          {contactDeadline <= 0 ? '-' : `${contactDeadline} Tage`}
        </span>
        <ReactTooltip />
      </span>
    </div>
  );
};

export default ContactDeadlineBadge;
