import { FC, useEffect } from 'react';
import Select from 'react-select';
import SELECT_PROPS from './select-props';

type SelectValue = { value?: boolean; label: string };

type IsNewBuildingSelectProps = {
  isNewBuilding?: boolean;
  setIsNewBuilding: (isNewBuilding?: boolean) => void;
  setIsNewBuildingLabel?: (label?: string) => void;
};

const IsNewBuildingSelect: FC<IsNewBuildingSelectProps> = ({
  isNewBuilding,
  setIsNewBuilding,
  setIsNewBuildingLabel,
}) => {
  const value = SELECT_OPTIONS?.find(({ value }) => value === isNewBuilding);
  useEffect(() => setIsNewBuildingLabel?.(value?.label));

  return (
    <div className="mb-2">
      <label>Bestand / Neubau</label>
      <Select<SelectValue>
        {...SELECT_PROPS<SelectValue>()}
        isClearable={isNewBuilding !== undefined}
        options={SELECT_OPTIONS}
        value={value}
        onChange={(value) => setIsNewBuilding(value?.value)}
      />
    </div>
  );
};

const SELECT_OPTIONS: SelectValue[] = [
  { value: undefined, label: 'Alle' },
  { value: true, label: 'Neubau' },
  { value: false, label: 'Bestand' },
];

export default IsNewBuildingSelect;
