export enum TestID {
  FlexTable = 'flex-expandable-table',
  SearchTable = 'search-table',
  Loop5Filter = 'loop5-filter',
  WmaTable = 'wma-table',
  CustomerSelectField = 'select-customer-field',
  StatusSelectField = 'select-status-field',
  TimePeriodSelect = 'select-time-period',
  StartTimePeriod = 'time-period-start',
  EndTimePeriod = 'time-period-end',
  SuccessAlert = 'alert-success',
  BtnUpdateTable = 'update-table-button',
  BtnExcelExport = 'excel-export-button',
  ExpanderWmaData = 'wma-data-expander',
  AnchorMyWmaDashboard = 'my-wma-dashboard-anchor',
  WmaId = 'wma-id',
  WmaPhone = 'wma-phone',
  WmaExecutionDate = 'wma-date',
  WmaEmail = 'wma-email',
  WmaPlz = 'wma-plz',
  WmaFullName = 'wma-full-name',
  WmaStatus = 'wma-status',
  BadgeContactDeadline = 'contact-deadline-badge',
  BtnDeletePersonalData = 'delete-personal-data-button',
  BtnExportData = 'export-data-button',
  SaleModalUpsert = 'sale-modal-upsert',
  SaleModalUpsertAbbrechenButton = 'sale-modal-upsert-abbrechen-button',
  SaleModalUpsertLoschenButton = 'sale-modal-upsert-loschen-button',
  SaleModalUpsertSpeichernButton = 'sale-modal-upsert-speichern-button',
  DeletePersonalDataModal = 'delete-personal-data-modal',
  DeletePersonalDataModalAbbrechenButton = 'delete-personal-data-modal-abbrechen-button',
  DeletePersonalDataModalBestätigenButton = 'delete-personal-data-modal-bestatigen-button',
  ErfolgscockpitLocationPinsCardMap = 'erfolgscockpit-location-pins-card-map',
}
