import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { FC, useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Column, useTable } from 'react-table';
import { formatCurrency, formatDateLong } from '../../utils/format';
import Loading from '../Loading';
import OneLine from '../OneLine';
import { FaDownload } from 'react-icons/fa';
import { HiRefresh } from 'react-icons/hi';
import { AddTestRunFilter } from '../../utils/where-exprs';
import { DownloadBlockedIcon } from '../../assets';
import { getWMALinkLong } from '../../utils/my-wma-link';
import {
  Loop5ListQuery,
  Loop5ListQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/Loop5List';
import { alertError } from '../../services/alert';
import { LoadingOutlined, ExportOutlined } from '@ant-design/icons';

type Loop5ListProps = {
  wmaId: uuid;
};
type CellType = { cell: { row: { index: number } } };

type DownloadLinkMap = Record<string, string | undefined>;
type DownloadLinkReadyMap = Record<string, boolean | undefined>;
type Metadata = NonNullable<Loop5ListQuery['wma_input_by_pk']>['metadata'];

const Loop5List: FC<Loop5ListProps> = ({ wmaId }) => {
  const { data, loading } = useQuery<Loop5ListQuery, Loop5ListQueryVariables>(
    QUERY,
    { variables: { wmaId } }
  );
  const [loadingLinks, setLoadingLinks] = useState<boolean>(true);
  const [downloadLoader, setDownloadLoader] = useState<boolean>(false);
  const [downloadLoaderId, setDownloadLoaderId] = useState<string>('');
  const [downloadLinks, setDownloadLinks] = useState<DownloadLinkMap>({});
  const [downloadLinksReady, setDownloadLinksReady] =
    useState<DownloadLinkReadyMap>({});

  const setLinks = async (metadata: Metadata) => {
    setLoadingLinks(true);
    const linksArrayPromises = metadata.map(async (meta, index) => {
      const link = getWMALinkLong(meta, index);
      let response: Response | null = null;
      if (link && meta.is_legacy) {
        try {
          response = await fetch(link);
        } catch (e) {
          return {
            id: meta.id,
            link: undefined,
          };
        }
      }
      return {
        id: meta.id,
        link: response?.statusText !== 'Gone' ? link : undefined,
      };
    });
    const linksArray = await Promise.all(linksArrayPromises);
    const links: DownloadLinkMap = {};
    linksArray.forEach(({ id, link }) => {
      links[id] = link;
    });
    setDownloadLinks(links);
    setLoadingLinks(false);
  };

  useEffect(() => {
    if (!data?.wma_input_by_pk?.metadata) return;

    const metadata = data?.wma_input_by_pk?.metadata;
    setLinks(metadata);
  }, [data, downloadLoader, downloadLinksReady]);

  const handleDownloadPDF = (value: string) => {
    setDownloadLoader(true);
    setDownloadLoaderId(value);

    const myHeaders = new Headers();
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(downloadLinks[value] as string, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          setDownloadLoader(false);
          downloadLinksReady[value] = true;
          setDownloadLinksReady(downloadLinksReady);
        }
      })
      .catch((error) =>
        alertError(
          'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.',
          error
        )
      )
      .finally();
  };

  const columns = useMemo(
    (): Column<Metadata[0]>[] => [
      {
        Header: 'WMA',
        id: 'link',
        accessor: (run) => run.id,
        Cell: ({ value, ...rest }: { value: string }) => {
          const { cell } = rest as CellType;
          return (
            <div style={{ width: '60px' }}>
              {downloadLinks[value] ? (
                downloadLoader && value === downloadLoaderId ? (
                  <LoadingOutlined />
                ) : cell.row.index === 0 ? (
                  <a
                    href={downloadLinks[value]}
                    target="_blank"
                    className="link-dark"
                    rel="noreferrer"
                  >
                    <ExportOutlined />
                  </a>
                ) : downloadLinksReady[value] ? (
                  <OneLine>
                    <a
                      href={downloadLinks[value]}
                      className="link-dark"
                      rel="noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <FaDownload
                        color="#1ab394"
                        onClick={() => {
                          handleDownloadPDF(value);
                        }}
                      />
                      <> &nbsp;Download bereit</>
                    </a>
                  </OneLine>
                ) : (
                  <FaDownload
                    onClick={() => {
                      handleDownloadPDF(value);
                    }}
                  />
                )
              ) : (
                <div
                  style={{ cursor: 'pointer' }}
                  title="Diese WMA liegt mehr als 6 Monate in der Vergangenheit und ist daher nicht mehr als Download verfügbar."
                >
                  <DownloadBlockedIcon />
                </div>
              )}
            </div>
          );
        },
      },

      {
        Header: 'Abrufdatum',
        accessor: (run) => run.acceptance_date,
        Cell: ({ value }: { value?: string }) => (
          <span>{formatDateLong(value) || '-'}</span>
        ),
      },
      {
        Header: 'Richtwert',
        accessor: (run) => run.output?.reference_value,
        Cell: ({ value }: { value?: number }) => (
          <OneLine>{formatCurrency(value)}</OneLine>
        ),
      },
    ],
    [downloadLinks]
  );

  const tableInstance = useTable<Metadata[0]>({
    columns,
    data: data?.wma_input_by_pk?.metadata || [],
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <>
      <HiRefresh style={{ color: '#1ab394', marginBottom: '5px' }} /> Bei dieser
      WMA handelt es sich um einenen Abo-Versand (Loop5)
      <br />
      <strong>Abrufhistorie dieses Objekts</strong>
      <br />
      Haupt-ID: {wmaId.split('-')[0].toUpperCase()}
      {loading || loadingLinks ? (
        <div style={{ width: '100px' }}>
          <Loading />
        </div>
      ) : (
        <Table className="w-50" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

const QUERY = gql`
  query Loop5List($wmaId: uuid!) {
    wma_input_by_pk(wma_id: $wmaId) {
      id: wma_id
      contract_id
      wma_id
      metadata(
        where: { is_failure: { _eq: false } ${AddTestRunFilter()} }
        order_by: { acceptance_date: desc }
      ) {
        id: uuid
        is_legacy
        legacy_id
        acceptance_date
        output {
          id: uuid
          reference_value: iib_immobilien_richtwert
          result_access_keys
        }
        input {
          wma_id
          customer_number
          contract_id
        }
      }
      customer_number
    }
  }
`;

export default Loop5List;
