import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
`;

export const Card = styled.div`
  background-color: #fff;
  padding: 40px 30px;
  width: 550px;
  line-height: 18px;
  font-weight: 350;
  color: #757575;
  letter-spacing: 1px;
  font-size: 16px;
`;
