import React, { FC } from 'react';
import Loading from './Loading';

const AppLoading: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        // justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ecf0f5',
      }}
    >
      <Loading size={10} />
    </div>
  );
};

export default AppLoading;
