import moment from 'moment';
import { StatsFilterValues } from '../components/StatsFilter';
import { TableCampaignFilterValues } from '../components/TableCampaignFilter';
import { TableFilterValues } from '../components/TableFilter';
import momentToQueryDate from './moment-to-query-date';
import { getObjectTypeBoolExpr } from './object-type';
import getEnv from './get-env';
import { wma_metadata_bool_exp } from '../graphql.types';
import { getStatusFilterBoolExpr } from './status-filter';
import {
  getDateFilter,
  getInternalRequestFilter,
  getNewBuildingFilter,
  getUserConsentFilter,
} from './where-exprs-helpers';

export const AddTestRunFilter = (): '' | ', is_test_run: { _eq: false }' =>
  getEnv('REACT_APP_INCLUDE_TEST_RUN') === 'true'
    ? ''
    : ', is_test_run: { _eq: false }';

export const getMetadataStatsFilterExpr = ({
  startDate,
  endDate,
  customerId,
}: StatsFilterValues): wma_metadata_bool_exp => {
  const maxDate = moment(endDate).add(1, 'days');

  return {
    _and: [
      { acceptance_date: { _gte: momentToQueryDate(startDate) } },
      { acceptance_date: { _lt: momentToQueryDate(maxDate) } },
      customerId
        ? {
            _or: [
              { input: { customer_number: { _eq: customerId } } },
              { output: { commercial_agent_id: { _eq: customerId } } },
            ],
          }
        : {},
    ],
  };
};

export const getMetadataTableFilterExpr = ({
  startDate,
  endDate,
  isInternal,
  isLoop5,
  statusFilter,
  objectType,
  isNewBuilding,
  goals,
  hasUserConsent,
}: TableFilterValues): wma_metadata_bool_exp => {
  const maxDate = moment(endDate).add(1, 'days');
  const query: wma_metadata_bool_exp = {
    _and: [
      ...getDateFilter(startDate, maxDate),
      getInternalRequestFilter(isInternal),
      isLoop5 !== 'UNSPECIFIED' ? { is_loop5: { _eq: isLoop5 } } : {},
      statusFilter ? getStatusFilterBoolExpr(statusFilter) : {},
      objectType ? { input: getObjectTypeBoolExpr(objectType) } : {},
      getNewBuildingFilter(isNewBuilding),
      goals ? { input: { request_motivation: { _in: goals } } } : {},
      getUserConsentFilter(hasUserConsent),
    ],
  };

  return query;
};

export const getMetadataTableCampaignFilterExpr = ({
  modernizedRoof,
  modernizedWindows,
  modernizedFloors,
  modernizedBathroom,
  modernizedThermalInsulation,
  modernizedHeating,
  modernizedInteriorWalls,
  modernizedElectricalInstallation,
}: TableCampaignFilterValues): wma_metadata_bool_exp => ({
  _and: [
    modernizedRoof !== undefined
      ? { input: { modernization_roof: { _lt: 100 } } }
      : {},
    modernizedWindows !== undefined
      ? { input: { modernization_windows: { _lt: 100 } } }
      : {},
    modernizedFloors !== undefined
      ? { input: { modernization_floors: { _lt: 100 } } }
      : {},
    modernizedBathroom !== undefined
      ? { input: { modernization_bathroom: { _lt: 100 } } }
      : {},
    modernizedThermalInsulation !== undefined
      ? { input: { modernization_thermal_insulation: { _lt: 100 } } }
      : {},
    modernizedHeating !== undefined
      ? { input: { modernization_heating: { _lt: 100 } } }
      : {},
    modernizedInteriorWalls !== undefined
      ? { input: { modernization_interior_walls: { _lt: 100 } } }
      : {},
    modernizedElectricalInstallation !== undefined
      ? { input: { modernization_electrical_installation: { _lt: 100 } } }
      : {},
  ],
});

export const getMetadataTableSearchQueryExpr = (
  searchQuery?: string
): wma_metadata_bool_exp => {
  searchQuery = searchQuery?.trim();

  if (!searchQuery) return {};
  const searchPattern = `${searchQuery}%`;

  const filterParams = {
    _or: [
      { legacy_id: { _ilike: searchPattern } },
      { input: { user: { email: { _ilike: searchPattern } } } },
      { input: { user: { first_name: { _ilike: searchPattern } } } },
      { input: { user: { last_name: { _ilike: searchPattern } } } },
      { input: { object_data__postal_code: { _ilike: searchPattern } } },
      { input: { object_data__city: { _ilike: searchPattern } } },
    ],
  } as wma_metadata_bool_exp;

  if (searchQuery.includes(' ') && filterParams?._or) {
    const [firstName, lastName] = searchQuery.split(' ');
    filterParams._or.push({
      _and: [
        { input: { user: { first_name: { _ilike: `${firstName}%` } } } },
        { input: { user: { last_name: { _ilike: `${lastName}%` } } } },
      ],
    });
  }

  if (isHex(searchQuery) && filterParams?._or) {
    filterParams._or.push({
      input: {
        wma_id: {
          _gte: appendZeros(searchQuery),
          _lt: addToHexadecimal(searchQuery),
        },
      },
    });
  }

  return filterParams;
};

const isHex = (str: string) => {
  const reg = /^([0-9a-f]{1,8})$/i;
  return reg.test(str);
};

const appendZeros = (str: string) => {
  const searchQuery = String(str).padEnd(8, '0');
  return `${searchQuery}-0000-0000-0000-000000000000`;
};

const addToHexadecimal = (c1: string) => {
  const newHexString = (parseInt(c1, 16) + parseInt('00000001', 16)).toString(
    16
  );
  return appendZeros(String(newHexString).padStart(c1.length, '0'));
};
