import React, { FC } from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';

const ReportingNav: FC = () => {
  const { pathname } = useLocation();

  return (
    <div className="tabs-container">
      <Nav as="ul" variant="tabs" defaultActiveKey={pathname}>
        <Nav.Item as="li" key="/reporting">
          <Nav.Link href="/reporting" to="/reporting" as={Link}>
            Übersicht
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="/reporting_analysis">
          <Nav.Link
            href="/reporting_analysis"
            to="/reporting_analysis"
            as={Link}
          >
            W-Analysen
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="/reporting_timing">
          <Nav.Link href="/reporting_timing" to="/reporting_timing" as={Link}>
            Timing
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default ReportingNav;
