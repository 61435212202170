import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { FC, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
// eslint-disable-next-line import/no-unresolved

import Box from '../components/Box';
import ExcelExport from '../components/ExcelExport';
import Layout from '../components/Layout';
import TableCampaignFilter, {
  useTableCampaignFilter,
} from '../components/TableCampaignFilter';
import TableFilter, { useTableFilter } from '../components/TableFilter';
import WMATable, { WMA_TABLE_FRAGMENT } from '../components/WMATable';
import {
  AddTestRunFilter,
  getMetadataTableCampaignFilterExpr,
  getMetadataTableFilterExpr,
  getMetadataTableSearchQueryExpr,
} from '../utils/where-exprs';
import { getCustomerIdFilter } from '../utils/where-exprs-helpers';

import { useDownloadExcel } from '../utils/hooks';
// eslint-disable-next-line import/no-unresolved
import { wma_metadata_bool_exp } from '../graphql.types';
import {
  CampaignFilterQuery,
  CampaignFilterQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/CampaginFilter';

const CampaignFilter: FC = () => {
  const {
    filter: tableFilter,
    updateFilter: updateTableFilter,
    filterLabels: tableFilterLabels,
    updateFilterLabels: updateTableFilterLabels,
  } = useTableFilter();
  const { filter: campaignFilter, updateFilter: updateCampaignFilter } =
    useTableCampaignFilter();
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  // state used to combine multiple filters which can be triggered simultaneously if state is true
  const [filterEnabled, setFilterEnabled] = useState(false);
  const { commercialAgentFilter, customerNumberFilter } = getCustomerIdFilter(
    tableFilter.customerId
  );

  const filterExpr: wma_metadata_bool_exp = {
    _and: [
      getMetadataTableFilterExpr(tableFilter),
      getMetadataTableCampaignFilterExpr(campaignFilter),
      getMetadataTableSearchQueryExpr(searchQuery),
    ],
  };

  const downloadExcel = useDownloadExcel({
    filterLabels: tableFilterLabels,
    filterExpr,
  });

  const { data, fetchMore, loading } = useQuery<
    CampaignFilterQuery,
    CampaignFilterQueryVariables
  >(CAMPAIGN_FILTER_QUERY, {
    variables: {
      where: filterExpr,
      beforeAcceptance: '2999-01-01',
      offset: page === 1 ? 0 : (page - 1) * 30,
      commercialAgentFilter,
      customerNumberFilter,
    },
  });

  const currentCount = data?.wma_metadata.length || 0;
  const customerFilterCount =
    data?.wma_metadata_aggregate_customer_filter_count?.aggregate?.count || 0;
  const CommercialAgentFilterCount =
    data?.wma_metadata_aggregate_commercial_agent_filter_count?.aggregate
      ?.count || 0;
  const totalCount = tableFilter.customerId
    ? customerFilterCount + CommercialAgentFilterCount
    : customerFilterCount;
  const limitItemsPerPage = 30;

  const handleLoadMore =
    totalCount > currentCount
      ? (selectedCurrentPage: number) => {
          const adjustOffsetMulti = selectedCurrentPage === 1 ? 0 : -1;
          setPage(selectedCurrentPage);
          // offset calculates where to start to fetch data. Limit = max items to fetch. Page 1 = 0-30, Page 2= 30-60.
          fetchMore({
            variables: {
              where: filterExpr,
              beforeAcceptance: '2999-01-01',
              offset:
                selectedCurrentPage === 1
                  ? adjustOffsetMulti
                  : (selectedCurrentPage - adjustOffsetMulti) *
                    limitItemsPerPage,
            },
          });
          return {
            wma_metadata_aggregate_customer_filter_count:
              data?.wma_metadata_aggregate_customer_filter_count,
            wma_metadata_aggregate_commercial_agent_filter_count:
              data?.wma_metadata_aggregate_commercial_agent_filter_count,
            wma_metadata: [...(data?.wma_metadata || [])],
          };
        }
      : undefined;

  const handleChange = () => {
    setFilterEnabled(true);
  };

  return (
    <Layout title="Kampagnenfilter">
      {/* <Row> */}
      <Box size={12} title="Filter">
        <Row className="mt-3">
          <Col>
            <TableFilter
              initialValues={tableFilter}
              onValuesChange={(values) => updateTableFilter(values)}
              onLabelsChange={(labels) => updateTableFilterLabels(labels)}
              filterEnabled={filterEnabled}
              setFilterEnabled={setFilterEnabled}
            />
          </Col>
          <Col>
            <TableCampaignFilter
              initialValues={campaignFilter}
              onValuesChange={updateCampaignFilter}
              filterEnabled={filterEnabled}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={{ span: 3, offset: 9 }}>
            <Button className="w-100" onClick={handleChange}>
              Tabelle aktualisieren
            </Button>
          </Col>
        </Row>
      </Box>

      {/* <Box size={6} title="">
          <TableCampaignFilter
            initialValues={campaignFilter}
            onValuesChange={updateCampaignFilter}
            filterEnabled={filterEnabled}
          />
          <Row className="mt-3">
            <Col lg={{ span: 3, offset: 9 }}>
              <Button className="w-100" onClick={handleChange}>
                Tabelle aktualisieren
              </Button>
            </Col>
          </Row>
        </Box> */}
      {/* </Row> */}
      <Row>
        <Box
          size={12}
          title={
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">Einzelabrufe</div>
              <ExcelExport style={{ fontSize: 13 }} onExport={downloadExcel} />
            </div>
          }
        >
          <WMATable
            fragments={data?.wma_metadata || []}
            totalCount={totalCount}
            searchQuery={searchQuery}
            onSearchQueryChange={setSearchQuery}
            loadMore={handleLoadMore}
            loading={loading}
          />
        </Box>
      </Row>
    </Layout>
  );
};

// To change max items per page -> edit the limit and adjust the offset in above function.
const CAMPAIGN_FILTER_QUERY = gql`
  ${WMA_TABLE_FRAGMENT}
  query CampaignFilter(
    $where: wma_metadata_bool_exp!
    $beforeAcceptance: timestamp!
    $offset: Int!
    $commercialAgentFilter: wma_metadata_bool_exp!
    $customerNumberFilter: wma_metadata_bool_exp!
  ) {
    wma_metadata_aggregate_customer_filter_count: wma_metadata_aggregate(
      where: {
        _and: [
          $where,
          $customerNumberFilter,
          { is_failure: { _eq: false } ${AddTestRunFilter()} }
          { acceptance_date: { _lt: $beforeAcceptance } }
          { input: { is_esg: { _neq: true } } }
        ]
      }
    ) {
      aggregate {
        count
        __typename
      }
      __typename
    }

    wma_metadata_aggregate_commercial_agent_filter_count: wma_metadata_aggregate(
    where: {
      _and: [
        $where,
        $commercialAgentFilter,
        { is_failure: { _eq: false } ${AddTestRunFilter()} }
        { acceptance_date: { _lt: $beforeAcceptance } }
        { input: { is_esg: { _neq: true } } }
      ]
    }
  ) {
    aggregate {
      count
      __typename
    }
    __typename
  }
    wma_metadata(
      offset: $offset
      limit: 30
      where: {
        _and: [
          $where
          {
            _or: [
              $commercialAgentFilter,
              $customerNumberFilter,
            ]
          },
          { is_failure: { _eq: false } ${AddTestRunFilter()} }
          { acceptance_date: { _lt: $beforeAcceptance } }
          { input: { is_esg: { _neq: true } } }
        ]
      }
      order_by: { acceptance_date: desc }
    ) {
      id: uuid
      acceptance_date
      ...WMATable
    }
  }
`;

export default CampaignFilter;
