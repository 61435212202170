import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useStorage } from '../utils/use-storage';
import SELECT_PROPS from './filter-items/select-props';

type SelectValue = { value?: false; label: string };
export type TableCampaignFilterValues = {
  modernizedRoof?: false;
  modernizedWindows?: false;
  modernizedFloors?: false;
  modernizedBathroom?: false;
  modernizedThermalInsulation?: false;
  modernizedHeating?: false;
  modernizedInteriorWalls?: false;
  modernizedElectricalInstallation?: false;
};

type TableCampaignFilterProps = {
  initialValues: TableCampaignFilterValues;
  onValuesChange: (values: TableCampaignFilterValues) => void;
  filterEnabled: boolean;
};

const TableCampaignFilter: FC<TableCampaignFilterProps> = ({
  initialValues,
  onValuesChange,
  filterEnabled,
}) => {
  const [modernizedRoof, setModernizedRoof] = useState<false>();
  const [modernizedWindows, setModernizedWindows] = useState<false>();
  const [modernizedFloors, setModernizedFloors] = useState<false>();
  const [modernizedBathroom, setModernizedBathroom] = useState<false>();
  const [modernizedThermalInsulation, setModernizedThermalInsulation] =
    useState<false>();
  const [modernizedHeating, setModernizedHeating] = useState<false>();
  const [modernizedInteriorWalls, setModernizedInteriorWalls] =
    useState<false>();
  const [
    modernizedElectricalInstallation,
    setModernizedElectricalInstallation,
  ] = useState<false>();

  useEffect(() => {
    setModernizedRoof(initialValues.modernizedRoof);
  }, [initialValues.modernizedRoof]);
  useEffect(() => {
    setModernizedWindows(initialValues.modernizedWindows);
  }, [initialValues.modernizedWindows]);
  useEffect(() => {
    setModernizedFloors(initialValues.modernizedFloors);
  }, [initialValues.modernizedFloors]);
  useEffect(() => {
    setModernizedBathroom(initialValues.modernizedBathroom);
  }, [initialValues.modernizedBathroom]);
  useEffect(() => {
    setModernizedThermalInsulation(initialValues.modernizedThermalInsulation);
  }, [initialValues.modernizedThermalInsulation]);
  useEffect(() => {
    setModernizedHeating(initialValues.modernizedHeating);
  }, [initialValues.modernizedHeating]);
  useEffect(() => {
    setModernizedInteriorWalls(initialValues.modernizedInteriorWalls);
  }, [initialValues.modernizedInteriorWalls]);
  useEffect(() => {
    setModernizedElectricalInstallation(
      initialValues.modernizedElectricalInstallation
    );
  }, [initialValues.modernizedElectricalInstallation]);

  useEffect(() => {
    if (filterEnabled) handleChange();
  }, [filterEnabled]);

  const handleChange = () =>
    onValuesChange({
      modernizedRoof,
      modernizedWindows,
      modernizedFloors,
      modernizedBathroom,
      modernizedThermalInsulation,
      modernizedHeating,
      modernizedInteriorWalls,
      modernizedElectricalInstallation,
    });

  return (
    <div className="filter-table">
      {/* <Row>
        <Col>
          <p>
            Wählen Sie hier relevante Modernisierungsstände aus. Zum Beispiel
            alle Abrufe bei denen Dach oder Dämmung seit mehr als 10 Jahren
            nicht modernisiert wurden.
          </p>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <div className="mb-2">
            <label>Dach</label>
            <Select<SelectValue>
              {...SELECT_PROPS<SelectValue>()}
              isClearable={modernizedRoof !== undefined}
              options={SELECT_OPTIONS}
              value={SELECT_OPTIONS.find(
                ({ value }) => value === modernizedRoof
              )}
              onChange={(value) => setModernizedRoof(value?.value)}
            />
          </div>
          <div className="mb-2">
            <label>Fenster, Türen, Außenwände</label>
            <Select<SelectValue>
              {...SELECT_PROPS<SelectValue>()}
              isClearable={modernizedWindows !== undefined}
              options={SELECT_OPTIONS}
              value={SELECT_OPTIONS.find(
                ({ value }) => value === modernizedWindows
              )}
              onChange={(value) => setModernizedWindows(value?.value)}
            />
          </div>
          <div className="mb-2">
            <label>Fußböden</label>
            <Select<SelectValue>
              {...SELECT_PROPS<SelectValue>()}
              isClearable={modernizedFloors !== undefined}
              options={SELECT_OPTIONS}
              value={SELECT_OPTIONS.find(
                ({ value }) => value === modernizedFloors
              )}
              onChange={(value) => setModernizedFloors(value?.value)}
            />
          </div>
        </Col>
        <Col>
          {/* <div className="mb-2">
            <label>Modernisierung: Dämmung</label>
            <Select<SelectValue>
              {...SELECT_PROPS<SelectValue>()}
              isClearable={modernizedThermalInsulation !== undefined}
              options={SELECT_OPTIONS}
              value={SELECT_OPTIONS.find(
                ({ value }) => value === modernizedThermalInsulation
              )}
              onChange={(value) => setModernizedThermalInsulation(value?.value)}
            />
          </div> */}
          {/* <div className="mb-2">
            <label>Modernisierung: Heizung</label>
            <Select<SelectValue>
              {...SELECT_PROPS<SelectValue>()}
              isClearable={modernizedHeating !== undefined}
              options={SELECT_OPTIONS}
              value={SELECT_OPTIONS.find(
                ({ value }) => value === modernizedHeating
              )}
              onChange={(value) => setModernizedHeating(value?.value)}
            />
          </div> */}
          {/* <div className="mb-2">
            <label>Modernisierung: Innenwände & Türen</label>
            <Select<SelectValue>
              {...SELECT_PROPS<SelectValue>()}
              isClearable={modernizedInteriorWalls !== undefined}
              options={SELECT_OPTIONS}
              value={SELECT_OPTIONS.find(
                ({ value }) => value === modernizedInteriorWalls
              )}
              onChange={(value) => setModernizedInteriorWalls(value?.value)}
            />
          </div> */}
          <div className="mb-2">
            <label>Bäder</label>
            <Select<SelectValue>
              {...SELECT_PROPS<SelectValue>()}
              isClearable={modernizedBathroom !== undefined}
              options={SELECT_OPTIONS}
              value={SELECT_OPTIONS.find(
                ({ value }) => value === modernizedBathroom
              )}
              onChange={(value) => setModernizedBathroom(value?.value)}
            />
          </div>

          <div className="mb-2">
            <label>Wände und Elektroinstallation</label>
            <Select<SelectValue>
              {...SELECT_PROPS<SelectValue>()}
              isClearable={modernizedElectricalInstallation !== undefined}
              options={SELECT_OPTIONS}
              value={SELECT_OPTIONS.find(
                ({ value }) => value === modernizedElectricalInstallation
              )}
              onChange={(value) =>
                setModernizedElectricalInstallation(value?.value)
              }
            />
          </div>
        </Col>
      </Row>
      {/* <Row className="mt-3">
        <Col lg={{ span: 3, offset: 9 }}>
          <Button className="w-100" onClick={handleChange}>
            Filter aktualisieren
          </Button>
        </Col>
      </Row> */}
    </div>
  );
};

const SELECT_OPTIONS: SelectValue[] = [
  { value: undefined, label: 'Egal / Alle' },
  { value: false, label: 'Modernisierungspotenzial' },
];

export default TableCampaignFilter;

export const useTableCampaignFilter = (): {
  filter: TableCampaignFilterValues;
  updateFilter: (filter: TableCampaignFilterValues) => void;
} => {
  const { getStoredValues, setStoredValues } =
    useStorage<TableCampaignFilterValues>('FILTER');

  const [stateTrigger, setStateTrigger] = useState(0);

  const filter = getStoredValues();
  const updateFilter = (filter: TableCampaignFilterValues) => {
    setStoredValues(filter);
    setStateTrigger(stateTrigger + 1);
  };

  return { filter, updateFilter };
};
