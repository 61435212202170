import React, { FC } from 'react';
import { formatPercent } from '../../utils/format';
import Box from '../Box';
import Loading from '../Loading';
// eslint-disable-next-line import/no-unresolved
import { LateDownloadCardFragment as KeinAbrufCardFragment } from './__generated__/LateDownloadCard';

type KeinAbrufCardProps = {
  fragments: (KeinAbrufCardFragment | undefined)[];
  size?: number;
  loading?: boolean;
};

const KeinAbrufCard: FC<KeinAbrufCardProps> = ({
  fragments,
  size = 12,
  loading,
}) => {
  const totalCount = fragments.length;
  const keinAbrufCount = fragments.filter((fragment) => {
    const acceptanceDate = fragment?.acceptance_date;
    const downloadDate = fragment?.output?.first_download_at_result1;

    if (!downloadDate && acceptanceDate) {
      return true;
    }
    return false;
  }).length;

  const percent = formatPercent(
    totalCount !== 0 ? (keinAbrufCount / totalCount) * 100 : 0
  );

  return (
    <Box size={size} title="Kein Aufruf der Langfassung">
      {loading && <Loading />}
      {!loading && (
        <>
          <p className="fs-2">
            {keinAbrufCount}
            &nbsp;
            <span className="text-secondary">({percent})</span>
          </p>
          <p>Wohnungsmarktanalysen, die nie abgerufen wurden.</p>
        </>
      )}
    </Box>
  );
};

export default KeinAbrufCard;
