import { FC } from 'react';
import { compareDates, getStatus, STATUS_LABELS } from '../../utils/status';
import { RunData } from './types';
import { TestID } from '../../utils/constants/DataTestId';

type StatusBadgeProps = {
  runData: RunData;
};

const StatusBadge: FC<StatusBadgeProps> = ({
  runData: {
    user,
    sale,
    processing: { crmExportAt, telephoneDisplayedAt, noteAddedAt },
  },
}) => {
  if (
    crmExportAt === undefined ||
    telephoneDisplayedAt === undefined ||
    noteAddedAt === undefined
  )
    return <></>;

  return (
    <span data-testid={TestID.WmaStatus}>
      {STATUS_LABELS.get(
        getStatus({
          hasNoInterest: !user,
          isSold: sale.isSold,
          exportStatusIndex: compareDates([
            crmExportAt,
            telephoneDisplayedAt,
            noteAddedAt,
          ]),
        })
      )}
    </span>
  );
};

export default StatusBadge;
