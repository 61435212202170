import objectPath from 'object-path';
import { Meta, Property, RunData } from '../components/WMATable/types';
import getEnv from './get-env';

export type WMALinkType = Partial<
  Pick<RunData, 'wmaId' | 'customer'> & {
    meta: Pick<Meta, 'id' | 'isLegacy' | 'legacyAccessKeys'>;
    property: Pick<Property, 'contractId'>;
  }
>;

export type DataType = {
  id: string;
  is_legacy?: boolean;
  input?: {
    wma_id?: string;
    customer_number?: number | null;
    contract_id?: number | null;
  };
  output?: { result_access_keys?: jsonb | null } | null;
};

export const toWMALinkType = (data: DataType): WMALinkType => {
  return {
    wmaId: data?.input?.wma_id,
    meta: {
      id: data?.id || '',
      isLegacy: data.is_legacy || false,
      legacyAccessKeys: (() => {
        const accessKey1 = objectPath.get<string | undefined>(
          (data.output?.result_access_keys as Record<string, string> | null) ||
            {},
          'access_key1',
          undefined
        );
        const accessKey2 = objectPath.get<string | undefined>(
          (data.output?.result_access_keys as Record<string, string> | null) ||
            {},
          'access_key2',
          undefined
        );

        if (accessKey1 && accessKey2)
          return {
            longDownload: accessKey1,
            shortDownload: accessKey2,
          };
        return undefined;
      })(),
    },
    customer: {
      id: data?.input?.customer_number || undefined,
    },
    property: {
      contractId: data?.input?.contract_id || undefined,
    },
  };
};

export const getWMALinkLong = (
  data: DataType | WMALinkType,
  index?: number
): string | undefined => {
  const runData =
    'meta' in data && !!data.meta && 'isLegacy' in data?.meta
      ? data
      : toWMALinkType(data as DataType);
  if (runData?.meta?.isLegacy) {
    if (runData.meta.legacyAccessKeys)
      return `${getEnv('REACT_APP_FORMS_URL')}/analysen/download/${
        runData.meta.legacyAccessKeys.longDownload
      }.pdf`;
  } else {
    if (
      runData.wmaId &&
      runData?.customer?.id &&
      runData?.property?.contractId
    ) {
      if (index === 0) {
        return `${getEnv('REACT_APP_WMA_URL')}/#/${
          runData.property.contractId
        }/${runData.wmaId}/${runData.customer.id}/richtwert`;
      } else if (index !== 0)
        return `${getEnv('REACT_APP_WMA_LONG_URL')}/wma/download/${
          runData.property.contractId
        }/${runData.wmaId}/${runData.customer.id}/?metauuid=${
          runData.meta?.id
        }`;
    }
  }
  return undefined;
};

export const getWMALinkShort = (
  data: DataType | WMALinkType
): string | undefined => {
  const runData =
    'meta' in data && !!data.meta && 'isLegacy' in data?.meta
      ? data
      : toWMALinkType(data as DataType);
  if (runData?.meta?.isLegacy) {
    if (runData.meta.legacyAccessKeys)
      return `${getEnv('REACT_APP_FORMS_URL')}/analysen/download/${
        runData.meta.legacyAccessKeys.shortDownload
      }.pdf`;
  } else {
    if (runData.wmaId && runData?.property?.contractId)
      return `${getEnv('REACT_APP_WMA_URL')}/#/${runData.property.contractId}/${
        runData.wmaId
      }/richtwert`;
  }
  return undefined;
};
