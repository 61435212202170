import saveAs from 'file-saver';
import ExcelJS, { CellHyperlinkValue } from 'exceljs';
import { TableFilterLabels } from '../components/TableFilter';
// eslint-disable-next-line import/no-unresolved
import { DownloadExcelQuery } from './hooks/__generated__/use-download-excel';
import { GetCommercialAgent } from './hooks';
import today from './today';
import { getWMALinkLong } from './my-wma-link';
import moment from 'moment';
import { getContactDeadline } from './contact';
import { formatDateTime } from './format';
import { OBJECT_TYPE_LABELS, ObjectType, getObjectType } from './object-type';
import { STATUS_LABELS, compareDates, getStatus } from './status';

export const createWorkBook = async (
  filterLabels: TableFilterLabels,
  data: DownloadExcelQuery['wma_metadata_customer_number'],
  hasPersonExport: boolean,
  isCommercialAgent: boolean,
  getCommercialAgent: GetCommercialAgent
): Promise<ExcelJS.Workbook> => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Abrufe', {
    properties: { defaultColWidth: 20 },
  });

  // general info
  sheet.addRow([`WMA-Auswertung [${today().format('DD.MM.YYYY')}]`]);
  sheet.addRow([]);
  sheet.addRow(['Kunde', null, filterLabels.customerId]);
  sheet.addRow([]);
  sheet.addRows([
    ['Zeitraum von', null, filterLabels.startDate],
    ['Zeitraum bis', null, filterLabels.endDate],
  ]);
  sheet.addRow([]);
  sheet.addRow(['Auswertung vom', null, today().format('DD.MM.YYYY')]);
  sheet.addRow([]);
  sheet.getCell(1, 1).style = { font: { bold: true } };
  sheet.addRow([]);

  const motivColumnStart = 5;

  // filter info
  sheet.getCell(2, motivColumnStart).value = 'Einschränkungen';
  sheet.getCell(2, motivColumnStart).style = { font: { bold: true } };

  sheet.getCell(4, motivColumnStart).value = 'Interne / Externe Abrufe';
  sheet.getCell(5, motivColumnStart).value = 'Loop5';
  sheet.getCell(6, motivColumnStart).value = 'Status';
  sheet.getCell(7, motivColumnStart).value = 'Objekttyp';
  sheet.getCell(8, motivColumnStart).value = 'Bestand / Neubau';
  sheet.getCell(9, motivColumnStart).value = 'Motive';

  sheet.getCell(4, motivColumnStart + 1).value = filterLabels.isInternal;
  sheet.getCell(5, motivColumnStart + 1).value = filterLabels.isLoop5;
  sheet.getCell(6, motivColumnStart + 1).value = filterLabels.statusFilter;
  sheet.getCell(7, motivColumnStart + 1).value = filterLabels.objectType;
  sheet.getCell(8, motivColumnStart + 1).value = filterLabels.isNewBuilding;
  sheet.getCell(9, motivColumnStart + 1).value = filterLabels.goals;

  for (let i = 2; i <= 9; i++) {
    for (let j = motivColumnStart; j <= motivColumnStart + 5; j++) {
      sheet.getCell(i, j).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFEB9C' },
      };
    }
  }

  const headers = [
    'Analyse-Kennung',
    'WMA',
    'Annahmedatum',
    'Abrufer',
    'Motiv',
    'Straße',
    'Hausnummer',
    'PLZ',
    'Ort',
    'Objekttyp',
    'Richtwert',
    'Notiz',
    'Kontaktfrist',
    ...(isCommercialAgent ? ['Handelsvertreter'] : []),
    'Bearbeiter',
    'Status',
    'Zeit bis Aufruf Langfassung [h]',
    'Quelle',
    'Berater',
    ...(hasPersonExport
      ? ['Anrede', 'Vorname', 'Nachname', 'Telefon', 'E-Mail']
      : []),
  ].filter(Boolean);

  const commercialAgentIndex = headers.findIndex((col) => col === 'Bearbeiter');

  sheet.addRow(headers);

  let finalData: ((typeof data)[0] & { commercialAgentEmail?: string })[] =
    data;

  if (isCommercialAgent) {
    const promises = data.map((run) =>
      getCommercialAgent(
        run.output?.commercial_agent_id,
        run.input.object_data__postal_code
      )
    );
    const emails = await Promise.all(promises);
    const withEmails = data.map((run, index) => ({
      ...run,
      commercialAgentEmail: emails[index]?.email,
    }));
    finalData = withEmails;
  }

  finalData.forEach((dataRow, i) => {
    const row = [
      dataRow.input.wma_id?.split('-')[0] || '', // Analyse-Kennung
      getWMALinkLong(dataRow)
        ? ({
            text: 'Link',
            hyperlink: getWMALinkLong(dataRow),
          } as CellHyperlinkValue)
        : 'Nicht gefunden', // WMA
      formatDateTime(dataRow.acceptance_date), // Annahmedatum
      dataRow.is_internal_request ? 'Eigene' : 'Endnutzer', // Abrufer
      dataRow.input.conversion_goal?.label_de, // Motiv
      dataRow.input.object_data__street_name, // Straße
      dataRow.input.object_data__house_number, // Hausnummer
      dataRow.input.object_data__postal_code, // PLZ
      dataRow.input.object_data__city, // Ort
      OBJECT_TYPE_LABELS.get(
        getObjectType({
          objectType: dataRow.input.object_data__category || undefined,
          marketingType: dataRow.input.marketing_type,
        }) as ObjectType
      ), // Objekttyp
      dataRow.output?.reference_value, // Richtwert
      dataRow.cockpit_note, // Notiz
      (() => {
        const contactDeadline = getContactDeadline({
          userExists: !!dataRow.input.user,
          permissionGranted: !!dataRow.input.user_consent,
          acceptedAt:
            dataRow.input.metadata1?.[0]?.acceptance_date ||
            dataRow.acceptance_date,
          isLoop5: !!dataRow.is_loop5,
          isSparkasse:
            dataRow.input.match_code?.startsWith('SK') ||
            dataRow.input.metadata?.[0]?.input?.match_code?.startsWith('SK') ||
            false,
        });
        if (contactDeadline === 'USER_REMOVED') return 'Daten gelöscht';
        if (contactDeadline === 'PERMISSION_GRANTED')
          return 'Werbeeinwilligung gegeben';
        return moment().add(contactDeadline, 'days').format('DD.MM.YYYY');
      })(), // Kontaktfrist
      dataRow.cockpit_last_user, // Bearbeiter
      STATUS_LABELS.get(
        getStatus({
          hasNoInterest: !dataRow.input.user,
          isSold: !!dataRow.input.sale?.is_sold,
          exportStatusIndex: compareDates([
            dataRow.cockpit_sent_at,
            dataRow.cockpit_telephone_displayed_at,
            dataRow.cockpit_note_added_at,
          ]),
        })
      ), // Status
      (() => {
        const acceptanceDate = dataRow.acceptance_date;
        const downloadDate = dataRow.output?.first_download_at_result1;

        if (acceptanceDate && downloadDate) {
          const diff = moment(downloadDate).diff(
            moment(acceptanceDate),
            'hours'
          );
          return diff;
        }
        return 'Kein Aufruf';
      })(), // Zeit bis Aufruf Langfassung [h]
      dataRow.input.referer, // Quelle
      dataRow.advisor_name, // Berater
      ...(hasPersonExport
        ? [
            dataRow.input.user?.title, // Anrede
            dataRow.input.user?.first_name, // Vorname
            dataRow.input.user?.last_name, // Nachname
            dataRow.input.user?.phone_number, // Telefon
            dataRow.input.user?.email, // E-Mail
          ]
        : []),
    ];

    if (isCommercialAgent) {
      row.splice(commercialAgentIndex - 1, 0, dataRow.commercialAgentEmail);
    }
    sheet.addRow(row);
    sheet.getCell(11 + i, 2).style = { font: { bold: true } };
  });

  sheet.getColumn(10).numFmt = '#,##0.00 "€"';
  sheet.getColumn(10).width = 20;

  sheet.autoFilter = {
    from: { row: 11, column: 1 },
    to: { row: 11, column: hasPersonExport ? 22 : 17 },
  };

  sheet.views = [{ state: 'frozen', ySplit: 10 }];
  return workbook;
};

export const downloadWB = async (workbook: ExcelJS.Workbook): Promise<void> => {
  const buf = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buf], { type: 'application/octet-stream' }), 'abrufe.xlsx');
};
