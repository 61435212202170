import { FC, ReactNode, useState } from 'react';
import { Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import { FaEuroSign } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { alertSuccess, alertWarning } from '../../services/alert';
import {
  useUpsertEstimate,
  useDeleteEstimatePrice,
} from '../../utils/cockpit-mutation-hooks/upsert-estimate';
import { formatCurrency } from '../../utils/format';

type EstimateModelProps = {
  runId: uuid;
  objectValue?: number;
  estimateCallback?: () => void;
  children?: ReactNode;
};

const EstimateModel: FC<EstimateModelProps> = ({
  runId,
  objectValue,
  estimateCallback,
  children,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [estimatePrice, setEstimatePrice] = useState<number>();

  const upsertEstimate = useUpsertEstimate(runId);
  const deleteEstimate = useDeleteEstimatePrice(runId);

  const handleEstimate = () => {
    if (estimatePrice === undefined || objectValue === undefined) return;

    if (estimatePrice < 0) {
      alertWarning('The estimate value cannot be negative');
      return;
    } else if ((estimatePrice / objectValue) * 100 - 100 > 100) {
      alertWarning(
        `Die Abweichung beträgt mehr als 100%. Bitte wenden Sie sich direkt an info@iib-institut.de`
      );
      return;
    }
    upsertEstimate(estimatePrice)
      .catch(() => null)
      .finally(() => estimateCallback?.());
    setIsModalVisible(false);
    alertSuccess(`Objekt geschätzt auf ${formatCurrency(estimatePrice)}.`);
  };

  const handleDeleteEstimate = () => {
    deleteEstimate()
      .catch(() => null)
      .finally(() => estimateCallback?.());
    setIsModalVisible(false);
    alertSuccess(`Aktueller Wert gelöscht`);
  };

  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => setIsModalVisible(true)}
      >
        {children}
      </span>
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Body>
          <Row className="mb-2 mt-4">
            <Col lg={2} className="text-center">
              <FaEuroSign size={32} />
            </Col>
            <Col lg={10}>
              <h3>Preisfeedback erfassen</h3>
              <InputGroup>
                <NumberFormat
                  className="form-control"
                  placeholder="Ihre Marktpreiseinschätzung"
                  thousandSeparator="."
                  decimalSeparator=","
                  value={estimatePrice}
                  onValueChange={({ floatValue }) =>
                    setEstimatePrice(floatValue)
                  }
                />
                <InputGroup.Text>€</InputGroup.Text>
              </InputGroup>
              <p className="my-4">
                Ihr Feedback wird genutzt, um sicherzustellen, dass die
                Ergebnisse der Wohnmarktanalyse mit den aktuellen
                Marktentwicklungen übereinstimmen und zuverlässige Ergebnisse
                für Sie und Ihre Kunden liefern.
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
          <Button variant="primary" onClick={handleDeleteEstimate}>
            Preiseinschätzung löschen
          </Button>
          <Button variant="primary" onClick={() => setIsModalVisible(false)}>
            Abbrechen
          </Button>
          <Button
            variant="primary"
            onClick={handleEstimate}
            disabled={!estimatePrice}
          >
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EstimateModel;
