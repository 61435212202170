import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Col, Row } from 'react-bootstrap';
import { alertSuccess } from '../services/alert';
import { useStorage } from '../utils/use-storage';
import { ObjectType } from '../utils/object-type';
import CustomerSelect from './filter-items/CustomerSelect';
import GoalMultiSelect from './filter-items/GoalMultiSelect';
import HasUserConsentCheck from './filter-items/HasUserConsentCheck';
import IsInternalSelect from './filter-items/IsInternalSelect';
import IsLoop5Select from './filter-items/IsLoop5Select';
import IsNewBuildingSelect from './filter-items/IsNewBuildingSelect';
import ObjectTypeSelect from './filter-items/ObjectTypeSelect';
import TimeRangeSelect from './filter-items/TimeRangeSelect';
import { TestID } from '../utils/constants/DataTestId';
import type { StatusFilterValues } from '../utils/status-filter';
import StatusFilterSelect from './filter-items/StatusFilterSelect';

export type TableFilterValues = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  customerId?: number;
  isInternal?: boolean;
  isLoop5: ThreeState;
  statusFilter?: StatusFilterValues;
  objectType?: ObjectType;
  isNewBuilding?: boolean;
  goals?: number[];
  hasUserConsent?: true;
  userId: string | undefined;
};

export type TableFilterLabels = {
  startDate?: string;
  endDate?: string;
  customerId?: string;
  isInternal?: string;
  isLoop5?: string;
  statusFilter?: string;
  objectType?: string;
  isNewBuilding?: string;
  goals?: string;
};

type TableFilterProps = {
  initialValues: TableFilterValues;
  onValuesChange: (values: TableFilterValues) => void;
  onLabelsChange: (labels: TableFilterLabels) => void;
  filterEnabled?: boolean;
  setFilterEnabled?: (filterEnabled: boolean) => void;
};

const TableFilter: FC<TableFilterProps> = ({
  initialValues,
  onValuesChange,
  onLabelsChange,
  filterEnabled,
  setFilterEnabled,
}) => {
  const { user } = useAuth0();
  // values
  const [startDate, setStartDate] = useState<moment.Moment>(
    initialValues.startDate
  );
  const [endDate, setEndDate] = useState<moment.Moment>(initialValues.endDate);
  const [customerId, setCustomerId] = useState<number>();
  const [isInternal, setIsInternal] = useState<boolean>();
  const [isLoop5, setIsLoop5] = useState<ThreeState>('UNSPECIFIED');
  const [statusFilter, setStatusFilter] = useState<StatusFilterValues>();
  const [objectType, setObjectType] = useState<ObjectType>();
  const [isNewBuilding, setIsNewBuilding] = useState<boolean>();
  const [goals, setGoals] = useState<number[]>();
  const [hasUserConsent, setHasUserConsent] = useState<true>();

  // labels
  const [startDateLabel, setStartDateLabel] = useState<string>();
  const [endDateLabel, setEndDateLabel] = useState<string>();
  const [customerIdLabel, setCustomerIdLabel] = useState<string>();
  const [isInternalLabel, setIsInternalLabel] = useState<string>();
  const [isLoop5Label, setIsLoop5Label] = useState<string>();
  const [statusFilterLabel, setStatusFilterLabel] = useState<string>();
  const [objectTypeLabel, setObjectTypeLabel] = useState<string>();
  const [isNewBuildingLabel, setIsNewBuildingLabel] = useState<string>();
  const [goalsLabel, setGoalsLabel] = useState<string>();

  useEffect(() => {
    setStartDate(initialValues.startDate);
  }, [initialValues.startDate]);
  useEffect(() => {
    setEndDate(initialValues.endDate);
  }, [initialValues.endDate]);
  useEffect(() => {
    setCustomerId(initialValues.customerId);
  }, [initialValues.customerId]);
  useEffect(() => {
    setIsInternal(initialValues.isInternal);
  }, [initialValues.isInternal]);
  useEffect(() => {
    setIsLoop5(initialValues.isLoop5);
  }, [initialValues.isLoop5]);
  useEffect(() => {
    setStatusFilter(initialValues.statusFilter);
  }, [initialValues.statusFilter]);
  useEffect(() => {
    setObjectType(initialValues.objectType);
  }, [initialValues.objectType]);
  useEffect(() => {
    setIsNewBuilding(initialValues.isNewBuilding);
  }, [initialValues.isNewBuilding]);
  useEffect(() => {
    setGoals(initialValues.goals);
  }, [initialValues.goals]);
  useEffect(() => {
    setHasUserConsent(initialValues.hasUserConsent);
  }, [initialValues.hasUserConsent]);

  useEffect(() => {
    if (
      filterEnabled !== undefined &&
      filterEnabled !== false &&
      setFilterEnabled !== undefined
    ) {
      handleChange();
      setFilterEnabled(false);
    }
  }, [filterEnabled]);

  const changeFilterVaues = () => {
    onValuesChange({
      startDate,
      endDate,
      customerId,
      isInternal,
      isLoop5,
      statusFilter,
      objectType,
      isNewBuilding,
      goals,
      hasUserConsent,
      userId: user?.name,
    });
    onLabelsChange({
      startDate: startDateLabel,
      endDate: endDateLabel,
      customerId: customerIdLabel,
      isInternal: isInternalLabel,
      isLoop5: isLoop5Label,
      statusFilter: statusFilterLabel,
      objectType: objectTypeLabel,
      isNewBuilding: isNewBuildingLabel,
      goals: goalsLabel,
    });
  };

  const handleChange = () => {
    changeFilterVaues();
    alertSuccess('Filter aktualisiert');
  };

  return (
    <div className="filter-table">
      <Row>
        <Col>
          <CustomerSelect
            customerId={customerId}
            setCustomerId={setCustomerId}
            setCustomerIdLabel={setCustomerIdLabel}
          />
          <TimeRangeSelect
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setStartDateLabel={setStartDateLabel}
            setEndDateLabel={setEndDateLabel}
          />
          <IsInternalSelect
            isInternal={isInternal}
            setIsInternal={setIsInternal}
            setIsInternalLabel={setIsInternalLabel}
          />
          <IsLoop5Select
            isLoop5={isLoop5}
            setIsLoop5={setIsLoop5}
            setIsLoop5Label={setIsLoop5Label}
          />
        </Col>
        <Col>
          <StatusFilterSelect
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            setStatusFilterLabel={setStatusFilterLabel}
          />
          <ObjectTypeSelect
            objectType={objectType}
            setObjectType={setObjectType}
            setObjectTypeLabel={setObjectTypeLabel}
          />
          <IsNewBuildingSelect
            isNewBuilding={isNewBuilding}
            setIsNewBuilding={setIsNewBuilding}
            setIsNewBuildingLabel={setIsNewBuildingLabel}
          />
          <GoalMultiSelect
            goals={goals}
            setGoals={setGoals}
            setGoalsLabel={setGoalsLabel}
          />
          <HasUserConsentCheck
            hasUserConsent={hasUserConsent}
            setHasUserConsent={setHasUserConsent}
          />
        </Col>
      </Row>
      {filterEnabled === undefined && (
        <Row className="mt-3">
          <Col lg={{ span: 3, offset: 9 }}>
            <Button
              data-testid={TestID.BtnUpdateTable}
              className="w-100"
              onClick={handleChange}
            >
              Tabelle aktualisieren
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TableFilter;

type TableFilterStoredValues = {
  startDate: number;
  endDate: number;
  customerId: number;
  isInternal: boolean;
  isLoop5: ThreeState;
  statusFilter: StatusFilterValues;
  objectType: ObjectType;
  isNewBuilding: boolean;
  goals: number[];
  hasUserConsent: true;
  userId: string | undefined;
};

export const useTableFilter = (): {
  filter: TableFilterValues;
  updateFilter: (filter: TableFilterValues) => void;
  filterLabels: TableFilterLabels;
  updateFilterLabels: (filterLabels: TableFilterLabels) => void;
  getStoredValues: () => Partial<TableFilterStoredValues>;
} => {
  const { user } = useAuth0();
  const { getStoredValues, setStoredValues } =
    useStorage<TableFilterStoredValues>('FILTER');

  const [stateTrigger, setStateTrigger] = useState(0);

  const storedValues = getStoredValues();
  const filter: TableFilterValues = {
    ...storedValues,
    startDate: storedValues.startDate
      ? moment(storedValues.startDate)
      : moment().startOf('month'),
    endDate: storedValues.endDate
      ? moment(storedValues.endDate)
      : moment().startOf('day'),
    isLoop5: storedValues.isLoop5 === undefined ? false : storedValues.isLoop5,
    userId: user?.name,
  };
  const updateFilter = (filter: TableFilterValues) => {
    setStoredValues({
      ...filter,
      startDate: filter.startDate.valueOf(),
      endDate: filter.endDate.valueOf(),
      userId: user?.name,
    });
    setStateTrigger(stateTrigger + 1);
  };

  const { getStoredValues: getStoredLabels, setStoredValues: setStoredLabels } =
    useStorage<TableFilterLabels>('FILTER_LABELS');

  const filterLabels = getStoredLabels();
  const updateFilterLabels = (labels: TableFilterLabels) =>
    setStoredLabels(labels);

  return {
    filter,
    updateFilter,
    filterLabels,
    updateFilterLabels,
    getStoredValues,
  };
};
