/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import BenchmarkCard, {
  BENCHMARK_CARD_FRAGMENT,
} from '../components/cards/BenchmarkCard';
import SalesFunnelCard, {
  SALES_FUNNEL_CARD_FRAGMENT,
} from '../components/cards/SalesFunnelCard';
import GoalCard, { GOAL_CARD_FRAGMENT } from '../components/cards/GoalCard';
import LocationCard, {
  LOCATION_CARD_FRAGMENT,
} from '../components/cards/LocationCard';
import ObjectTypeCard, {
  OBJECT_TYPE_CARD_FRAGMENT,
} from '../components/cards/ObjectTypeCard';
import ObjectTypeTimeCard, {
  OBJECT_TYPE_TIME_CARD_FRAGMENT,
} from '../components/cards/ObjectTypeTimeCard';
import RequestTypeTimeCard, {
  REQUEST_TYPE_TIME_CARD_FRAGMENT,
} from '../components/cards/RequestTypeTimeCard';
import Layout from '../components/Layout';
import ReportingNav from '../components/ReportingNav';
import StatsFilter, { useStatsFilter } from '../components/StatsFilter';
import '@iib/pandora-box/dist/mtk.css';

import {
  AddTestRunFilter,
  getMetadataStatsFilterExpr,
} from '../utils/where-exprs';
import {
  ReportingOverviewQuery,
  ReportingOverviewQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/ReportingOverview';
import {
  getFilteredLocationFragments,
  MAX_LOCATION_MARKERS_ALLOWED,
} from '../utils';

const ReportingOverview: FC = () => {
  const { filter, updateFilter } = useStatsFilter();

  const { data, loading } = useQuery<
    ReportingOverviewQuery,
    ReportingOverviewQueryVariables
  >(REPORTING_OVERVIEW_QUERY, {
    variables: { where: getMetadataStatsFilterExpr(filter) },
  });

  const objectTypeFragments =
    data?.wma_metadata?.map((wma) => wma.input || undefined) || [];
  const goalFragments =
    data?.wma_metadata?.map((wma) => wma.input?.conversion_goal || undefined) ||
    [];
  const funnelFragments = data?.wma_metadata || [];
  const benchmarkFragments = data?.wma_metadata || [];
  const typeTimeFragments = data?.wma_metadata || [];
  const locationFragments = getFilteredLocationFragments(
    data,
    MAX_LOCATION_MARKERS_ALLOWED
  );
  return (
    <Layout title="Reporting // Übersicht">
      <div id="overviewSite">
        <ReportingNav />
        <Row className="pt-3 pb-3">
          <Col lg={12}>
            <Card body border="light">
              <StatsFilter
                initialValues={filter}
                onValuesChange={(value) => updateFilter(value)}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <ObjectTypeCard
            fragments={objectTypeFragments}
            size={4}
            loading={loading}
          />
          <GoalCard fragments={goalFragments} size={4} loading={loading} />
          <SalesFunnelCard
            fragments={funnelFragments}
            size={4}
            loading={loading}
          />
          <BenchmarkCard
            fragments={benchmarkFragments}
            startDate={filter.startDate}
            endDate={filter.endDate}
            size={3}
            loading={loading}
          />
          <Col lg={5}>
            <ObjectTypeTimeCard
              fragments={typeTimeFragments}
              startDate={filter.startDate}
              endDate={filter.endDate}
              loading={loading}
            />
            <RequestTypeTimeCard
              fragments={typeTimeFragments}
              startDate={filter.startDate}
              endDate={filter.endDate}
              loading={loading}
            />
          </Col>
          <LocationCard fragments={locationFragments} size={4} />
        </Row>
      </div>
    </Layout>
  );
};

const REPORTING_OVERVIEW_QUERY = gql`
  ${OBJECT_TYPE_CARD_FRAGMENT}
  ${GOAL_CARD_FRAGMENT}
  ${SALES_FUNNEL_CARD_FRAGMENT}
  ${BENCHMARK_CARD_FRAGMENT}
  ${OBJECT_TYPE_TIME_CARD_FRAGMENT}
  ${REQUEST_TYPE_TIME_CARD_FRAGMENT}
  ${LOCATION_CARD_FRAGMENT}
  query ReportingOverview($where: wma_metadata_bool_exp!) {
    wma_metadata(
      where: {
        _and: [
          $where
          { is_failure: { _eq: false } ${AddTestRunFilter()} }
          { input: { is_esg: { _neq: true } } }
        ]
      }
    ) {
      id: uuid
      acceptance_date
      input {
        id: wma_id
        conversion_goal {
          ...GoalCard
        }
        ...ObjectTypeCard
        ...LocationCard
      }
      ...ObjectTypeTimeCard
      ...SalesFunnelCard
      ...BenchmarkCard
      ...RequestTypeTimeCard
    }
  }
`;

export default ReportingOverview;
