/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pie } from '@ant-design/charts';
import { MarkerCfg } from '@antv/g2/lib/interface';
import gql from 'graphql-tag';
import { FC, useMemo } from 'react';
import {
  APPARTMENT_COLOR,
  APPARTMENT_LABEL,
  HOUSE_COLOR,
  HOUSE_LABEL,
  isAppartment,
  isHouse,
  isMultiHouse,
  isRentalAppartment,
  MULTI_HOUSE_COLOR,
  MULTI_HOUSE_LABEL,
  ObjectTypeInput,
  RENTAL_APPARTMENT_COLOR,
  RENTAL_APPARTMENT_LABEL,
} from '../../utils/object-type';
import Box from '../Box';
import DiagramWrapper from '../DiagramWrapper';

import Loading from '../Loading';
// eslint-disable-next-line import/no-unresolved
import { ObjectTypeCardFragment } from './__generated__/ObjectTypeCard';
import { makeSquareMarker } from '../../utils';

type ObjectTypeCardProps = {
  fragments: (ObjectTypeCardFragment | undefined)[];
  size?: number;
  loading?: boolean;
};

const ObjectTypeCard: FC<ObjectTypeCardProps> = ({
  fragments,
  size = 12,
  loading,
}) => {
  const objectTypes = useMemo(
    (): ObjectTypeInput[] =>
      fragments.map((fragment) => ({
        marketingType: fragment?.marketing_type || undefined,
        objectType: fragment?.object_type || undefined,
      })),
    [fragments]
  );

  const rentalAppartmentCount = objectTypes.filter(isRentalAppartment).length;
  const appartmentCount = objectTypes.filter(isAppartment).length;
  const houseCount = objectTypes.filter(isHouse).length;
  const multiHouseCount = objectTypes.filter(isMultiHouse).length;

  const totalCount =
    rentalAppartmentCount + appartmentCount + houseCount + multiHouseCount;

  const data = [
    { label: RENTAL_APPARTMENT_LABEL, value: rentalAppartmentCount },
    { label: APPARTMENT_LABEL, value: appartmentCount },
    { label: HOUSE_LABEL, value: houseCount },
    { label: MULTI_HOUSE_LABEL, value: multiHouseCount },
  ];

  return (
    <Box
      size={size}
      title="Abrufe nach Objekttyp"
      tooltipMessage={TOOLTIP_MESSAGE}
    >
      {loading && <Loading />}
      {!loading && (
        <>
          <DiagramWrapper>
            <Pie
              style={{ height: 400, marginBottom: 30 }}
              data={data}
              angleField="value"
              colorField="label"
              radius={0.9}
              label={{ content: '' }}
              interactions={[{ type: 'element-active' }]}
              legend={{
                marker: (_name, _index, item) =>
                  makeSquareMarker(item as MarkerCfg),
                position: 'bottom',
                flipPage: false,
                itemName: {
                  style: { fontSize: 14 },
                  formatter: (text: string) => {
                    const item = data
                      .filter((d) => d.label === text)
                      .find(() => true);
                    return `${text} (${item?.value})`;
                  },
                },
              }}
              color={[
                RENTAL_APPARTMENT_COLOR,
                APPARTMENT_COLOR,
                HOUSE_COLOR,
                MULTI_HOUSE_COLOR,
              ]}
            />
          </DiagramWrapper>
          <div
            className="text-center fs-2 fw-light"
            data-cy="Wohnmarktanalysen-data"
          >
            {totalCount} Wohnmarktanalysen
          </div>
        </>
      )}
    </Box>
  );
};

export const OBJECT_TYPE_CARD_FRAGMENT = gql`
  fragment ObjectTypeCard on wma_input {
    marketing_type
    object_type: object_data__category
  }
`;

const TOOLTIP_MESSAGE = `
Dies ist die Gesamtanzahl aller abgerufenen <br />
Wohnmarktanalysen in Ihrem ausgewählten Zeitraum. <br />
Gegliedert werden die Analysen hier nach <br />
den jeweiligen Objekttypen (Mietwohnungen, <br />
Eigentumswohnungen, Häuser oder Mehrfamilienhäuser).
`;
export default ObjectTypeCard;
