import gql from 'graphql-tag';
import moment from 'moment';
import { FC } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import Box from '../Box';
import Loading from '../Loading';
import {
  BenchmarkCardFragment,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/BenchmarkCard';

type BenchmarkCardProps = {
  fragments: (BenchmarkCardFragment | undefined)[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  size?: number;
  loading?: boolean;
};

type ListItemProps = {
  category: BankCategoryIndex;
  startDate: moment.Moment;
  endDate: moment.Moment;
};

enum BankCategoryIndex {
  XS,
  S,
  M,
  L,
  XL,
}

const BankCategoryNames = {
  [BankCategoryIndex.XS]: 'ImmoBanking XS',
  [BankCategoryIndex.S]: 'ImmoBanking S',
  [BankCategoryIndex.M]: 'ImmoBanking M',
  [BankCategoryIndex.L]: 'ImmoBanking L',
  [BankCategoryIndex.XL]: 'ImmoBanking XL',
};

const benchmarkValues = {
  [BankCategoryIndex.XS]: 1,
  [BankCategoryIndex.S]: 1.66,
  [BankCategoryIndex.M]: 2.33,
  [BankCategoryIndex.L]: 4,
  [BankCategoryIndex.XL]: 8,
};

const BenchmarkCard: FC<BenchmarkCardProps> = ({
  fragments,
  startDate,
  endDate,
  size = 12,
  loading,
}) => {
  return (
    <Box size={size} title="Anzahl Abrufe" tooltipMessage={TOOLTIP_MESSAGE}>
      {loading && <Loading />}
      {!loading && (
        <>
          <Row className="d-flex align-items-center">
            <Col className="fs-2 text-center" lg={4} data-cy="fragments-length">
              {fragments.length}
            </Col>
            <Col lg={8}>
              WMAs vom {startDate.format('DD.MM.YYYY')} bis{' '}
              {endDate.format('DD.MM.YYYY')}
            </Col>
          </Row>
          <h4 className="mt-4">Benchmark / Vergleich</h4>
          Folgende Anzahl an Abrufen wird je nach Kundengröße im gewählten
          Zeitraum durchschnittlich erreicht.
          <ListGroup className="mt-4" variant="flush">
            {
              <ListItem
                startDate={startDate}
                endDate={endDate}
                category={BankCategoryIndex.XS}
              />
            }
            {
              <ListItem
                startDate={startDate}
                endDate={endDate}
                category={BankCategoryIndex.S}
              />
            }
            {
              <ListItem
                startDate={startDate}
                endDate={endDate}
                category={BankCategoryIndex.M}
              />
            }
            {
              <ListItem
                startDate={startDate}
                endDate={endDate}
                category={BankCategoryIndex.L}
              />
            }
            {
              <ListItem
                startDate={startDate}
                endDate={endDate}
                category={BankCategoryIndex.XL}
              />
            }
          </ListGroup>
        </>
      )}
    </Box>
  );
};

const ListItem: FC<ListItemProps> = ({ category, startDate, endDate }) => {
  return (
    <ListGroup.Item className="d-flex align-items-center justify-content-between">
      <span className="fs-6 text-muted">{BankCategoryNames[category]}</span>
      <span className="fs-5">
        {Math.round(
          benchmarkValues[category] * (endDate.diff(startDate) / 86400000)
        )}
      </span>
    </ListGroup.Item>
  );
};

export const BENCHMARK_CARD_FRAGMENT = gql`
  fragment BenchmarkCard on wma_metadata {
    id: uuid
  }
`;

const TOOLTIP_MESSAGE = `
Die Gesamtanzahl aller Abrufe wird hier für den gewünschten Zeitraum zusammengerechnet <br />
und mit dem Durchschnitt anderer Banken verglichen (Benchmarking). <br />
Den Zeitraum können Sie jederzeit in dem Filter am Kopf der Seite anpassen. <br />
Diese sind je nach Bilanzgröße in die iib ImmoBanking Kategorien (XS, S, M, L oder XL) eingeteilt. <br />
Welcher Kategorie Sie angehören, können Sie Ihrem Vertrag entnehmen.
`;

// This Query no longer exists in Hasura, so commenting it out to prenvent CLI error during codegen.
// const QUERY = gql`
//   query BenchmarkCard($startDate: timestamp!, $maxDate: timestamp!) {
//     erfolgscockpit_benchmark_aggregation(
//       min_date: $startDate
//       max_date: $maxDate
//     ) {
//       run_count
//       customer_count
//       bank_category
//     }
//   }
// `;

// const useBenchmarkValues = (
//   startDate: moment.Moment,
//   endDate: moment.Moment
// ) => {
//   const maxDate = moment(endDate).add(1, 'days');

//   const { data, loading } = useQuery<
//     BenchmarkCardQuery,
//     BenchmarkCardQueryVariables
//   >(QUERY, {
//     variables: {
//       startDate: momentToQueryDate(startDate),
//       maxDate: momentToQueryDate(maxDate),
//     },
//   });

//   return {
//     benchmarkValues: data?.erfolgscockpit_benchmark_aggregation,
//     loading,
//   };
// };

export default BenchmarkCard;
