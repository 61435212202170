import { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FaStickyNote } from 'react-icons/fa';
import { alertSuccess } from '../../services/alert';
import useSetNote from '../../utils/cockpit-mutation-hooks/set-note';

type NoteModalProps = {
  runId: uuid;
  note?: string;
  setNoteCallback?: () => void;
  children?: ReactNode;
};

const NoteModal: FC<NoteModalProps> = ({
  runId,
  note,
  setNoteCallback,
  children,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentNote, setCurrentNote] = useState<string>();

  const setNote = useSetNote(runId);

  useEffect(() => {
    setCurrentNote(note);
  }, [note]);

  const handleSave = () => {
    currentNote &&
      setNote(currentNote)
        .catch(() => null)
        .finally(() => setNoteCallback?.());
    setIsModalVisible(false);
    alertSuccess('Notiz wurde gespeichert');
  };

  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => setIsModalVisible(true)}
      >
        {children}
      </span>
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Body>
          <Row className="mb-2 mt-4">
            <Col lg={2} className="text-center">
              <FaStickyNote size={32} />
            </Col>
            <Col lg={10}>
              <h3>Notiz</h3>
              <textarea
                className="form-control"
                rows={10}
                value={currentNote}
                onChange={(e) => setCurrentNote(e.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
          <Button variant="primary" onClick={() => setIsModalVisible(false)}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NoteModal;
