import styled from 'styled-components';

export const MapWrapper = styled.div`
  min-width: 200px;
  width: 100%;
  height: 700px;
  max-width: inherit;
  container-type: inline-size; // fixes width overflow issue of Map
  display: flex;
  justify-content: center;
  align-items: center;
`;
