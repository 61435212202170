import { Pagination } from 'react-bootstrap';
import { FC } from 'react';

type Props = {
  loadMore: (num: number) => void;
  totalCount: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const PagePagination: FC<Props> = ({
  loadMore,
  totalCount,
  currentPage,
  setCurrentPage,
}) => {
  const itemsPerPage = 30;
  const maxPages =
    Math.floor(totalCount / itemsPerPage) > 0
      ? Math.ceil(totalCount / itemsPerPage)
      : 1;

  let displayPaginationNumbers: number[] = [];
  let showPrevDots = false;
  let showNextDots = true;
  // 1,2,3,4,5,6...
  if (currentPage < 5 && maxPages > 6) {
    displayPaginationNumbers = Array.from({ length: 6 }, (_, i) => i + 1);
    showNextDots = true;
    showPrevDots = false;
  } // 1,2,3,4,5,6
  else if (maxPages <= 6) {
    displayPaginationNumbers = Array.from(
      { length: maxPages },
      (_, i) => i + 1
    );
    showNextDots = false;
    showPrevDots = false;
  } // ...2,3,4,5,6,7...
  else if (currentPage >= 5 && currentPage + 3 <= maxPages) {
    displayPaginationNumbers = Array.from(
      { length: 6 },
      (_, i) => i + (currentPage - 3)
    );
    showNextDots = true;
    showPrevDots = true;
  } // ...26,27,28,29,30,31
  else if (currentPage + 3 >= maxPages) {
    displayPaginationNumbers = Array.from(
      { length: 6 },
      (_, i) => i + (maxPages - 5)
    );
    showNextDots = false;
    showPrevDots = true;
  }
  const renderPaginationItems = (
    <>
      {displayPaginationNumbers.length > 0 &&
        displayPaginationNumbers.map((page: number) => (
          <Pagination.Item
            key={page}
            onClick={() => {
              setCurrentPage(page);
              loadMore(page);
            }}
            active={currentPage === page}
          >
            {page}
          </Pagination.Item>
        ))}
    </>
  );

  return (
    <Pagination>
      <Pagination.First
        onClick={() => {
          setCurrentPage(1);
          loadMore(1);
        }}
      />
      <Pagination.Prev
        data-cy="PrevPage"
        onClick={() => {
          currentPage > 1 && setCurrentPage(currentPage - 1);
          currentPage > 1 && loadMore(currentPage - 1);
        }}
      />
      {showPrevDots && <Pagination.Ellipsis />}
      {renderPaginationItems}
      {showNextDots && <Pagination.Ellipsis />}
      <Pagination.Next
        data-cy="NextPage"
        onClick={() => {
          currentPage + 1 <= maxPages && setCurrentPage(currentPage + 1);
          currentPage + 1 <= maxPages && loadMore(currentPage + 1);
        }}
      />
      <Pagination.Last
        onClick={() => {
          setCurrentPage(maxPages);
          loadMore(maxPages);
        }}
      />
    </Pagination>
  );
};

export default PagePagination;
