import gql from 'graphql-tag';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { FC } from 'react';
import Box from '../Box';
import { Map } from '../map/Map';
// eslint-disable-next-line import/no-unresolved
import { LocationCardFragment } from './__generated__/LocationCard';

type LocationCardProps = {
  fragments: (LocationCardFragment | undefined)[];
  size?: number;
};

type Location = {
  longitude: number;
  latitude: number;
  address?: string;
  city?: string;
};

const LocationCard: FC<LocationCardProps> = ({ fragments, size = 12 }) => {
  const locations = fragments
    .map((fragment) => {
      if (fragment?.object_longitute && fragment.object_latitude) {
        const location: Location = {
          longitude: fragment.object_longitute,
          latitude: fragment.object_latitude,
          address: `${fragment.object_street_name} ${fragment.object_house_number}`,
          city: `${fragment.object_postal_code} ${fragment.object_city}`,
        };
        return location;
      }
    })
    .filter((location): location is Location => !!location);

  return (
    <Box size={size} title="Landkarte" tooltipMessage={TOOLTIP_MESSAGE}>
      <Map
        style={{ height: '700px', margin: -16 }}
        pins={locations.map((location) => ({
          position: location,
          content: (
            <>
              {location.address}
              <br />
              {location.city}
            </>
          ),
        }))}
      />
    </Box>
  );
};

export const LOCATION_CARD_FRAGMENT = gql`
  fragment LocationCard on wma_input {
    object_latitude: object_data__location_latitude
    object_longitute: object_data__location_longitude
    object_street_name: object_data__street_name
    object_house_number: object_data__house_number
    object_postal_code: object_data__postal_code
    object_city: object_data__city
  }
`;

const TOOLTIP_MESSAGE = `
Wo befinden Sich die Immobilien, die in dem ausgewählten Zeitraum analysiert wurden? <br />
Dabei handelt es sich um die Adresse des Objekts, nicht um die Adresse des Nutzers/Interessenten.
`;

export default LocationCard;
