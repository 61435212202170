import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DayTimeCard, {
  DAY_TIME_CARD_FRAGMENT,
} from '../components/cards/DayTimeCard';
import GoalCard, { GOAL_CARD_FRAGMENT } from '../components/cards/GoalCard';
import LocationCard, {
  LOCATION_CARD_FRAGMENT,
} from '../components/cards/LocationCard';
import ObjectTypeTimeCard, {
  OBJECT_TYPE_TIME_CARD_FRAGMENT,
} from '../components/cards/ObjectTypeTimeCard';
import RequestTypeTimeCard, {
  REQUEST_TYPE_TIME_CARD_FRAGMENT,
} from '../components/cards/RequestTypeTimeCard';
import Layout from '../components/Layout';
import ReportingNav from '../components/ReportingNav';
import StatsFilter, { useStatsFilter } from '../components/StatsFilter';
import {
  AddTestRunFilter,
  getMetadataStatsFilterExpr,
} from '../utils/where-exprs';
import {
  ReportingAnalysisQuery,
  ReportingAnalysisQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/ReportingAnalysis';
import {
  getFilteredLocationFragments,
  MAX_LOCATION_MARKERS_ALLOWED,
} from '../utils';

const ReportingAnalysis: FC = () => {
  const { filter, updateFilter } = useStatsFilter();

  const { data, loading } = useQuery<
    ReportingAnalysisQuery,
    ReportingAnalysisQueryVariables
  >(REPORTING_ANALYSIS_QUERY, {
    variables: { where: getMetadataStatsFilterExpr(filter) },
  });

  const dayTimeFragments = data?.wma_metadata || [];
  const goalFragments =
    data?.wma_metadata.map((wma) => wma.input?.conversion_goal || undefined) ||
    [];
  const typeTimeFragments = data?.wma_metadata || [];
  const locationFragments = getFilteredLocationFragments(
    data,
    MAX_LOCATION_MARKERS_ALLOWED
  );

  return (
    <Layout title="Reporting // W-Analysen">
      <ReportingNav />
      <Row className="pt-3 pb-3">
        <Col lg={12}>
          <Card body border="light">
            <StatsFilter
              initialValues={filter}
              onValuesChange={(value) => updateFilter(value)}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <DayTimeCard
          fragments={dayTimeFragments}
          startDate={filter.startDate}
          endDate={filter.endDate}
          size={6}
          loading={loading}
        />
        <GoalCard
          fragments={goalFragments}
          size={6}
          type="PIE"
          loading={loading}
        />
        <ObjectTypeTimeCard
          fragments={typeTimeFragments}
          startDate={filter.startDate}
          endDate={filter.endDate}
          size={6}
          loading={loading}
        />
        <RequestTypeTimeCard
          fragments={typeTimeFragments}
          startDate={filter.startDate}
          endDate={filter.endDate}
          size={6}
          loading={loading}
        />
        <LocationCard fragments={locationFragments} size={6} />
      </Row>
    </Layout>
  );
};

const REPORTING_ANALYSIS_QUERY = gql`
  ${DAY_TIME_CARD_FRAGMENT}
  ${GOAL_CARD_FRAGMENT}
  ${OBJECT_TYPE_TIME_CARD_FRAGMENT}
  ${REQUEST_TYPE_TIME_CARD_FRAGMENT}
  ${LOCATION_CARD_FRAGMENT}
  query ReportingAnalysis($where: wma_metadata_bool_exp!) {
    wma_metadata(
      where: {
        _and: [
          $where
          { is_failure: { _eq: false } ${AddTestRunFilter()} }
          { input: { is_esg: { _neq: true } } }
        ]
      }
    ) {
      id: uuid
      ...DayTimeCard
      input {
        id: wma_id
        conversion_goal {
          ...GoalCard
        }
        ...LocationCard
      }
      ...ObjectTypeTimeCard
      ...RequestTypeTimeCard
    }
  }
`;

export default ReportingAnalysis;
