import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import useAccessToken from '../use-access-token';
import getEnv from '../get-env';
import { alertError } from '../../services/alert';

type CommercialAgent = {
  assignments: string[];
  ccEmail: string;
  createdAt: string;
  deletedAt: string | null;
  email: 'string';
  id: string;
  name: string;
  phone: string;
  position: string;
  salutation: string | null;
  surname: string;
  updatedAt: string;
};

export type GetCommercialAgent = (
  customerId?: number | null,
  plz?: string | null
) => Promise<CommercialAgent | null>;

type HookReturnType = {
  getCommercialAgent: GetCommercialAgent;
};

export const useGetCommercialAgent = (): HookReturnType => {
  const { user } = useAuth0();
  const [cache, setCache] = useState<Record<string, CommercialAgent>>();
  const { accessToken } = useAccessToken();

  const getCommercialAgent = React.useCallback(
    async (
      customerId?: number | null,
      plz?: string | null
    ): Promise<CommercialAgent | null> => {
      if (!customerId || !plz) return null;
      const myHeaders = new Headers();
      myHeaders.append('authorization', `Token ${accessToken}`);
      myHeaders.append('content-type', 'application/json');

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
      };

      const data = cache?.[`${customerId}:${plz}`]
        ? cache[`${customerId}:${plz}`]
        : await fetch(
            `${getEnv(
              'REACT_APP_API_URL'
            )}/customers/customers/commercial_agent/${customerId}/${plz}?token=${getEnv(
              'REACT_APP_CUSTOMER_API_TOKEN'
            )}`,
            requestOptions
          )
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              }
            })
            .catch((error) =>
              alertError(
                'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.',
                error
              )
            );

      setCache((curr) => ({ ...curr, [`${customerId}:${plz}`]: data }));
      return data?.[0];
    },
    [user, accessToken, cache]
  );

  return { getCommercialAgent };
};
