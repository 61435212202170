import formatter from 'format-number';
import moment from 'moment';
import { ReportingAnalysisQuery } from '../pages/__generated__/ReportingAnalysis';
import { LocationCardFragment } from '../components/cards/__generated__/LocationCard';

const sizeFormatter = formatter({
  integerSeparator: '.',
  decimalsSeparator: ',',
  suffix: ' m²',
});

export const formatSize = (value?: number | string): string | undefined => {
  if (typeof value === 'number') {
    return sizeFormatter(Number(value));
  }
};

const currencyFormatter = formatter({
  integerSeparator: '.',
  suffix: ' €',
  round: 0,
});

export const formatCurrency = (value?: number | string): string | undefined => {
  if (typeof value === 'number') {
    return currencyFormatter(value);
  }
};

const percentFormatter = formatter({
  integerSeparator: '.',
  suffix: '%',
  round: 0,
});

export const formatPercent = (value?: number): string => {
  if (typeof value === 'number') {
    return percentFormatter(value);
  } else {
    return percentFormatter(0);
  }
};

export const formatDateTime = (value?: string): string | undefined => {
  if (!value) {
    return;
  }
  const timestamp = moment(value, true);
  if (timestamp.isValid()) {
    return timestamp.format('DD.MM.YYYY hh:mm');
  }
};

export const formatDate = (value?: string): string | undefined => {
  if (!value) {
    return;
  }
  const timestamp = moment(value, true);
  if (timestamp.isValid()) {
    return timestamp.format('DD.MM.YYYY');
  }
};

export const formatDateLong = (value?: string): string | undefined => {
  if (!value) {
    return;
  }
  const timestamp = moment(value, true);
  if (timestamp.isValid()) {
    return timestamp.format('DD. MMM YYYY');
  }
};

export const MAX_LOCATION_MARKERS_ALLOWED = 200;
export const getFilteredLocationFragments = (
  data: ReportingAnalysisQuery | undefined,
  MAX_LOCATION_MARKERS_ALLOWED: number
): LocationCardFragment[] => {
  if (!data?.wma_metadata) {
    return [];
  }

  return [...data.wma_metadata]
    .sort(
      (a, b) =>
        new Date(b.acceptance_date).getTime() -
        new Date(a.acceptance_date).getTime()
    )
    .slice(0, MAX_LOCATION_MARKERS_ALLOWED)
    .map((wma) => wma.input)
    .filter((item) => item !== undefined);
};
