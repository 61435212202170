import { FC, ReactNode, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Modal,
  Row,
  useAccordionButton,
} from 'react-bootstrap';
import {
  FaChevronDown,
  FaChevronUp,
  FaInfoCircle,
  FaPaperPlane,
} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { TestID } from '../utils/constants/DataTestId';

type BoxProps = {
  size: number;
  title: ReactNode;
  toggle?: boolean;
  initialShow?: boolean;
  sendQuestion?: boolean;
  tooltipMessage?: string;
  children: ReactNode;
};

const Box: FC<BoxProps> = ({
  size,
  title,
  toggle,
  initialShow,
  tooltipMessage,
  sendQuestion,
  children,
}) => {
  const defaultExpanded = !toggle || initialShow || false;

  return (
    <Col className="pt-2 pb-3" lg={size}>
      <Accordion
        style={{ height: '100%' }}
        defaultActiveKey={defaultExpanded ? '0' : undefined}
      >
        <Card style={{ height: '100%' }} border="light">
          <AccordionCardHeader
            title={title}
            eventKey="0"
            defaultExpanded={defaultExpanded}
            toggle={toggle}
            tooltipMessage={tooltipMessage}
            sendQuestion={sendQuestion}
          />
          <Accordion.Collapse eventKey="0">
            <Card.Body>{children}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Col>
  );
};

type AccordionCardHeaderProps = {
  title: ReactNode;
  eventKey: string;
  defaultExpanded: boolean;
  toggle?: boolean;
  tooltipMessage?: ReactNode;
  sendQuestion?: boolean;
};

const AccordionCardHeader: FC<AccordionCardHeaderProps> = ({
  title,
  eventKey,
  defaultExpanded,
  toggle,
  tooltipMessage,
  sendQuestion,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);

  const decoratedOnClick = useAccordionButton(eventKey, () =>
    setExpanded(!expanded)
  );

  return (
    <Card.Header
      onClick={(e) => toggle && decoratedOnClick(e)}
      style={{
        paddingTop: 12,
        paddingBottom: 12,
      }}
      className="bg-white d-flex"
    >
      <div
        data-testid={TestID.FlexTable}
        className="flex-grow-1"
        style={{ fontSize: 14, fontWeight: 600 }}
      >
        {title}&nbsp;
      </div>
      <div>
        {toggle && expanded && <FaChevronUp size={12} className="ms-1" />}
        {toggle && !expanded && <FaChevronDown size={12} className="ms-1" />}
        {tooltipMessage && (
          <span data-tip={tooltipMessage}>
            <FaInfoCircle size={12} className="ms-1" />
            <ReactTooltip multiline />
          </span>
        )}
        {sendQuestion && (
          <>
            <span onClick={() => setIsQuestionModalVisible(true)}>
              <FaPaperPlane size={12} className="ms-1" />
            </span>
            <QuestionModal
              visible={isQuestionModalVisible}
              onHide={() => setIsQuestionModalVisible(false)}
            />
          </>
        )}
      </div>
    </Card.Header>
  );
};

type QuestionModalProps = { visible: boolean; onHide: () => void };

const QuestionModal: FC<QuestionModalProps> = ({ visible, onHide }) => {
  return (
    <Modal show={visible} onHide={onHide}>
      <Modal.Body>
        <p>Frage</p>
        <Row className="mt-4 mb-2">
          <Col lg={2}>
            <span className="float-right">
              <FaPaperPlane size={32} />
            </span>
          </Col>
          <Col lg={10}>
            <h3>Frage zum Diagramm stellen:</h3>
            <br />
            <textarea className="form-control" rows={3} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
        <Button variant="primary" onClick={onHide}>
          Abbruch
        </Button>
        <Button variant="primary" onClick={onHide}>
          Senden
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Box;
