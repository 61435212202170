// eslint-disable-next-line import/no-unresolved
import { wma_input_bool_exp } from '../graphql.types';
import CHART_COLORS from './chart-colors';

export type ObjectType =
  | 'RENTAL_APPARTMENT'
  | 'APPARTMENT'
  | 'HOUSE'
  | 'MULTI_HOUSE';

export const RENTAL_APPARTMENT_LABEL = 'Mietwohnung';
export const APPARTMENT_LABEL = 'Eigentumswohnung';
export const HOUSE_LABEL = 'Haus';
export const MULTI_HOUSE_LABEL = 'Mehrfamilienhaus';

export const OBJECT_TYPE_LABELS = new Map<ObjectType, string>([
  ['RENTAL_APPARTMENT', RENTAL_APPARTMENT_LABEL],
  ['APPARTMENT', APPARTMENT_LABEL],
  ['HOUSE', HOUSE_LABEL],
  ['MULTI_HOUSE', MULTI_HOUSE_LABEL],
]);

export const RENTAL_APPARTMENT_COLOR = CHART_COLORS[0];
export const APPARTMENT_COLOR = CHART_COLORS[1];
export const HOUSE_COLOR = CHART_COLORS[2];
export const MULTI_HOUSE_COLOR = CHART_COLORS[3];

export type ObjectTypeInput = {
  objectType?: number;
  marketingType?: number;
};

export const isRentalAppartment = (input: ObjectTypeInput): boolean =>
  input.marketingType === 1;
export const isAppartment = (input: ObjectTypeInput): boolean =>
  input.marketingType === 2;
export const isHouse = (input: ObjectTypeInput): boolean =>
  input.marketingType === 3 && input.objectType !== 62;
export const isMultiHouse = (input: ObjectTypeInput): boolean =>
  (input.marketingType === 3 || input.marketingType === 4) &&
  input.objectType === 62;

export const getObjectType = (
  input: ObjectTypeInput
): ObjectType | undefined => {
  if (isRentalAppartment(input)) return 'RENTAL_APPARTMENT';
  if (isAppartment(input)) return 'APPARTMENT';
  if (isHouse(input)) return 'HOUSE';
  if (isMultiHouse(input)) return 'MULTI_HOUSE';
};

export const getObjectTypeBoolExpr = (
  objectType: ObjectType
): wma_input_bool_exp => {
  if (objectType === 'RENTAL_APPARTMENT') return { marketing_type: { _eq: 1 } };
  if (objectType === 'APPARTMENT') return { marketing_type: { _eq: 2 } };
  if (objectType === 'HOUSE')
    return { marketing_type: { _eq: 3 }, object_data__category: { _neq: 62 } };
  if (objectType === 'MULTI_HOUSE')
    return {
      _or: [{ marketing_type: { _eq: 3 } }, { marketing_type: { _eq: 4 } }],
      object_data__category: { _eq: 62 },
    };
  return {};
};
