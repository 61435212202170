import { ApolloClient, InMemoryCache, useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { TableFilterLabels } from '../../components/TableFilter';
// eslint-disable-next-line import/no-unresolved
import { wma_metadata_bool_exp } from '../../graphql.types';

import {
  DownloadExcelQuery,
  DownloadExcelQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/use-download-excel';
import { AddTestRunFilter } from '../where-exprs';
import { useGetCommercialAgent, useIsUserCommercialAgent } from '.';
import { useCallback } from 'react';
import { createWorkBook, downloadWB } from '../generate-excel';
import type {
  HasLoopsQuery,
  HasLoopsQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from '../../components/WMATable/__generated__';
import { HAS_LOOPS_QUERY } from '../../components/WMATable';
import { createLoop5Map } from '../loops';
import { useTableFilter } from '../../components/TableFilter';
import { getCustomerIdFilter } from '../../utils/where-exprs-helpers';

type Props = {
  filterLabels: TableFilterLabels;
  filterExpr: wma_metadata_bool_exp;
}; // Using single object as hook props makes it easier to test hook rerenders with different props.
type HookReturnType = (hasPersonExport: boolean) => Promise<void>;

export const useDownloadExcel = ({
  filterLabels,
  filterExpr,
}: Props): HookReturnType => {
  const client = useApolloClient() as ApolloClient<InMemoryCache>;
  const { isCommercialAgent, loading: loadingIsCommercialAgent } =
    useIsUserCommercialAgent();
  const { getCommercialAgent } = useGetCommercialAgent();
  const { filter: tableFilter } = useTableFilter();
  const { commercialAgentFilter, customerNumberFilter } = getCustomerIdFilter(
    tableFilter.customerId
  );
  const downloadExcel = useCallback(
    async (hasPersonExport: boolean) => {
      if (
        loadingIsCommercialAgent ||
        !filterExpr ||
        JSON.stringify(filterExpr) === '{}' ||
        !filterLabels ||
        JSON.stringify(filterLabels) === '{}'
      )
        return;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = await getData(
        client,
        filterExpr,
        commercialAgentFilter,
        customerNumberFilter,
        !!tableFilter.customerId
      );
      const workbook = await createWorkBook(
        filterLabels,
        data,
        hasPersonExport,
        isCommercialAgent,
        getCommercialAgent
      );
      downloadWB(workbook);
    },
    [loadingIsCommercialAgent, filterLabels, filterExpr]
  );

  return downloadExcel;
};

const getData = async (
  client: ApolloClient<InMemoryCache>,
  filterExpr: wma_metadata_bool_exp,
  commercialAgentFilter: wma_metadata_bool_exp,
  customerNumberFilter: wma_metadata_bool_exp,
  includeQuery = false
) => {
  const { data } = await client.query<
    DownloadExcelQuery,
    DownloadExcelQueryVariables
  >({
    query: QUERY,
    variables: {
      where: filterExpr,
      commercialAgentFilter,
      customerNumberFilter,
      includeQuery,
    },
  });

  const isLoop5Filter: boolean =
    filterExpr._and?.[0]?._and?.some((expr) => expr.is_loop5?._eq === true) ||
    false;

  const concatedData = includeQuery
    ? data.wma_metadata_commercial_agent?.concat(
        data.wma_metadata_customer_number
      )
    : data.wma_metadata_customer_number;

  const wmaIds = concatedData?.map(
    (fragment: { input: { wma_id: unknown } }) => fragment.input.wma_id
  );

  if (!isLoop5Filter) {
    const hasLoops = await client.query<HasLoopsQuery, HasLoopsQueryVariables>({
      query: HAS_LOOPS_QUERY,
      variables: { wmaIds },
    });
    const loop5Map = createLoop5Map(hasLoops.data.v_wma_has_loops);
    const copiedData = concatedData?.map(
      (fragment: { input: { wma_id: string | number }; is_loop5: unknown }) => {
        return {
          ...fragment,
          is_loop5:
            loop5Map[fragment.input.wma_id] || fragment.is_loop5 || false,
        };
      }
    );
    return copiedData;
  }

  return concatedData;
};

export const EXCEL_DATA_FRAGMENT = gql`
  fragment ExcelData on wma_metadata {
    id: uuid
    acceptance_date
    cockpit_note
    cockpit_note_added_at
    cockpit_sent_at
    cockpit_telephone_displayed_at
    cockpit_last_user
    is_internal_request
    advisor_name
    is_loop5
    is_legacy
    input {
      wma_id
      conversion_goal {
        label_de
      }
      object_data__street_name
      object_data__house_number
      object_data__postal_code
      object_data__city
      object_data__category
      marketing_type
      user_consent
      sale {
        id: wma_id
        is_sold
        sale_price
        estimated_price_by_client
      }
      user {
        id: uuid
        title
        first_name
        last_name
        phone_number
        email
      }
      referer
      customer_number
      contract_id
      match_code

      metadata(
        limit: 1
        order_by: { acceptance_date: asc_nulls_last }
        where: { input: { match_code: { _is_null: false, _nlike: "" } } }
      ) {
        input {
          match_code
        }
      }

      metadata1: metadata(
        limit: 1
        order_by: { acceptance_date: asc_nulls_last }
        where: { is_failure: { _eq: false } }
      ) {
        acceptance_date
      }
    }
    output {
      reference_value: iib_immobilien_richtwert
      first_download_at_result1
      result_access_keys
      commercial_agent_id
    }
  }
`;

const QUERY = gql`
 ${EXCEL_DATA_FRAGMENT}

  query DownloadExcel(
    $where: wma_metadata_bool_exp!
    $commercialAgentFilter: wma_metadata_bool_exp!
    $customerNumberFilter: wma_metadata_bool_exp!
    $includeQuery: Boolean!
  ) {
    wma_metadata_customer_number: wma_metadata(
      where: {
        _and: [
          $where,
          $customerNumberFilter,
          { is_failure: { _eq: false }, ${AddTestRunFilter()} },
          { input: { is_esg: { _neq: true } } }
        ]
      }
    ) {
      ...ExcelData
  }

  wma_metadata_commercial_agent: wma_metadata(
      where: {
        _and: [
          $where,
          $commercialAgentFilter,
          { is_failure: { _eq: false }, ${AddTestRunFilter()} },
          { input: { is_esg: { _neq: true } } }
        ]
      }
    )  @include(if: $includeQuery) {
      ...ExcelData
  }
  }
`;
