import { FC, useMemo } from 'react';
import { Funnel } from '@ant-design/charts';
import { type FunnelConfig } from '@ant-design/charts';

import gql from 'graphql-tag';
import Box from '../Box';
import DiagramWrapper from '../DiagramWrapper';
import Loading from '../Loading';
import CHART_COLORS from '../../utils/chart-colors';
import { SalesFunnelCardFragment } from './__generated__/SalesFunnelCard';

type SalesFunnelCardProps = {
  fragments: (SalesFunnelCardFragment | undefined)[];
  size?: number;
  loading?: boolean;
};

type FunnelAggregate = {
  type: string;
  isLoop5: string;
  count: number;
};

const SalesFunnelChart: FC<SalesFunnelCardProps> = ({
  fragments,
  size = 12,
  loading,
}) => {
  // Dynamic data preparation using useMemo
  const data = useMemo((): FunnelAggregate[] => {
    const nestedData: {
      [key in 'loop5' | 'nonLoop5']: {
        [key2 in 'runs' | 'contacted' | 'exported' | 'sold']: FunnelAggregate;
      };
    } = {
      loop5: {
        runs: {
          type: 'Abrufe',
          isLoop5: 'Loop5 Abfrage',
          count: 0,
        },
        contacted: {
          type: 'Telefonnummer angezeigt',
          isLoop5: 'Loop5 Abfrage',
          count: 0,
        },
        exported: {
          type: 'Kontakt exportiert',
          isLoop5: 'Loop5 Abfrage',
          count: 0,
        },
        sold: {
          type: 'Auftrag erhalten',
          isLoop5: 'Loop5 Abfrage',
          count: 0,
        },
      },
      nonLoop5: {
        runs: {
          type: 'Abrufe',
          isLoop5: 'Erstabfrage',
          count: 0,
        },
        contacted: {
          type: 'Telefonnummer angezeigt',
          isLoop5: 'Erstabfrage',
          count: 0,
        },
        exported: {
          type: 'Kontakt exportiert',
          isLoop5: 'Erstabfrage',
          count: 0,
        },
        sold: {
          type: 'Auftrag erhalten',
          isLoop5: 'Erstabfrage',
          count: 0,
        },
      },
    };

    // Process each fragment to generate the required data
    fragments.forEach((fragment) => {
      if (!fragment) return;

      const nestedDataColumn = fragment.is_loop5
        ? nestedData.loop5
        : nestedData.nonLoop5;

      // Increment the appropriate counts based on the fragment data
      nestedDataColumn.runs.count++;
      if (fragment.cockpit_telephone_displayed_at)
        nestedDataColumn.contacted.count++;
      if (fragment.cockpit_sent_at) nestedDataColumn.exported.count++;
      if (fragment.input.sale?.is_sold) nestedDataColumn.sold.count++;
    });

    // Flatten the nested data structure into a single array
    return Object.values(nestedData).flatMap((nestedDataColumn) =>
      Object.values(nestedDataColumn)
    );
  }, [fragments]);

  // Funnel chart configuration
  const config: FunnelConfig = {
    data,
    xField: 'type',
    yField: 'count',
    compareField: 'isLoop5',
    minSize: 0.01, // Ensures a minimum size for each funnel section
    maxSize: 1, // Ensures consistency in maximum size
    dynamicHeight: true, // Adjusts each section's height dynamically to ensure proportional representation
    legend: {
      position: 'bottom',
      flipPage: false,
      itemName: {
        style: { fontSize: 14 },
      },
    },
    label: {
      position: 'middle',
      formatter: (datum) => {
        const dataItem = datum as FunnelAggregate;
        return `${dataItem.count}`;
      },
      style: {
        fill: '#444',
        lineHeight: 16,
      },
      offset: 20,
    },
    conversionTag: false,
    color: CHART_COLORS,
  };

  return (
    <Box size={size} title="Sales Funnel" tooltipMessage={TOOLTIP_MESSAGE}>
      {loading ? (
        <Loading />
      ) : (
        <DiagramWrapper>
          <Funnel {...config} style={{ height: 400, marginBottom: 30 }} />
        </DiagramWrapper>
      )}
    </Box>
  );
};

export const SALES_FUNNEL_CARD_FRAGMENT = gql`
  fragment SalesFunnelCard on wma_metadata {
    is_loop5
    cockpit_telephone_displayed_at
    cockpit_sent_at
    input {
      id: wma_id
      sale {
        is_sold
      }
    }
  }
`;

const TOOLTIP_MESSAGE = `
Der Sales Funnel bietet Ihnen eine gute Übersicht über den Bearbeitungsstand der Wohnmarktanalysen. <br />
Zunächst wird das Verhältnis zwischen internen und externen Abrufen aufgezeigt, danach werden Ihnen die Ergebnisse der Telefonate grafisch veranschaulicht. <br />
Was bedeuten die einzelnen Bereiche? <br />
Interne Abrufe: <br />
Wie viele Abrufe wurden von Ihnen und Ihren Kollegen eingegeben? <br />
Externe Abrufe: <br />
Wie viele Abrufe wurden über das Frontend-Tool auf ihrer Internetseite getätigt? <br />
Nicht bearbeitet: <br />
Eingehende Abrufe, die im Cockpit noch nicht bearbeitet wurden. Diese Anzahl sollte im Idealfall am Ende eines Arbeitstages gegen 0 gehen. <br />
Angerufen: <br />
Stufe 1 der Bearbeitung des Interessenten: Anruf. <br />
Bei wie vielen eingegangenen Analysen wurde im Cockpit die Telefonnummer des Nutzers abgerufen? <br />
Termin: <br />
Stufe 2 der Bearbeitung des Interessenten: Termin. <br />
Mit wie vielen Nutzern wurde ein konkreter Besichtigungstermin vereinbart? <br />
Auftrag: <br />
Stufe 3 der Bearbeitung des Interessenten: Auftrag. <br />
Wie viele Abrufe wurden aus dem Cockpit exportiert, bzw. an einen Kollegen oder Ihr CRM übergeleitet? <br />
Unsere Annahme ist hierbei ein erfolgreicher Besichtigungstermin und damit ein angestoßener Vermarktungsprozess.
`;

export default SalesFunnelChart;
