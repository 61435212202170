import type { Moment } from 'moment';
import moment from 'moment';

export type ContactDeadline = number | 'USER_REMOVED' | 'PERMISSION_GRANTED';

type ContactDeadlineInput = {
  userExists: boolean;
  permissionGranted: boolean;
  acceptedAt?: string | number | Moment;
  isLoop5?: boolean;
  isSparkasse?: boolean;
  now?: Moment;
};

export const getContactDeadline = ({
  userExists,
  permissionGranted,
  acceptedAt,
  isLoop5 = false,
  isSparkasse = false,
  now = moment(),
}: ContactDeadlineInput): ContactDeadline => {
  if (!userExists) return 'USER_REMOVED';

  if (permissionGranted && !(isLoop5 && isSparkasse))
    return 'PERMISSION_GRANTED';

  const CONTACT_PERIOD = isLoop5
    ? LOOP_WMA_CONTACT_PERIOD
    : REGULAR_WMA_CONTACT_PERIOD;
  const startOfAcceptedAt = acceptedAt ? moment(acceptedAt).startOf('day') : '';
  const startOfToday = now.startOf('day');

  return startOfAcceptedAt
    ? Math.max(
        moment(startOfAcceptedAt).diff(startOfToday, 'days') + CONTACT_PERIOD,
        0
      )
    : 0;
};

const REGULAR_WMA_CONTACT_PERIOD = 180;
const LOOP_WMA_CONTACT_PERIOD = 365;
