import React, { FC } from 'react';
import { FaCheckSquare, FaSquareFull } from 'react-icons/fa';

type CustomCheckboxProps = {
  label: string;
  checked: boolean;
  onCheck: (checked: boolean) => void;
};

const CustomCheckbox: FC<CustomCheckboxProps> = ({
  label,
  checked,
  onCheck,
}) => (
  <div onClick={() => onCheck(!checked)} data-cy="CheckBox">
    {!checked ? <FaSquareFull /> : <FaCheckSquare />}
    <span style={{ marginLeft: '1rem' }}>{label}</span>
  </div>
);

export default CustomCheckbox;
