import type { HasLoopsQuery } from '../components/WMATable/__generated__';
import type { Loop5Type } from '../components/WMATable/types';

type GetLoop5TypeProps = {
  isLoop5: boolean;
  loop5Map?: Record<string, boolean>;
  wmaId: string;
};
export const getLoop5Type = (props: GetLoop5TypeProps): Loop5Type => {
  const { isLoop5, loop5Map, wmaId } = props;
  if (isLoop5) return 'LOOP';
  if (loop5Map?.[wmaId]) return 'FIRST_RUN';
  return 'NOT_LOOP';
};

export const createLoop5Map = (
  hasLoopsArray: HasLoopsQuery['v_wma_has_loops'] = []
): Record<string, boolean> => {
  const loop5Map: Record<string, boolean> = {};
  const withLoopsArray = hasLoopsArray.filter((x) => x.has_loops);
  withLoopsArray.forEach((x) => {
    loop5Map[x.wma40_id] = true;
  });
  return loop5Map;
};
