import * as React from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import Box from './Box';
import type { match } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import { PhaseData, Subtask } from '../types/onboarding';

interface Props {
  match?: match;
  phaseData: PhaseData[];
  phaseNumber: number;
  phaseTitle: string;
}

interface State {
  loading: boolean;
  showDetailsPhase: null | number;
  showSubtasksOfPhase: null | number;
  showSubDetailsPhase: Record<number, number | null>;
}

export default class OnboardingColumn extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,

      showDetailsPhase: null,
      showSubtasksOfPhase: null,
      showSubDetailsPhase: {},
    };

    this.handleShowDetailsPhase = this.handleShowDetailsPhase.bind(this);
    this.handleShowSubtasks = this.handleShowSubtasks.bind(this);
    this.handleShowSubDetailsPhase = this.handleShowSubDetailsPhase.bind(this);
  }

  componentDidMount(): void {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      loading: false,
    });
  }

  handleShowDetailsPhase(id: number): void {
    let value: number | null = null;
    if (this.state.showDetailsPhase !== id) value = id;
    this.setState({
      showDetailsPhase: value,
    });
  }

  handleShowSubtasks(id: number): void {
    let value: number | null = null;
    if (this.state.showSubtasksOfPhase !== id) value = id;
    this.setState({
      showSubtasksOfPhase: value,
    });
  }

  handleShowSubDetailsPhase(index: number, subindex: number): void {
    let value: number | null = null;
    let parent = 0;
    if (this.state.showSubDetailsPhase[index] !== subindex) {
      value = subindex;
      parent = index;
    }
    this.setState({
      showSubDetailsPhase: { [parent]: value },
    });
  }

  render(): JSX.Element {
    return (
      <Box
        size={12}
        title={`Phase ${this.props.phaseNumber} // ${this.props.phaseTitle}`}
      >
        <ul className="sortable-list connectList agile-list">
          {this.props.phaseData.map((entry: PhaseData, index: number) => (
            <div key={`${index}column${this.props.phaseTitle}`}>
              <li className="warning-element">
                <strong>{entry.label}</strong>{' '}
                {entry.subtasks && entry.subtasks.length > 0 && (
                  <span
                    className="badge badge-danger float-right"
                    onClick={() => this.handleShowSubtasks(index)}
                  >
                    {entry.subtasks.length} Unteraufgaben{' '}
                    <span>
                      {this.state.showSubtasksOfPhase !== index ? (
                        <FaChevronRight />
                      ) : (
                        <FaChevronDown />
                      )}
                    </span>
                  </span>
                )}
                <div className="agile-detail">
                  <div className="row">
                    <span
                      className="col-4"
                      onClick={() => this.handleShowDetailsPhase(index)}
                    >
                      {this.state.showDetailsPhase !== index ? (
                        entry.text !== '' && (
                          <span>
                            Zeige Details <FaChevronRight />
                          </span>
                        )
                      ) : (
                        <React.Fragment>
                          <span>
                            Verberge Details <FaChevronDown />
                          </span>
                        </React.Fragment>
                      )}
                    </span>
                    <div className="col-8">
                      {entry.tags.map((tag: string) => (
                        <span
                          key={`${tag}subrow${this.props.phaseTitle}`}
                          className="float-right badge ml-1 mt-1"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                  {this.state.showDetailsPhase === index && (
                    <div className="row mt-4">
                      <div className="col-12">
                        <span className="mt-2">{entry.text}</span>
                      </div>
                    </div>
                  )}
                </div>
              </li>
              {this.state.showSubtasksOfPhase === index &&
                entry &&
                entry.subtasks &&
                entry.subtasks.map((subentry: Subtask, subindex: number) => (
                  <li
                    key={`${index}${subindex}${this.props.phaseTitle}`}
                    className="warning-element ml-4"
                  >
                    <strong>{subentry.label}</strong>
                    <div className="agile-detail">
                      <div className="row">
                        <span
                          className="col-6"
                          onClick={() =>
                            this.handleShowSubDetailsPhase(index, subindex)
                          }
                        >
                          {this.state.showSubDetailsPhase[index] !==
                          subindex ? (
                            <span>
                              Zeige Details <FaChevronRight />
                            </span>
                          ) : (
                            <span>
                              Verberge Details <FaChevronDown />
                            </span>
                          )}
                        </span>
                        <div className="col-6">
                          {subentry.tags.map((tag: string, index: number) => (
                            <span
                              key={`column${tag}${this.props.phaseTitle}${index}`}
                              className="float-right badge ml-1 mt-1"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                      </div>
                      {this.state.showSubDetailsPhase[index] === subindex && (
                        <div className="row mt-4">
                          <div className="col-12">
                            <span className="mt-2">{subentry.text}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
            </div>
          ))}
        </ul>
      </Box>
    );
  }
}
