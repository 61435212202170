import { FC, useEffect } from 'react';
import Select from 'react-select';
import SELECT_PROPS from './select-props';
import { TestID } from '../../utils/constants/DataTestId';

type IsLoop5SelectProps = {
  isLoop5: ThreeState;
  setIsLoop5: (isLoop5: ThreeState) => void;
  setIsLoop5Label?: (label?: string) => void;
};

const IsLoop5Select: FC<IsLoop5SelectProps> = ({
  isLoop5,
  setIsLoop5,
  setIsLoop5Label,
}) => {
  const value = SELECT_OPTIONS.find(({ value }) => value === isLoop5);
  useEffect(() => setIsLoop5Label?.(value?.label), [value]);

  return (
    <div data-testid={TestID.Loop5Filter} className="mb-2">
      <label>Loop5</label>
      <Select<SelectOption<ThreeState>>
        {...SELECT_PROPS<SelectOption<ThreeState>>()}
        isClearable={isLoop5 !== 'UNSPECIFIED'}
        options={SELECT_OPTIONS}
        value={value}
        onChange={(value) =>
          value?.value === undefined
            ? setIsLoop5('UNSPECIFIED')
            : setIsLoop5(value.value)
        }
      />
    </div>
  );
};

const SELECT_OPTIONS: SelectOption<ThreeState>[] = [
  { value: 'UNSPECIFIED', label: 'Alle' },
  { value: true, label: 'Mit ImmoMonitoring (Loop5)' },
  { value: false, label: 'Nur Erstabrufe' },
];

export default IsLoop5Select;
