/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ShapeAttrs } from '@antv/g-base';
import { MarkerCfg } from '@antv/g2/lib/interface';

const CHART_COLORS = [
  '#00B4B4',
  '#FA3A5A',
  '#FAB400',
  '#B4D21E',
  '#F06400',
  '#E8743B',
  '#A34534',
  '#58508d',
  '#945ECF',
  '#13A4B4',
  '#6C8893',
  '#2F6497',
  '#a1dbb1',
  '#ED4A7B',
];

export default CHART_COLORS;

export const STANDALONE_CHART_COLORS = [CHART_COLORS[2], CHART_COLORS[1]];

export const TOTAL_COLOR = '#F06400';

export const makeSquareMarker = (item: MarkerCfg): MarkerCfg => {
  const fill =
    (item?.style as ShapeAttrs)?.fill !== 'transparent' &&
    (item?.style as ShapeAttrs)?.fill;
  const color = fill || (item?.style as ShapeAttrs)?.stroke || 'transparent'; // fall back to transparent if the item has neither stroke nor fill.

  return {
    symbol: 'square',
    style: {
      r: 4,
      fill: color,
      stroke: 'transparent',
    },
  } as MarkerCfg;
};
