import { useMutation } from '@apollo/client';
import type { FetchResult } from '@apollo/client';
import gql from 'graphql-tag';
import {
  CockpitInternalRequestMutation,
  CockpitInternalRequestMutationVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/edit-is-internal-request';

const IS_INTERNAL_REQUEST_MUTATION = gql`
  mutation CockpitInternalRequest($runId: uuid!, $isInternalRequest: Boolean!) {
    update_wma_metadata_by_pk(
      pk_columns: { uuid: $runId }
      _set: { is_internal_request: $isInternalRequest }
    ) {
      id: uuid
      is_internal_request
    }
  }
`;

type EditIsInternalReturnType = (
  isInternalRequest: boolean
) => Promise<
  FetchResult<
    CockpitInternalRequestMutation,
    Record<string, unknown>,
    Record<string, unknown>
  >
>;

const useEditIsInternalRequest = (runId: uuid): EditIsInternalReturnType => {
  const [mutation] = useMutation<
    CockpitInternalRequestMutation,
    CockpitInternalRequestMutationVariables
  >(IS_INTERNAL_REQUEST_MUTATION);

  return (isInternalRequest: boolean) =>
    mutation({ variables: { runId, isInternalRequest } });
};

export default useEditIsInternalRequest;
