import { CSSProperties, FC, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FaShieldAlt } from 'react-icons/fa';
import { PulseLoader } from 'react-spinners';
import CustomCheckbox from './CustomCheckbox';
import { TestID } from '../utils/constants/DataTestId';

type ExcelExportProps = {
  onExport?: (hasPersonExport: boolean) => Promise<void>;
  className?: string;
  style?: CSSProperties;
};

const ExcelExport: FC<ExcelExportProps> = ({ onExport, className, style }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasPersonExport, setHasPersonExport] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleExport = async (hasPersonExport: boolean) => {
    setIsLoading(true);
    await onExport?.(hasPersonExport);
    setIsLoading(false);
  };

  return (
    <>
      <Button
        data-testid={TestID.BtnExcelExport}
        disabled={isLoading}
        className={className}
        style={style}
        onClick={() => setIsModalVisible(true)}
      >
        {isLoading && <PulseLoader size={4} />}
        Excel Export
      </Button>
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Body>
          <Row className="mb-2 mt-4">
            <Col lg={2} className="text-center">
              <FaShieldAlt size={32} />
            </Col>
            <Col lg={10}>
              <h3>Datenschutz beachten!</h3>
              <p>
                Bei dieser Aktion werden personenbezogene Daten bearbeitet oder
                exportiert. Bitte gehen Sie besonders sorgfältig vor, um nicht
                gegen die aktuellen Vorschriften des Datenschutzes zu verstoßen.
                <br />
                <br />
                Sprechen Sie im Zweifel Ihren Datenschutzbeauftragten an.
              </p>
              <CustomCheckbox
                label="Personenbezogene Daten mit exportieren, sofern möglich"
                checked={hasPersonExport}
                onCheck={(checked) => setHasPersonExport(checked)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
          <Button variant="primary" onClick={() => setIsModalVisible(false)}>
            Schließen
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleExport(hasPersonExport);
              setIsModalVisible(false);
            }}
          >
            Excel Export
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExcelExport;
