import { FC, ReactNode, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import {
  FaAddressCard,
  FaClipboardList,
  FaEuroSign,
  FaPhone,
  FaStickyNote,
} from 'react-icons/fa';
import useEditIsInternalRequest from '../../utils/cockpit-mutation-hooks/edit-is-internal-request';
import {
  formatCurrency,
  formatDateLong,
  formatPercent,
} from '../../utils/format';
import { getWMALinkLong, getWMALinkShort } from '../../utils/my-wma-link';
import CustomCheckbox from '../CustomCheckbox';
import ContactDeadlineBadge from './ContactDeadlineBadge';
import EditLogModal from './EditLogModal';
import ExportModal from './ExportModal';
import NoteModal from './NoteModal';
import SaleModal from './SaleModal';
import EstimateModel from './EstimateModel';
import TelephoneModal from './TelephoneModal';
import { RunData } from './types';
import Loop5List from './Loop5List';
import gql from 'graphql-tag';
import isUrl from 'is-url';

import {
  GetIsSpecialObjectQuery,
  GetIsSpecialObjectQueryVariables,
  GetWmaDeletionInfoQuery,
  GetWmaDeletionInfoQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/WMATableExpandedContent';
import { ExportOutlined } from '@ant-design/icons';
import OneLine from '../OneLine';
import { TestID } from '../../utils/constants/DataTestId';

type WMATableExpandedContentProps = {
  runData: RunData;
  changeCallback?: () => void;
  setInternalData: (internalData: boolean) => void;
};

const getSCommLandpageURL = (accessKey?: string): string => {
  if (!accessKey) return '';

  const urlCheck = isUrl(accessKey);
  if (urlCheck) return accessKey;

  return `https://immobilien.sparkasse.de/preisfinder-anfrage.html?hash=${accessKey}`;
};

const WMATableExpandedContent: FC<WMATableExpandedContentProps> = ({
  runData,
  changeCallback,
  setInternalData,
}) => {
  const editIsInternalRequest = useEditIsInternalRequest(runData.id);

  const { data, loading } = useQuery<
    GetIsSpecialObjectQuery,
    GetIsSpecialObjectQueryVariables
  >(WMA_TABLE_EXPANDED_CONTENT_SPECIAL, {
    variables: {
      _eq: runData.id,
    },
  });

  const { data: wmaDeletionInfo, loading: queryLoading } = useQuery<
    GetWmaDeletionInfoQuery,
    GetWmaDeletionInfoQueryVariables
  >(WMA_TABLE_EXPANDED_CONTENT_DELETION_INFO_QUERY, {
    variables: {
      _wmaId: `%${runData.wmaId}`,
    },
  });

  const [isSpecial, setIsSpecial] = useState<boolean | null | undefined>(
    data?.wma_overwrites[0]?.specialObject
  );

  const [isDeleted, setIsDeleted] = useState<boolean>();
  useEffect(() => {
    setIsSpecial(data?.wma_overwrites[0]?.specialObject);
  }, [data, isSpecial]);

  useEffect(() => {
    setIsDeleted(
      wmaDeletionInfo?.wma_user_change_logs[0]?.operation === 'DELETE'
    );
  }, [wmaDeletionInfo, isDeleted]);

  return (
    <>
      {!loading && !queryLoading && (
        <>
          <Row>
            <ContentCol title="Personenbezogene Daten">
              {runData.user.userExists && (
                <>
                  <span data-testid={TestID.WmaFullName}>
                    {runData.user?.firstName} {runData.user?.lastName}
                  </span>
                  <br />
                  {runData.user.email && (
                    <a href={`mailto:${runData.user.email}`} />
                  )}
                  <br />
                  <br />
                  {runData.user.telephone && (
                    <TelephoneModal
                      runId={runData.id}
                      phoneNumber={runData.user.telephone}
                      contactUserCallback={changeCallback}
                    >
                      <FaPhone /> Telefonnummer abrufen
                    </TelephoneModal>
                  )}
                </>
              )}
              {isDeleted ? (
                <>Personenbezogene Daten wurden gelöscht.</>
              ) : (
                <>-</>
              )}
            </ContentCol>
            <ContentCol title="Objektdetails">
              {runData.property.streetName} {runData.property.houseNumber}
              <br />
              {runData.property.zipCode} {runData.property.city}
              <br />
              <br />
              {runData.property.objectType}
              <br />
              {runData.property.objectTypeDetail}
              <br />
              Baujahr: {runData.property.constructionYear}
              <br />
              <br />
              {runData.meta.goal && <>Motiv: {runData.meta.goal}</>}
            </ContentCol>
            <ContentCol title="Wohnmarktanalyse">
              WMA-ID: {runData?.wmaId?.split('-')[0].toUpperCase()}
              <br />
              Stichtag: {formatDateLong(runData.meta.acceptedAt)}
              <br />
              <br />
              Richtwert: {formatCurrency(runData.meta.objectValue)}
              <br />
              <br />
              {
                <OneLine>
                  <a
                    className="link-dark text-decoration-none"
                    href={getWMALinkLong(runData, 0)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExportOutlined /> &nbsp;WMA Langfassung
                  </a>
                </OneLine>
              }
              <br />
              <br />
              {
                <OneLine>
                  <a
                    className="link-dark text-decoration-none"
                    href={getWMALinkShort(runData)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExportOutlined /> &nbsp; WMA Kurzfassung
                  </a>
                </OneLine>
              }
              <br />
              <br />
              {runData.matchCode?.startsWith('SK') && runData.accessKey ? (
                <a
                  className="link-dark text-decoration-none"
                  href={getSCommLandpageURL(runData.accessKey)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExportOutlined /> &nbsp; S-Com Landingpage
                </a>
              ) : (
                <></>
              )}
            </ContentCol>
            <ContentCol title="Kontaktfrist">
              <ContactDeadlineBadge
                userExists={!!(runData.user.userExists && !isDeleted)}
                permissionGranted={runData.user.permissionGranted}
                acceptedAt={
                  runData.meta.firstRunAcceptedAt || runData.meta.acceptedAt
                }
                loopType={runData.meta.loopType}
                isSparkasse={runData.matchCode?.startsWith('SK')}
                verbose
              />
              <br />
              <br />
              Überleitung an: {runData.processing.crmExportEmail || '-'}
              <br />
              Überleitung am:{' '}
              {runData.processing.crmExportEmail &&
              runData.processing.lastEditedAt
                ? formatDateLong(runData.processing.lastEditedAt)
                : '-'}
              <br />
              <br />
              {runData.processing.log && (
                <EditLogModal log={runData.processing.log}>
                  <FaClipboardList /> Bearbeitungsprotokoll einsehen
                </EditLogModal>
              )}
              <br />
              <br />
              <CustomCheckbox
                checked={runData.meta.isInternal}
                label="Datensatz ist ein interner Abruf"
                onCheck={(checked) => {
                  setInternalData(true);
                  editIsInternalRequest(checked);
                }}
              />
            </ContentCol>
            <ContentCol title="Status">
              Zuletzt bearbeitet von: {runData.processing.lastEditedBy || '-'}
              <br />
              Zuletzt bearbeitet am: &nbsp;
              {formatDateLong(runData.processing.lastEditedAt) || '-'}
              <br />
              <br />
              <NoteModal
                runId={runData.id}
                note={runData.processing.note}
                setNoteCallback={changeCallback}
              >
                <FaStickyNote /> Notiz hinzufügen
              </NoteModal>
              <br />
              <br />
              <ExportModal
                runId={runData.id}
                exportUserCallback={changeCallback}
              >
                {runData.user?.firstName !== undefined &&
                runData.user?.lastName !== undefined ? (
                  <>
                    <FaAddressCard /> An CRM übergeben
                  </>
                ) : (
                  <></>
                )}
              </ExportModal>
            </ContentCol>
            <ContentCol title="Auftrag">
              <SaleModal
                runId={runData.id}
                objectValue={runData.meta.objectValue}
                saleCallback={changeCallback}
              >
                <FaEuroSign />{' '}
                {runData.sale.isSold ? 'Auftrag verändern' : 'Auftrag erfassen'}
              </SaleModal>
              <br />
              {runData.sale.isSold ? 'Verkauft für' : 'Nicht verkauft'}
              <br />
              {runData.sale.price && (
                <>
                  {formatCurrency(runData.sale.price)}
                  {runData.meta.objectValue &&
                    `${
                      (runData.sale.price / runData.meta.objectValue) * 100 -
                        100 >
                      0
                        ? ` (+${formatPercent(
                            (runData.sale.price / runData.meta.objectValue) *
                              100 -
                              100
                          )})`
                        : ` (${formatPercent(
                            (runData.sale.price / runData.meta.objectValue) *
                              100 -
                              100
                          )})`
                    }`}
                </>
              )}
              <br />
              <br />
              <h4>Preiseinschätzung</h4>
              <EstimateModel
                runId={runData.id}
                objectValue={runData.meta.objectValue}
                estimateCallback={changeCallback}
              >
                <FaEuroSign />{' '}
                {runData.sale?.estimated_price_by_client
                  ? 'Preiseinschätzung verändern'
                  : 'Preisfeedback erfassen'}
              </EstimateModel>
              <br />
              {runData.sale?.estimated_price_by_client
                ? 'Geschätzt auf'
                : 'Kein Feedback gegeben'}
              <br />
              {runData.sale.estimated_price_by_client && (
                <>
                  {formatCurrency(runData.sale.estimated_price_by_client)}
                  {runData.meta.objectValue &&
                    `${
                      (runData.sale.estimated_price_by_client /
                        runData.meta.objectValue) *
                        100 -
                        100 >
                      0
                        ? ` (+${formatPercent(
                            (runData.sale.estimated_price_by_client /
                              runData.meta.objectValue) *
                              100 -
                              100
                          )})`
                        : ` (${formatPercent(
                            (runData.sale.estimated_price_by_client /
                              runData.meta.objectValue) *
                              100 -
                              100
                          )})`
                    }`}
                </>
              )}
            </ContentCol>
          </Row>
          {data &&
            runData.meta.loopType === 'LOOP' &&
            runData.wmaId &&
            !isSpecial && (
              <>
                <hr className="border-top" />
                <Row>
                  <Col>
                    <Loop5List wmaId={runData.wmaId} />
                  </Col>
                </Row>
              </>
            )}
        </>
      )}
    </>
  );
};

const ContentCol: FC<{ title: ReactNode; children?: ReactNode }> = ({
  title,
  children,
}) => (
  <Col>
    <strong>{title}</strong>
    <br />
    <br />
    {children}
  </Col>
);

const WMA_TABLE_EXPANDED_CONTENT_SPECIAL = gql`
  query GetIsSpecialObject($_eq: uuid = "") {
    wma_overwrites(where: { uuid: { _eq: $_eq } }) {
      specialObject
    }
  }
`;

const WMA_TABLE_EXPANDED_CONTENT_DELETION_INFO_QUERY = gql`
  query GetWmaDeletionInfo($_wmaId: String = "") {
    wma_user_change_logs(
      where: { description: { _like: $_wmaId } }
      order_by: {}
    ) {
      description
      timestamp
      user_uuid
      operation
    }
  }
`;

export default WMATableExpandedContent;
