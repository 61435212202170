import { FC, ReactNode, useState } from 'react';
import { Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import { FaEuroSign } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { alertSuccess, alertWarning } from '../../services/alert';
import {
  useUpsertSale,
  useDeletePrice,
} from '../../utils/cockpit-mutation-hooks/upsert-sale';
import { formatCurrency } from '../../utils/format';
import { TestID } from '../../utils/constants/DataTestId';

type SaleModalProps = {
  runId: uuid;
  objectValue?: number;
  saleCallback?: () => void;
  defaultOpen?: boolean;
  children?: ReactNode;
};

const SaleModal: FC<SaleModalProps> = ({
  runId,
  objectValue,
  saleCallback,
  defaultOpen = false,
  children,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(defaultOpen);
  const [salePrice, setSalePrice] = useState<number>();

  const upsertSale = useUpsertSale(runId);
  const deletePrice = useDeletePrice(runId);

  const handleSale = () => {
    if (salePrice === undefined) return;

    if (salePrice < 0) {
      alertWarning('The estimate value cannot be negative');
      return;
    } else if (
      objectValue !== undefined &&
      (salePrice / objectValue) * 100 - 100 > 100
    ) {
      alertWarning(
        `Die Abweichung beträgt mehr als 100%. Bitte wenden Sie sich direkt an info@iib-institut.de`
      );
      return;
    }
    upsertSale(salePrice)
      .catch(() => null)
      .finally(() => saleCallback?.());
    setIsModalVisible(false);
    alertSuccess(`Objekt verkauft für ${formatCurrency(salePrice)}.`);
  };

  const handleDeletePrice = () => {
    deletePrice()
      .catch(() => null)
      .finally(() => saleCallback?.());
    setIsModalVisible(false);
    alertSuccess(`Aktueller Wert gelöscht`);
  };

  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => setIsModalVisible(true)}
      >
        {children}
      </span>
      <Modal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        data-testid={TestID.SaleModalUpsert}
      >
        <Modal.Body>
          <Row className="mb-2 mt-4">
            <Col lg={2} className="text-center">
              <FaEuroSign size={32} />
            </Col>
            <Col lg={10}>
              <h3>Auftrag erfassen</h3>
              <InputGroup>
                <NumberFormat
                  className="form-control"
                  placeholder="Tatsächlicher Verkaufspreis"
                  thousandSeparator="."
                  decimalSeparator=","
                  value={salePrice}
                  onValueChange={({ floatValue }) => setSalePrice(floatValue)}
                />
                <InputGroup.Text>€</InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
          <Button
            data-testid={TestID.SaleModalUpsertLoschenButton}
            variant="primary"
            onClick={handleDeletePrice}
          >
            Auftrag löschen
          </Button>
          <Button
            data-testid={TestID.SaleModalUpsertAbbrechenButton}
            variant="primary"
            onClick={() => setIsModalVisible(false)}
          >
            Abbrechen
          </Button>
          <Button
            data-testid={TestID.SaleModalUpsertSpeichernButton}
            variant="primary"
            onClick={handleSale}
            disabled={!salePrice}
          >
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SaleModal;
