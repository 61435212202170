import { FC, ReactNode, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FaPhone } from 'react-icons/fa';
import useContactUser from '../../utils/cockpit-mutation-hooks/contact-user';

type TelephoneModalProps = {
  runId: uuid;
  phoneNumber: string;
  contactUserCallback?: () => void;
  children?: ReactNode;
};

const TelephoneModal: FC<TelephoneModalProps> = ({
  runId,
  phoneNumber,
  contactUserCallback,
  children,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const contactUser = useContactUser(runId);

  const handleOpen = () => {
    contactUser()
      .catch(() => null)
      .finally(() => contactUserCallback?.());
    setIsModalVisible(true);
  };

  return (
    <>
      <span style={{ cursor: 'pointer' }} onClick={handleOpen}>
        {children}
      </span>
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Body>
          <Row className="mb-2 mt-4">
            <Col lg={2} className="text-center">
              <FaPhone size={32} />
            </Col>
            <Col lg={10}>
              <h3>Telefonnummer: {phoneNumber}</h3>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
          <Button variant="primary" onClick={() => setIsModalVisible(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TelephoneModal;
