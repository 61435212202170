import { Column } from '@ant-design/charts';
import gql from 'graphql-tag';
import moment from 'moment';
import { FC, useMemo } from 'react';
import Box from '../Box';

import DiagramWrapper from '../DiagramWrapper';
import Loading from '../Loading';
import { STANDALONE_CHART_COLORS } from '../../utils/chart-colors';
// eslint-disable-next-line import/no-unresolved
import { ContactTimingCardFragment } from './__generated__/ContactTiminigCard';

type ContactTimingCardProps = {
  fragments: (ContactTimingCardFragment | undefined)[];
  size?: number;
  loading?: boolean;
};

type TimingAggregate = {
  day: string;
  label: string;
  count: number;
};

const ContactTimingCard: FC<ContactTimingCardProps> = ({
  fragments,
  size = 12,
  loading,
}) => {
  const data = useMemo((): TimingAggregate[] => {
    let dayCount = 7;

    const getDataPoint = (dayDiff: number) => ({
      contact: {
        day: dayDiff === 1 ? '1 Tag' : `${dayDiff} Tage`,
        label: 'Erste Kontaktaufnahme',
        count: 0,
      },
      download: {
        day: dayDiff === 1 ? '1 Tag' : `${dayDiff} Tage`,
        label: 'Aufruf Langfassung',
        count: 0,
      },
    });

    const nestedData = Array.from(Array(dayCount).keys()).map((i) =>
      getDataPoint(i)
    );

    fragments.forEach((fragment) => {
      const acceptanceDate = fragment?.acceptance_date;
      const contactDate = fragment?.cockpit_telephone_displayed_at;
      const downloadDate = fragment?.output?.first_download_at_result1;

      if (acceptanceDate && contactDate) {
        const dayDiff = moment(contactDate).diff(
          moment(acceptanceDate),
          'days'
        );

        if (dayDiff >= dayCount) {
          const diff = dayDiff - dayCount + 1;
          nestedData.push(
            ...Array.from(Array(diff).keys()).map((i) =>
              getDataPoint(i + dayCount)
            )
          );
          dayCount = dayDiff + 1;
        }

        if (dayDiff >= 0) {
          nestedData[dayDiff].contact.count++;
        }
      }

      if (acceptanceDate && downloadDate) {
        const dayDiff = moment(downloadDate).diff(
          moment(acceptanceDate),
          'days'
        );

        if (dayDiff >= dayCount) {
          const diff = dayDiff - dayCount + 1;
          nestedData.push(
            ...Array.from(Array(diff).keys()).map((i) =>
              getDataPoint(i + dayCount)
            )
          );
          dayCount = dayDiff + 1;
        }

        if (dayDiff >= 0) {
          nestedData[dayDiff].download.count++;
        }
      }
    });

    return nestedData
      .map(({ contact, download }) => [contact, download])
      .flatMap((value) => value);
  }, [fragments]);

  return (
    <Box
      size={size}
      title="Dauer bis zur Kontaktaufnahme"
      tooltipMessage={TOOLTIP_MESSAGE}
    >
      {loading && <Loading />}
      {!loading && (
        <DiagramWrapper>
          <Column
            style={{ height: 400, marginBottom: 30 }}
            data={data}
            xField="day"
            yField="count"
            seriesField="label"
            isGroup
            legend={{
              position: 'bottom',
              flipPage: false,
              itemName: { style: { fontSize: 14 } },
            }}
            meta={{ count: { alias: 'Anzahl' } }}
            color={STANDALONE_CHART_COLORS}
          />
        </DiagramWrapper>
      )}
    </Box>
  );
};

export const CONTACT_TIMING_CARD_FRAGMENT = gql`
  fragment ContactTimingCard on wma_metadata {
    acceptance_date
    cockpit_telephone_displayed_at
    output {
      id: uuid
      first_download_at_result1
    }
  }
`;

const TOOLTIP_MESSAGE = `
Der grüne Balken zeigt, in welchem Zeitraum ein eingegangener Kontakt angerufen wurde. <br />Der rote Balken zeigt, in welchem Zeitraum die jeweils dazugehörige Beraterfassung heruntergeladen wurde. <br />
Bitte beachten Sie: Da der rote Balken für die ergänzende Beraterfassung steht, <br />
ergibt das Addieren der roten und grünen Kennzahlen nicht die Gesamtzahl der Abrufe. <br />
Hinweis: Ein Lead gilt bereits nach wenigen Stunden als „kalt“. <br />
Demnach sollte die Kontaktaufnahme binnen 24 Stunden abgeschlossen sein. <br />
In den Blocks auf der rechten Seite sehen Sie den Anteil der zu lange <br />
unbearbeiteten Abrufe in absoluter Anzahl und als Anteil an der Gesamtsumme von Abrufen. <br />
Diese Zahlen sollten für ein erfolgreiches Arbeiten mit der Wohnmarktanalyse möglichst gegen 0 gehen.
`;
export default ContactTimingCard;
