import { FC, useEffect } from 'react';
import Select from 'react-select';
import SELECT_PROPS from './select-props';
import {
  STATUS_FILTER_LABELS,
  type StatusFilterValues,
} from '../../utils/status-filter';
import { TestID } from '../../utils/constants/DataTestId';

type SelectValue = { value?: StatusFilterValues; label?: string };

type StatusFilterSelectProps = {
  statusFilter?: StatusFilterValues;
  setStatusFilter: (statusFilter?: StatusFilterValues) => void;
  setStatusFilterLabel?: (label?: string) => void;
};

const StatusFilterSelect: FC<StatusFilterSelectProps> = ({
  statusFilter,
  setStatusFilter,
  setStatusFilterLabel,
}) => {
  const value = SELECT_OPTIONS?.find(({ value }) => value === statusFilter);
  useEffect(() => setStatusFilterLabel?.(value?.label));

  return (
    <div className="mb-2" data-testid={TestID.StatusSelectField}>
      <label>Status </label>
      <Select<SelectValue>
        {...SELECT_PROPS<SelectValue>()}
        isClearable={statusFilter !== undefined}
        options={SELECT_OPTIONS}
        value={value}
        onChange={(value) => setStatusFilter(value?.value)}
      />
    </div>
  );
};

const SELECT_OPTIONS: SelectValue[] = [
  { value: 'ALL', label: STATUS_FILTER_LABELS.get('ALL') },
  { value: 'PROCESSING', label: STATUS_FILTER_LABELS.get('PROCESSING') },
  { value: 'SOLD', label: STATUS_FILTER_LABELS.get('SOLD') },
  { value: 'UNPROCESSED', label: STATUS_FILTER_LABELS.get('UNPROCESSED') },
  // {
  //   value: 'LEAD_FORWARDED',
  //   label: STATUS_FILTER_LABELS.get('LEAD_FORWARDED'), TODO: uncomment this when LEAD_FORWARDED is implemented
  // },
  // { value: 'DELETED', label: STATUS_FILTER_LABELS.get('DELETED') },
];

export default StatusFilterSelect;
