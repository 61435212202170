import Layout from '../components/Layout';
import React, { FC } from 'react';

export const Error404: FC = () => {
  return (
    <Layout title="Seite nicht gefunden">
      Unter dieser Adresse wurde leider keine Seite gefunden.
    </Layout>
  );
};
