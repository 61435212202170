import { Bar, Pie } from '@ant-design/charts';
import { useQuery } from '@apollo/client';
import capitalize from 'capitalize';
import gql from 'graphql-tag';
import { FC, useMemo } from 'react';
import CHART_COLORS, { makeSquareMarker } from '../../utils/chart-colors';
import { formatPercent } from '../../utils/format';
import Box from '../Box';
import DiagramWrapper from '../DiagramWrapper';
import Loading from '../Loading';
// eslint-disable-next-line import/no-unresolved
import { GoalCardFragment, GoalCardListQuery } from './__generated__/GoalCard';

type GoalCardProps = {
  fragments: (GoalCardFragment | undefined)[];
  size?: number;
  type?: 'BAR' | 'PIE';
  loading?: boolean;
};

type GoalAggregate = {
  label: string;
  count: number;
};

const GoalCard: FC<GoalCardProps> = ({
  fragments,
  size = 12,
  type = 'BAR',
  loading,
}) => {
  const { data: goalListData } =
    useQuery<GoalCardListQuery>(GOAL_CARD_LIST_QUERY);

  const goals = useMemo(
    () =>
      fragments.filter((goal): goal is GoalCardFragment => goal !== undefined),
    [fragments]
  );

  const data = useMemo(() => {
    if (goalListData?.wma_conversion_request_motivation) {
      const goalMap = new Map<number, GoalAggregate>();
      goalListData.wma_conversion_request_motivation.forEach((goal) =>
        goalMap.set(goal.id, {
          label: capitalize(goal.label_de || ''),
          count: 0,
        })
      );

      goals.forEach((goal) => {
        const goalAggregate = goalMap.get(goal.id);
        goalAggregate && goalAggregate.count++;
      });

      return Array.from(goalMap).map(([_, goalAgg]) => goalAgg);
    }
    return [];
  }, [goals, goalListData]);

  const totalCount = data
    .map(({ count }) => count)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <Box size={size} title="Abrufe nach Motiv" tooltipMessage={TOOLTIP_MESSAGE}>
      {loading && <Loading />}
      {!loading && (
        <>
          {type === 'BAR' && (
            <DiagramWrapper>
              <Bar
                style={{ height: 400, marginBottom: 30 }}
                data={data}
                xField="count"
                yField="label"
                meta={{ count: { alias: 'Abrufe' } }}
                appendPadding={[0, 20, 0, 0]}
                label={{
                  position: 'right',
                  offset: 5,
                  formatter: (datum) => {
                    const { count } = datum as GoalAggregate;
                    const percent = totalCount > 0 ? count / totalCount : 0;
                    return formatPercent(percent * 100);
                  },
                }}
                color={BAR_COLOR}
              />
            </DiagramWrapper>
          )}
          {type === 'PIE' && (
            <DiagramWrapper>
              <Pie
                style={{ height: 400, marginBottom: 30 }}
                data={data}
                angleField="count"
                colorField="label"
                radius={0.9}
                label={{ content: '' }}
                interactions={[{ type: 'element-active' }]}
                legend={{
                  marker: (_name, _index, item) => makeSquareMarker(item),
                  position: 'bottom',
                  flipPage: false,
                  itemName: {
                    style: { fontSize: 14 },
                    formatter: (text: string) => {
                      const item = data
                        .filter((d) => d.label === text)
                        .find(() => true);
                      return `${text} (${item?.count})`;
                    },
                  },
                }}
                color={CHART_COLORS}
              />
            </DiagramWrapper>
          )}
        </>
      )}
    </Box>
  );
};

export const GOAL_CARD_FRAGMENT = gql`
  fragment GoalCard on wma_conversion_request_motivation {
    id: value
  }
`;

export const GOAL_CARD_LIST_QUERY = gql`
  query GoalCardList {
    wma_conversion_request_motivation {
      id: value
      label_de
    }
  }
`;

const BAR_COLOR = CHART_COLORS[2];

const TOOLTIP_MESSAGE = `
Warum Kunden die Wohnmarktanalyse bestellt haben, wird Ihnen in dieser Statistik aufgeführt. <br />
Das hilft ihnen dabei zu priorisieren, um welche Abrufe sie sich zu erst kümmern sollten. <br />
Hierfür wird Ihnen sowohl die absolute Zahl der jeweiligen Nutzer aufgeführt(Spalte 1), <br />
sowie der prozentuale Anteil der Gesamtabrufer(Spalte 2). <br />
<br />
Unser Tipp: <br />
Es kommt häufig vor, dass Nutzer sich noch nicht auf ein Motiv festlegen wollen <br />
und daher „Sonstiges“ oder „Nur bewerten“ auswählen.Diese Abfragen sollten Sie jedoch <br />
keineswegs ignorieren – hier versteckt sich großes Potential! Denn selbst wenn diese <br />
Nutzer sich mit Ihrer Entscheidung noch etwas Zeit lassen wollen, <br />
ob sie nun verkaufen, kaufen oder doch mieten wollen, können Sie bereits hier ansetzen und <br />
einen wertvollen Kontakt für die Zukunft herstellen.
`;
export default GoalCard;
