import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Box from '../components/Box';
import CustomCheckbox from '../components/CustomCheckbox';
import Layout from '../components/Layout';
import OnboardingColumn from '../components/OnboardingColumn';
import { PHASE1_DATA, PHASE2_DATA, PHASE3_DATA } from '../utils';
// eslint-disable-next-line import/no-unresolved
import { PhaseData } from '../types/onboarding';
import type { match } from 'react-router';

interface Props {
  match?: match;
}

interface State {
  loading: boolean;
  selectedTags: string[];
  tags: string[];
  phase1Data: PhaseData[];
  phase2Data: PhaseData[];
  phase3Data: PhaseData[];
  phase1FilteredData: PhaseData[];
  phase2FilteredData: PhaseData[];
  phase3FilteredData: PhaseData[];
}

export default class Onboarding extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      selectedTags: [],
      tags: [
        'Mitarbeiter / Nutzer',
        'Marketing',
        'Medialer Vertrieb',
        'Vertriebssteuerung',
        'Leiter Kunden Service Center',
        'Leiter Privatkundenberatung',
        'Leiter Immobilienabteilung',
        'Vorstände',
        'Projektleiter',
        'Bank / Institut',
        'iib',
      ],
      phase1Data: PHASE1_DATA,
      phase2Data: PHASE2_DATA,
      phase3Data: PHASE3_DATA,
      phase1FilteredData: [],
      phase2FilteredData: [],
      phase3FilteredData: [],
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  componentDidMount(): void {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      loading: false,
      phase1FilteredData: PHASE1_DATA,
      phase2FilteredData: PHASE2_DATA,
      phase3FilteredData: PHASE3_DATA,
    });
  }

  handleChangeFilter(): void {
    for (let dataSetIndex = 1; dataSetIndex <= 3; dataSetIndex++) {
      let results: PhaseData[] = [];

      const data =
        dataSetIndex === 1
          ? this.state.phase1Data
          : dataSetIndex === 2
          ? this.state.phase2Data
          : this.state.phase3Data;
      // data object
      for (let i = 0; i < data.length; i++) {
        // every key in data object
        for (const key in data[i]) {
          // if it has tags
          if (key === 'tags' && data[i][key].length > 0) {
            // every selected tag in filter component
            for (
              let indexFilterTag = 0;
              indexFilterTag < this.state.selectedTags.length;
              indexFilterTag++
            ) {
              // if tag is in parent
              if (
                data[i][key].includes(this.state.selectedTags[indexFilterTag])
              ) {
                results.push(data[i]); // push in array
              }
            }
          }
          // if parent has subtasks
          if (key === 'subtasks' && (data[i][key]?.length || 0) > 0) {
            // for every subtask
            for (
              let subTaskindex = 0;
              subTaskindex < (data[i]?.subtasks?.length || 0);
              subTaskindex++
            ) {
              // every selected sub tag in filter component
              for (
                let indexFilterTag = 0;
                indexFilterTag < this.state.selectedTags.length;
                indexFilterTag++
              ) {
                // if tag is in child
                if (
                  data[i][key]?.[subTaskindex].tags &&
                  data[i][key]?.[subTaskindex].tags.includes(
                    this.state.selectedTags[indexFilterTag]
                  )
                ) {
                  // check if entry not already in arry
                  for (
                    let resultIndex = 0;
                    resultIndex < results.length;
                    resultIndex++
                  ) {
                    if (results[resultIndex].label !== data[i].label) {
                      results.push(data[i]); // push in array
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (this.state.selectedTags.length === 0) {
        results =
          dataSetIndex === 1
            ? this.state.phase1Data
            : dataSetIndex === 2
            ? this.state.phase2Data
            : this.state.phase3Data;
      }
      if (dataSetIndex === 1) {
        this.setState({
          phase1FilteredData: results,
        });
      }
      if (dataSetIndex === 2) {
        this.setState({
          phase2FilteredData: results,
        });
      }
      if (dataSetIndex === 3) {
        this.setState({
          phase3FilteredData: results,
        });
      }
    }
  }

  handleCheckbox(dataFromCheckbox: string): void {
    const checkboxArray = this.state.selectedTags;
    if (!this.state.selectedTags.includes(dataFromCheckbox)) {
      checkboxArray.push(dataFromCheckbox);
    } else {
      const index = checkboxArray.indexOf(dataFromCheckbox); // <-- Not supported in <IE9
      if (index !== -1) {
        checkboxArray.splice(index, 1);
      }
    }
    this.setState({
      selectedTags: checkboxArray,
    });
    this.handleChangeFilter();
  }

  render(): JSX.Element {
    return (
      <Layout title="Onboarding Prozess">
        <Row className="mt-3">
          <Box size={12} title="Rollen filtern">
            <Row>
              {this.state.tags.map((tag) => (
                <div key={tag} className="col-3">
                  <CustomCheckbox
                    label={tag}
                    checked={this.state.selectedTags.includes(tag)}
                    onCheck={() => this.handleCheckbox(tag)}
                  />
                </div>
              ))}
            </Row>
          </Box>
        </Row>

        <Row>
          <Col lg={4} style={{ height: '100%' }}>
            <OnboardingColumn
              phaseTitle="Planung"
              phaseNumber={1}
              phaseData={this.state.phase1FilteredData}
            />
          </Col>
          <Col lg={4} style={{ height: '100%' }}>
            <OnboardingColumn
              phaseTitle="Einführung"
              phaseNumber={2}
              phaseData={this.state.phase2FilteredData}
            />
          </Col>
          <Col lg={4} style={{ height: '100%' }}>
            <OnboardingColumn
              phaseTitle="Laufender Betrieb"
              phaseNumber={3}
              phaseData={this.state.phase3FilteredData}
            />
          </Col>
        </Row>
      </Layout>
    );
  }
}
