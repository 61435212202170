import { useApolloClient } from '@apollo/client';
import capitalize from 'capitalize';
import classNames from 'classnames';
import gql from 'graphql-tag';
import objectPath from 'object-path';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { FormControl, Table } from 'react-bootstrap';
import {
  FaAddressCard,
  FaChevronDown,
  FaChevronRight,
  FaPhone,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaStickyNote,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import { HiRefresh } from 'react-icons/hi';
import { Column, Row, useSortBy, useTable } from 'react-table';
import { formatCurrency, formatDateLong } from '../../utils/format';
import { getWMALinkLong } from '../../utils/my-wma-link';
import {
  getObjectType,
  ObjectType,
  OBJECT_TYPE_LABELS,
} from '../../utils/object-type';
import Loading from '../Loading';
import OneLine from '../OneLine';
import ContactDeadlineBadge from './ContactDeadlineBadge';
import DeletePersonalDataModal from './DeletePersonalDataModal';
import ExportModal from './ExportModal';
import NoteModal from './NoteModal';
import StatusBadge from './StatusBadge';
import TelephoneModal from './TelephoneModal';
import { RunData } from './types';
import WMATableExpandedContent from './WMATableExpandedContent';
import {
  useGetCommercialAgent,
  useIsUserCommercialAgent,
} from '../../utils/hooks';

import Paginator from '../Pagination';
import {
  WMATableFragment,
  WMATableRunQuery,
  WMATableRunQueryVariables,
  type HasLoopsQuery,
  type HasLoopsQueryVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__';
import { TestID } from '../../utils/constants/DataTestId';
import { ExportOutlined } from '@ant-design/icons';
import { useTableFilter } from '../TableFilter';
import { createLoop5Map, getLoop5Type } from '../../utils';

type WMATableProps = {
  fragments: WMATableFragment[];
  totalCount: number;
  searchQuery: string;
  onSearchQueryChange: (searchQuery: string) => void;
  loadMore?: (num: number) => void;
  loading?: boolean;
};

const WMATable: FC<WMATableProps> = ({
  fragments,
  totalCount,
  searchQuery,
  onSearchQueryChange,
  loadMore,
  loading,
}) => {
  const { filter } = useTableFilter();
  const { isCommercialAgent, loading: loadingIsCommercialAgent } =
    useIsUserCommercialAgent();
  const { getCommercialAgent } = useGetCommercialAgent();
  const [expandedId, setExpandedId] = useState<uuid>();
  const [data, setData] = useState<RunData[]>([]);
  const [, setInternalData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [processing, setProcessing] = useState<boolean>(false);
  const expand = (runId: uuid) => {
    runId === expandedId
      ? setExpandedId(undefined)
      : (setExpandedId(runId), setInternalData(false));
  };
  const loadRun = useLoadRun();
  const verifyHasLoops = useVerifyHasLoops();

  const processData = async () => {
    setProcessing(true);
    const wmaIds = fragments.map((fragment) => fragment.input.wma_id);
    let loop5Map: Record<string, boolean> = {};
    if (filter.isLoop5 !== true) {
      const hasLoops = await verifyHasLoops(wmaIds);
      loop5Map = createLoop5Map(hasLoops.data.v_wma_has_loops);
    }
    const mapped = fragments.map(
      (fragment): RunData => ({
        id: fragment.id,
        wmaId: fragment.input?.wma_id,
        legacyId: fragment.legacy_id ? String(fragment.legacy_id) : undefined,
        matchCode: fragment.input.match_code
          ? String(fragment.input.match_code)
          : String(fragment.input.metadata[0]?.input?.match_code || ''),
        accessKey: fragment.access_key
          ? String(fragment.access_key)
          : String(fragment.input.metadata[0]?.access_key || ''),
        commercialAgentId: fragment.output?.commercial_agent_id,
        user: {
          firstName: fragment.input?.user?.first_name || undefined,
          lastName: fragment.input?.user?.last_name || undefined,
          email: fragment.input?.user?.email,
          telephone: fragment.input?.user?.phone_number || undefined,
          userExists: !!fragment.input?.user,
          permissionGranted: !!fragment.input?.user_consent,
        },
        customer: {
          id: fragment.input.customer_number || undefined,
        },
        property: {
          contractId: fragment.input?.contract_id || undefined,
          streetName: fragment.input?.object_data__street_name || undefined,
          houseNumber: fragment.input?.object_data__house_number || undefined,
          zipCode: fragment.input?.object_data__postal_code || undefined,
          city: fragment.input?.object_data__city || undefined,
          constructionYear:
            fragment.input?.object_data__construction_year || undefined,
          objectType: OBJECT_TYPE_LABELS.get(
            getObjectType({
              objectType: fragment.input?.object_data__category || undefined,
              marketingType: fragment.input?.marketing_type,
            }) as ObjectType
          ),
          objectTypeDetail:
            fragment.input?.conversion_object_category?.label_de || undefined,
        },
        meta: {
          acceptedAt: fragment.acceptance_date,
          firstRunAcceptedAt: fragment.input.metadata1[0]?.acceptance_date,
          objectValue: fragment.output?.reference_value || undefined,
          goal: fragment.input?.conversion_goal?.label_de || undefined,
          isInternal: !!fragment.is_internal_request,
          loopType: getLoop5Type({
            isLoop5: fragment.is_loop5,
            loop5Map,
            wmaId: fragment.input.wma_id,
          }),
          referrer: fragment.input.referer || undefined,
          isLegacy: fragment.is_legacy,
          legacyAccessKeys: (() => {
            const accessKey1 = objectPath.get<string | undefined>(
              (fragment.output?.result_access_keys as Record<
                string,
                string
              > | null) || {},
              'access_key1',
              undefined
            );
            const accessKey2 = objectPath.get<string | undefined>(
              (fragment.output?.result_access_keys as Record<
                string,
                string
              > | null) || {},
              'access_key2',
              undefined
            );

            if (accessKey1 && accessKey2)
              return {
                longDownload: accessKey1,
                shortDownload: accessKey2,
              };
            return undefined;
          })(),
        },
        processing: {
          lastEditedBy: fragment.cockpit_last_user || undefined,
          lastEditedAt: fragment.cockpit_last_edit || undefined,
          note: fragment.cockpit_note || undefined,
          crmExportAt: fragment.cockpit_sent_at
            ? fragment.cockpit_sent_at
            : '0',
          crmExportEmail: fragment.cockpit_sent_email || undefined,
          telephoneDisplayedAt: fragment.cockpit_telephone_displayed_at
            ? fragment.cockpit_telephone_displayed_at
            : '0',
          noteAddedAt: fragment.cockpit_note_added_at
            ? fragment?.cockpit_note_added_at
            : '0',
          log: fragment.user_log || undefined,
        },
        sale: {
          isSold: !!fragment.input.sale?.is_sold,
          price: fragment.input.sale?.sale_price || undefined,
          estimated_price_by_client:
            fragment.input.sale?.estimated_price_by_client || undefined,
        },
        OldRuns: null,
      })
    );
    const comparator = (a: RunData, b: RunData) => {
      if (!a.meta.acceptedAt) return 1;
      if (!b.meta.acceptedAt) return -1;
      return (
        new Date(b.meta.acceptedAt).getTime() -
        new Date(a.meta.acceptedAt).getTime()
      );
    };
    mapped.sort(comparator);
    const onlyLatest: Record<string, RunData> = {};
    mapped.forEach((data) => {
      if (!data.wmaId) return;
      if (!onlyLatest[data.wmaId]) {
        onlyLatest[data.wmaId] = { ...data, OldRuns: [] };
      } else {
        onlyLatest[data.wmaId]?.OldRuns?.push(data);
      }
    });

    let latest = Object.values(onlyLatest);

    if (isCommercialAgent) {
      const promises = latest.map((run) =>
        getCommercialAgent(run.commercialAgentId, run.property.zipCode)
      );
      const emails = await Promise.all(promises);
      const withEmails = latest.map((run, index) => ({
        ...run,
        commercialAgentEmail: emails[index]?.email,
      }));
      latest = withEmails;
    }

    setData(latest);
    setProcessing(false);
    return latest;
  };

  const columns = useMemo((): Column<RunData>[] => {
    const cols: Column<RunData>[] = [
      {
        Header: '',
        id: 'expander',
        Cell: ({ row }: { row: Row<RunData> }) => (
          <span
            data-testid={TestID.ExpanderWmaData}
            onClick={() => expand(row.original.id)}
          >
            {expandedId !== row.original.id && <FaChevronRight />}
            {expandedId === row.original.id && <FaChevronDown />}
          </span>
        ),
        disableSortBy: true,
      },
      {
        Header: 'WMA',
        id: 'link',
        Cell: ({ row }: { row: Row<RunData> }) => (
          <OneLine>
            <a
              data-testid={TestID.AnchorMyWmaDashboard}
              className="link-dark"
              href={getWMALinkLong(row.original, 0)}
              target="_blank"
              rel="noreferrer"
            >
              <ExportOutlined />
            </a>
          </OneLine>
        ),
      },
      {
        Header: 'ID',
        id: 'id',
        Cell: ({ row }: { row: Row<RunData> }) => (
          <OneLine>
            <div data-testid={TestID.WmaId}>
              {row.original.wmaId?.split('-')[0].toUpperCase()}
              {row.original.legacyId && (
                <>
                  <br /> {row.original.legacyId}
                </>
              )}
              {row.original.meta.loopType === 'LOOP' && (
                <HiRefresh style={{ color: '#1ab394' }} />
              )}
            </div>
          </OneLine>
        ),
      },
      {
        Header: 'Tel',
        id: 'phone',
        Cell: ({ row }: { row: Row<RunData> }) => (
          <>
            {row.original.user.telephone && (
              <TelephoneModal
                data-testid={TestID.WmaPhone}
                runId={row.original.id}
                phoneNumber={row.original.user.telephone}
                contactUserCallback={() => loadRun(row.original.id)}
              >
                <FaPhone />
              </TelephoneModal>
            )}
          </>
        ),
      },
      {
        Header: 'E-Mail',
        accessor: (run) => (
          <span data-testid={TestID.WmaEmail}>{run.user.email}</span>
        ),
      },
      {
        Header: 'PLZ',
        accessor: (run) => (
          <span data-testid={TestID.WmaPlz}>{run.property.zipCode}</span>
        ),
      },
      {
        Header: 'Ort',
        accessor: (run) => run.property.city,
      },
      {
        Header: 'Abrufdatum',
        accessor: (run) => run.meta.acceptedAt,
        Cell: ({ value }: { value?: string }) => (
          <span data-testid={TestID.WmaExecutionDate}>
            {formatDateLong(value) || '-'}
          </span>
        ),
      },
      {
        Header: 'Motiv',
        accessor: (run) => run.meta.goal,
        Cell: ({ value }: { value?: string }) => (
          <OneLine>{value && capitalize(value)}</OneLine>
        ),
      },
      {
        Header: 'Richtwert',
        accessor: (run) => run.meta.objectValue,
        Cell: ({ value }: { value?: number }) => (
          <OneLine>{formatCurrency(value)}</OneLine>
        ),
      },
      {
        Header: 'Kontaktfrist',
        accessor: (run) => run.user.userExists && run.meta.acceptedAt,
        Cell: ({ row }: { row: Row<RunData> }) => (
          <ContactDeadlineBadge
            userExists={row.original.user.userExists}
            permissionGranted={row.original.user.permissionGranted}
            acceptedAt={
              row.original.meta.firstRunAcceptedAt ||
              row.original.meta.acceptedAt
            }
            isSparkasse={row.original.matchCode?.startsWith('SK')}
            loopType={row.original.meta.loopType}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Löschen',
        id: 'delete',
        Cell: ({ row }: { row: Row<RunData> }) => {
          const { wmaId } = row.original;
          if (!wmaId) {
            return null;
          }
          const { userExists } = row.original.user;
          if (userExists) {
            return (
              <DeletePersonalDataModal
                data-testid={TestID.BtnDeletePersonalData}
                runId={wmaId}
                deleteUserCallback={() => loadRun(row.original.id)}
              >
                <FaTrashAlt />
              </DeletePersonalDataModal>
            );
          }
          return <FaTimes />;
        },
      },
      {
        Header: 'Quelle',
        accessor: (run) => run.meta.referrer,
        disableSortBy: true,
      },
      {
        Header: 'Bearbeiter',
        accessor: (run) => run.processing.lastEditedBy,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: ({ row }: { row: Row<RunData> }) => (
          <StatusBadge runData={row.original} />
        ),
      },
      {
        Header: 'Notiz',
        accessor: (run) => run.processing.note,
        Cell: ({ value: note, row }: { value?: string; row: Row<RunData> }) =>
          note ? (
            <NoteModal
              runId={row.original.id}
              note={note}
              setNoteCallback={() => loadRun(row.original.id)}
            >
              <FaStickyNote />
            </NoteModal>
          ) : null,
        disableSortBy: true,
      },
      {
        Header: 'Export',
        id: 'export',
        Cell: ({ row }: { row: Row<RunData> }) => {
          const { id: runId, user } = row.original;
          const { userExists } = user;
          if (userExists) {
            return (
              <ExportModal
                data-testid={TestID.BtnExportData}
                runId={runId}
                exportUserCallback={() => loadRun(row.original.id)}
              >
                <FaAddressCard />
              </ExportModal>
            );
          }
          return <FaTimes />;
        },
      },
    ];

    if (isCommercialAgent) {
      const index = cols.findIndex((col) => col.Header === 'Bearbeiter');
      cols.splice(index, 0, {
        Header: 'Handelsvertreter',
        id: 'commercialAgent',
        accessor: (run) => run.commercialAgentEmail,
      });
    }
    return cols;
  }, [expandedId, isCommercialAgent]);

  const tableInstance = useTable<RunData>({ columns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    if (!fragments || loadingIsCommercialAgent) return;

    processData();
  }, [fragments, loadingIsCommercialAgent]);

  return (
    <>
      <FormControl
        data-testid={TestID.SearchTable}
        className="mb-3"
        placeholder={`${totalCount} Ergebnisse in Tabelle durchsuchen`}
        value={searchQuery}
        onChange={(e) => onSearchQueryChange(e.target.value)}
      />
      <Table
        data-testid={TestID.WmaTable}
        responsive
        {...getTableProps()}
        style={{ overflowY: 'auto' }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <OneLine>
                    {column.render('Header')}
                    &nbsp;
                    <span>
                      {column.canSort && (
                        <>
                          {column.isSortedDesc === undefined && <FaSort />}
                          {column.isSortedDesc === true && <FaSortDown />}
                          {column.isSortedDesc === false && <FaSortUp />}
                        </>
                      )}
                    </span>
                  </OneLine>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <Fragment key={rowProps.key}>
                <tr
                  className={classNames({
                    'table-success': row.original.sale.isSold,
                    'table-light-gray':
                      !row.original.processing.telephoneDisplayedAt &&
                      !row.original.sale.isSold,
                  })}
                  {...rowProps}
                >
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
                {expandedId === row.original.id && (
                  <tr>
                    <td colSpan={columns.length}>
                      <WMATableExpandedContent
                        runData={row.original}
                        changeCallback={() => loadRun(row.original.id)}
                        setInternalData={setInternalData}
                      />
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      {(loading || loadingIsCommercialAgent || processing) && (
        <div style={{ height: '100px', width: '100px' }}>
          <Loading />
        </div>
      )}
      {loadMore && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <Paginator
            loadMore={loadMore}
            totalCount={totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </>
  );
};

export const WMA_TABLE_FRAGMENT = gql`
  fragment WMATable on wma_metadata {
    id: uuid
    legacy_id
    acceptance_date
    input {
      id: wma_id
      wma_id
      is_esg
      user {
        id: uuid
        first_name
        last_name
        email
        phone_number
      }
      customer_number
      contract_id
      object_data__street_name
      object_data__house_number
      object_data__postal_code
      object_data__city
      object_data__category
      object_data__construction_year
      marketing_type
      conversion_object_category {
        id: value
        value
        label_de
      }
      conversion_goal {
        id: value
        value
        label_de
      }
      user_consent
      sale {
        id: wma_id
        is_sold
        sale_price
        estimated_price_by_client
      }
      referer
      match_code

      metadata(
        limit: 1
        order_by: { acceptance_date: asc_nulls_last }
        where: { access_key: { _is_null: false, _nlike: "" } }
      ) {
        access_key
        input {
          match_code
        }
      }

      metadata1: metadata(
        limit: 1
        order_by: { acceptance_date: asc_nulls_last }
        where: { is_failure: { _eq: false } }
      ) {
        acceptance_date
      }
    }
    output {
      id: uuid
      reference_value: iib_immobilien_richtwert
      result_access_keys
      commercial_agent_id
    }
    cockpit_note
    cockpit_last_edit
    cockpit_last_user
    cockpit_sent_at
    cockpit_sent_email
    cockpit_telephone_displayed_at
    user_log
    is_internal_request
    is_loop5
    is_legacy
    cockpit_note_added_at
    access_key
  }
`;

export const HAS_LOOPS_QUERY = gql`
  query HasLoops($wmaIds: [uuid!]!) {
    v_wma_has_loops(where: { wma40_id: { _in: $wmaIds } }) {
      count_matched_id_on_abos
      has_loops
      wma40_id
    }
  }
`;

const WMA_TABLE_RUN_QUERY = gql`
  ${WMA_TABLE_FRAGMENT}
  query WMATableRun($runId: uuid!) {
    wma_metadata_by_pk(uuid: $runId) {
      ...WMATable
    }
  }
`;

const useVerifyHasLoops = () => {
  const client = useApolloClient();

  return async (wmaIds: uuid[]) => {
    const stuff = await client.query<HasLoopsQuery, HasLoopsQueryVariables>({
      query: HAS_LOOPS_QUERY,
      variables: { wmaIds },
    });

    return stuff;
  };
};

const useLoadRun = () => {
  const client = useApolloClient();

  return (runId: uuid) => {
    client.query<WMATableRunQuery, WMATableRunQueryVariables>({
      query: WMA_TABLE_RUN_QUERY,
      variables: { runId },
      fetchPolicy: 'network-only',
    });
  };
};

export default WMATable;
