import SweetAlert from 'sweetalert2';
import type { SweetAlertResult } from 'sweetalert2';

export const alertSuccess = (
  msg: string,
  text?: string
): Promise<SweetAlertResult> =>
  SweetAlert.fire({
    type: 'success',
    title: msg,
    text: text || '',
  });

export const alertError = (
  msg: string,
  text?: string
): Promise<SweetAlertResult> =>
  SweetAlert.fire({
    type: 'error',
    title: msg,
    text: text || '',
  });

export const alertWarning = (
  msg: string,
  text?: string
): Promise<SweetAlertResult> =>
  SweetAlert.fire({
    type: 'warning',
    title: msg,
    text: text || '',
  });

export const alertConfirm = async (
  msg: string,
  text: string
): Promise<SweetAlertResult['value']> => {
  const result = await SweetAlert.fire({
    type: 'warning',
    title: msg,
    text,
    showCloseButton: true,
    showCancelButton: true,
  });
  return result.value;
};
