import gql from 'graphql-tag';
import moment from 'moment';
import React, { FC } from 'react';
import { formatPercent } from '../../utils/format';
import Box from '../Box';
import Loading from '../Loading';
// eslint-disable-next-line import/no-unresolved
import { LateDownloadCardFragment } from './__generated__/LateDownloadCard';

type LateDownloadCardProps = {
  fragments: (LateDownloadCardFragment | undefined)[];
  size?: number;
  loading?: boolean;
};

const LateDownloadCard: FC<LateDownloadCardProps> = ({
  fragments,
  size = 12,
  loading,
}) => {
  const totalCount = fragments.length;
  const lateDownloadCount = fragments.filter((fragment) => {
    const acceptanceDate = fragment?.acceptance_date;
    const downloadDate = fragment?.output?.first_download_at_result1;

    if (acceptanceDate && downloadDate) {
      const dayDiff = moment(downloadDate).diff(moment(acceptanceDate), 'days');
      if (dayDiff > 7) {
        return true;
      }
    }
    return false;
  }).length;

  const percent = formatPercent(
    totalCount !== 0 ? (lateDownloadCount / totalCount) * 100 : 0
  );

  return (
    <Box size={size} title="Aufruf Langfassung nach mehr als 7 Tagen">
      {loading && <Loading />}
      {!loading && (
        <>
          <p className="fs-2">
            {lateDownloadCount}
            &nbsp;
            <span className="text-secondary">({percent})</span>
          </p>
          <p>Wohnmarktanalysen wurden erst nach über einer Woche abgerufen.</p>
        </>
      )}
    </Box>
  );
};

export const LATE_DOWNLOAD_CARD_FRAGMENT = gql`
  fragment LateDownloadCard on wma_metadata {
    acceptance_date
    output {
      id: uuid
      first_download_at_result1
    }
  }
`;

export default LateDownloadCard;
