import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Col, Row } from 'react-bootstrap';
import { useStorage } from '../utils/use-storage';
import CustomerSelect from './filter-items/CustomerSelect';
import TimeRangeSelect from './filter-items/TimeRangeSelect';

export type StatsFilterValues = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  customerId?: number;
  userId: string | undefined;
};

type StatsFilterProps = {
  initialValues: StatsFilterValues;
  onValuesChange: (values: StatsFilterValues) => void;
};

const StatsFilter: FC<StatsFilterProps> = ({
  initialValues,
  onValuesChange,
}) => {
  const { user } = useAuth0();

  const [startDate, setStartDate] = useState<moment.Moment>(
    initialValues.startDate
  );
  const [endDate, setEndDate] = useState<moment.Moment>(initialValues.endDate);
  const [customerId, setCustomerId] = useState<number>();

  useEffect(() => {
    setStartDate(initialValues.startDate);
  }, [initialValues.startDate]);
  useEffect(() => {
    setEndDate(initialValues.endDate);
  }, [initialValues.endDate]);
  useEffect(() => {
    setCustomerId(initialValues.customerId);
  }, [initialValues.customerId]);

  const handleInitialChange = (value: number) => {
    onValuesChange({
      startDate,
      endDate,
      customerId: value,
      userId: user?.name,
    });
  };
  const handleChange = () => {
    onValuesChange({
      startDate,
      endDate,
      customerId,
      userId: user?.name,
    });
  };

  return (
    <div id="statsFilter">
      <Row>
        <Col lg={6}>
          <CustomerSelect
            customerId={customerId}
            setCustomerId={setCustomerId}
            handleInitialChange={handleInitialChange}
            PreselectKunde
          />
        </Col>
        <Col lg={6}>
          <TimeRangeSelect
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={{ span: 3, offset: 9 }}>
          <Button
            className="w-100"
            onClick={handleChange}
            data-cy="Statistiken-Button"
          >
            Statistiken aktualisieren
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default StatsFilter;

type StatsFilterStoredValues = {
  startDate?: number;
  endDate?: number;
  customerId?: number;
  userId: string | undefined;
};

export const useStatsFilter = (): {
  filter: StatsFilterValues;
  updateFilter: (filter: StatsFilterValues) => void;
} => {
  const { user } = useAuth0();
  const { getStoredValues, setStoredValues } =
    useStorage<StatsFilterStoredValues>('FILTER');

  const [stateTrigger, setStateTrigger] = useState(0);

  const storedValues = getStoredValues();
  const filter: StatsFilterValues = {
    ...storedValues,
    startDate: storedValues.startDate
      ? moment(storedValues.startDate)
      : moment().startOf('month'),
    endDate: storedValues.endDate
      ? moment(storedValues.endDate)
      : moment().startOf('day'),
    userId: user?.name,
  };
  const updateFilter = (filter: StatsFilterValues) => {
    setStoredValues({
      ...filter,
      startDate: filter.startDate.valueOf(),
      endDate: filter.endDate.valueOf(),
      userId: user?.name,
    });
    setStateTrigger(stateTrigger + 1);
  };

  return { filter, updateFilter };
};
