import {
  ApolloClient,
  ApolloProvider as DefaultApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { FC, ReactNode } from 'react';
import getEnv from '../utils/get-env';

type Props = {
  children: ReactNode;
  accessToken: string;
};

const ApolloProvider: FC<Props> = ({ children, accessToken }) => {
  const client = createApolloClient(accessToken);

  return (
    <DefaultApolloProvider client={client}>{children}</DefaultApolloProvider>
  );
};

const ADMIN_ROLE = 'erfolgscockpit_iib_admin';
const CUSTOMER_ROLE = 'erfolgscockpit_customer';
const IIB_ROLES = 'https://iib-institut.de/roles';
type UserRoleToken = {
  [IIB_ROLES]?: string[];
};

const createApolloClient = (accessToken?: string) => {
  const decoded = accessToken
    ? jwtDecode<UserRoleToken>(accessToken)
    : undefined;
  const isAdmin = decoded?.[IIB_ROLES]?.includes(
    'ROLE_WMA4_ERFOLGCOCKPIT_IIB_ADMIN'
  );
  const isCustomer = decoded?.[IIB_ROLES]?.includes(
    'ROLE_WMA4_ERFOLGCOCKPIT_CUSTOMER'
  );

  const role = isAdmin ? ADMIN_ROLE : isCustomer ? CUSTOMER_ROLE : undefined;

  const httpLink = new HttpLink({
    uri: (operation) => {
      const url =
        operation.getContext().clientName === UNIFIED_API_CLIENT_NAME
          ? getEnv('REACT_APP_GRAPHQL_UNIFIED_API_BASE_URL')
          : getEnv('REACT_APP_GRAPHQL_DEFAULT_BASE_URL');
      return url || '';
    },
    headers: {
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...(role ? { 'X-Hasura-Role': role } : {}),
    },
  });

  const cache = new InMemoryCache({});

  return new ApolloClient({
    cache,
    link: httpLink,
  });
};

export default ApolloProvider;

export const DEFAULT_CLIENT_NAME = 'DEFAULT';
export const UNIFIED_API_CLIENT_NAME = 'UNIFIED_API';
