import React, { useEffect, type FC } from 'react';
import { Datenschutz as PandoraDatenschutz } from '@iib/pandora-box';

const Datenschutz: FC = () => {
  useEffect(() => {
    document.body.style.setProperty('background-color', '#fff', 'important');
  });
  return <PandoraDatenschutz />;
};

export default Datenschutz;
