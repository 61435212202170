import { useMutation } from '@apollo/client';
import type { FetchResult } from '@apollo/client';
import gql from 'graphql-tag';
import {
  EstimatedPriceMutation,
  EstimatedPriceMutationVariables,
  // eslint-disable-next-line import/no-unresolved
} from './__generated__/upsert-estimate';

const UPSERT_ESTIMATED_PRICE_MUTATION = gql`
  mutation EstimatedPrice($runId: uuid!, $estimated_price_by_client: Float) {
    upsert_wma_estimated_price_by_metadata_uuid(
      metadata_uuid: $runId
      estimated_price_by_client: $estimated_price_by_client
    ) {
      success
    }
  }
`;

type UpsertEstimateReturnType = (
  estimated_price_by_client?: number
) => Promise<
  FetchResult<
    EstimatedPriceMutation,
    Record<string, unknown>,
    Record<string, unknown>
  >
>;

const useUpsertEstimate = (runId: uuid): UpsertEstimateReturnType => {
  const [mutation] = useMutation<
    EstimatedPriceMutation,
    EstimatedPriceMutationVariables
  >(UPSERT_ESTIMATED_PRICE_MUTATION);

  return (estimated_price_by_client?: number) =>
    mutation({ variables: { runId, estimated_price_by_client } });
};

const DELETE_ESTIMATEPRICE_MUTATION = gql`
  mutation DeleteEstimatedPrice($runId: uuid!) {
    upsert_wma_estimated_price_by_metadata_uuid(
      metadata_uuid: $runId
      estimated_price_by_client: null
    ) {
      success
    }
  }
`;

type DeleteEstimatePriceReturnType = () => Promise<
  FetchResult<
    EstimatedPriceMutation,
    Record<string, unknown>,
    Record<string, unknown>
  >
>;

const useDeleteEstimatePrice = (runId: uuid): DeleteEstimatePriceReturnType => {
  const [mutation] = useMutation<
    EstimatedPriceMutation,
    EstimatedPriceMutationVariables
  >(DELETE_ESTIMATEPRICE_MUTATION);
  return () => mutation({ variables: { runId } });
};
export { useUpsertEstimate, useDeleteEstimatePrice };
