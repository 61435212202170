type HookReturnType<T> = {
  getStoredValues: () => Partial<T>;
  setStoredValues: (values: Partial<T>) => void;
};

export const useStorage = <T>(storageKey: string): HookReturnType<T> => {
  const getStoredValues = (): Partial<T> => {
    const serializedValues = localStorage.getItem(storageKey);
    if (!serializedValues) return {};
    try {
      return JSON.parse(serializedValues);
    } finally {
      // always
    }
  };

  const setStoredValues = (values: Partial<T>) => {
    const currentValues = getStoredValues() || {};

    localStorage.setItem(
      storageKey,
      JSON.stringify({
        ...currentValues,
        ...values,
      })
    );
  };

  return {
    getStoredValues,
    setStoredValues,
  };
};
