import { alertError, alertSuccess } from '../../services/alert';
import getEnv from '../get-env';
import useAccessToken from '../use-access-token';

type HookReturnType = {
  sendEmail: (metadataUuid: string, email: string) => Promise<void>;
};

export const useSendXmlEmail = (): HookReturnType => {
  const { accessToken } = useAccessToken();

  const sendEmail = async (
    metadataUuid: string,
    email: string
  ): Promise<void> => {
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Token ${accessToken}`);
    myHeaders.append('content-type', 'application/json');

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email,
      }),
    };

    try {
      const sent = await fetch(
        `${getEnv(
          'REACT_APP_API_URL'
        )}/mail-automation/wmas/${metadataUuid}/sendxmlemail`,
        requestOptions
      );
      if (sent.status === 200) {
        alertSuccess('Visitenkarte versendet.');
      } else {
        alertError(
          'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.',
          sent.statusText
        );
      }
    } catch (error: unknown) {
      alertError(
        'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.',
        typeof error === 'string' ? error : ''
      );
    }
  };

  return { sendEmail };
};
