import { Heatmap } from '@ant-design/charts';
import gql from 'graphql-tag';
import moment from 'moment';
import { FC, useMemo } from 'react';
import Box from '../Box';

import Color from 'color';
import DiagramWrapper from '../DiagramWrapper';
import Loading from '../Loading';
import CHART_COLORS from '../../utils/chart-colors';
// eslint-disable-next-line import/no-unresolved
import { DayTimeCardFragment } from './__generated__/DayTimeCard';

type DayTimeCardProps = {
  fragments: (DayTimeCardFragment | undefined)[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  size?: number;
  loading?: boolean;
};

type HeatmapDatum = {
  date: string;
  hour: string;
  count: number;
};

const DayTimeCard: FC<DayTimeCardProps> = ({
  fragments,
  startDate,
  endDate,
  size = 12,
  loading,
}) => {
  const data = useMemo(() => {
    const dayCount = Math.max(endDate.diff(startDate, 'days') + 1, 1);

    const dates = fragments
      .map((fragment) => fragment?.acceptance_date)
      .filter((dateString): dateString is string => dateString !== undefined)
      .map((dateString) => moment(dateString))
      .filter((date) => date.isValid());

    const nestedData = Array.from(Array(dayCount).keys()).map((dayDiff) => {
      const date = startDate.clone().add(dayDiff, 'days').format('ll (dd)');
      return Array.from(Array(24).keys()).map((hourDiff) => {
        const hour = `${hourDiff}:00`;
        return { date, hour, count: 0 };
      });
    });

    dates.forEach((date) => {
      const dayDiff = date.diff(startDate, 'days');
      const hourDiff = date.diff(date.clone().startOf('day'), 'hours');

      nestedData[dayDiff][hourDiff].count++;
    });

    return nestedData.flatMap((value) => value);
  }, [fragments, startDate, endDate]);

  const maxCount = useMemo(
    () => Math.max(...data.map(({ count }) => count)),
    [data]
  );

  return (
    <Box
      size={size}
      title="Tageszeit-Übersicht"
      tooltipMessage={TOOLTIP_MESSAGE}
    >
      {loading && <Loading />}
      {!loading && (
        <DiagramWrapper>
          <Heatmap
            style={{ height: 400, marginBottom: 30 }}
            data={data}
            xField="date"
            yField="hour"
            colorField="count"
            meta={{ count: { alias: 'Abrufe' } }}
            color={(datum) => {
              const count: number = (datum as HeatmapDatum).count || 0;
              const percent = maxCount > 0 ? count / maxCount : 0;

              return Color(CHART_COLOR)
                .lighten(1 - percent)
                .hex();
            }}
          />
        </DiagramWrapper>
      )}
    </Box>
  );
};

export const DAY_TIME_CARD_FRAGMENT = gql`
  fragment DayTimeCard on wma_metadata {
    acceptance_date
  }
`;

const TOOLTIP_MESSAGE = `
Diese detaillierte Zeitachse zeigt Ihnen nicht nur auf, an welchen Tagen{' '} <br />
die meisten Abrufe getätigt wurden (horizontale Achse), sondern auch zu welcher Uhrzeit (vertikale Achse). <br />
Unser Tipp: <br />
Hieraus lassen sich Schlüsse für diverse Marketingmaßnahmen ziehen. <br />
Zum Beispiel an welchen Wochentagen Sie zu welcher Uhrzeit das Frontend Tool{' '} <br />
besonders präsent platzieren sollten, da Ihre Interessenten zu diesem Zeitpunkt besonders aktiv sind. <br />
Oder auch, wann sie typischerweise mehr oder weniger Personal am Telefon benötigen.
`;
const CHART_COLOR = CHART_COLORS[2];

export default DayTimeCard;
