import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/css/animate.css';
import './assets/css/style.css';
import './index.css';
import { EncryptedStorageCache } from './utils/encrypted-storage-cache';
import getEnv from './utils/get-env';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Auth0Provider
        domain={getEnv('REACT_APP_AUTH_DOMAIN') || ''}
        clientId={getEnv('REACT_APP_AUTH_CLIENT_ID') || ''}
        redirectUri={window.location.origin}
        audience={getEnv('REACT_APP_AUTH_AUDIENCE')}
        useRefreshTokens
        cache={new EncryptedStorageCache()}
      >
        <App />
      </Auth0Provider>
    </React.StrictMode>
  );
} else {
  console.error('Failed to find the root element.');
}
