import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import SELECT_PROPS from './select-props';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { TestID } from '../../utils/constants/DataTestId';

type TimeRangeSelectProps = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  setStartDate: (startDate: moment.Moment) => void;
  setEndDate: (endDate: moment.Moment) => void;
  setStartDateLabel?: (label: string) => void;
  setEndDateLabel?: (label: string) => void;
};

const TimeRangeSelect: FC<TimeRangeSelectProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setStartDateLabel,
  setEndDateLabel,
}) => {
  useEffect(
    () => setStartDateLabel?.(startDate.format('DD.MM.YYYY')),
    [startDate]
  );
  useEffect(() => setEndDateLabel?.(endDate.format('DD.MM.YYYY')), [endDate]);

  const handleTimeRangeChange = (timeRange?: TimeRange) => {
    const now = moment();
    switch (timeRange) {
      case 'THIS_YEAR':
        setStartDate(now.clone().startOf('year'));
        setEndDate(now.clone().endOf('year').startOf('day'));
        break;
      case 'THIS_MONTH':
        setStartDate(now.clone().startOf('month'));
        setEndDate(now.clone().endOf('month').startOf('day'));
        break;
      case 'THIS_WEEK':
        setStartDate(now.clone().startOf('week'));
        setEndDate(now.clone().endOf('week').startOf('day'));
        break;
      case 'LAST_YEAR':
        setStartDate(now.clone().startOf('year').subtract(1, 'years'));
        setEndDate(now.clone().startOf('year').subtract(1, 'days'));
        break;
      case 'LAST_MONTH':
        setStartDate(now.clone().startOf('month').subtract(1, 'months'));
        setEndDate(now.clone().startOf('month').subtract(1, 'days'));
        break;
      case 'LAST_WEEK':
        setStartDate(now.clone().startOf('week').subtract(1, 'weeks'));
        setEndDate(now.clone().startOf('week').subtract(1, 'days'));
        break;
    }
  };

  return (
    <>
      <div className="mb-2" data-testid={TestID.TimePeriodSelect}>
        <label>Zeitraum</label>
        <Select<TimeRangeOption>
          {...SELECT_PROPS<TimeRangeOption>()}
          placeholder="Zeitraum auswählen"
          options={TIME_RANGE_OPTIONS}
          onChange={(value) => handleTimeRangeChange(value?.value)}
        />
      </div>
      <div className="mb-2">
        <Row>
          <Col lg={6} data-testid={TestID.StartTimePeriod}>
            <label>Von</label>
            <DatePicker
              className="form-control"
              selected={startDate.toDate()}
              onChange={(date) => {
                if (date instanceof Date) {
                  setStartDate(moment(date).startOf('day'));
                }
              }}
              dateFormat="dd.MM.yyyy"
            />
          </Col>
          <Col lg={6} data-testid={TestID.EndTimePeriod}>
            <label>Bis</label>
            <DatePicker
              data-testid={TestID.EndTimePeriod}
              className="form-control"
              selected={endDate.toDate()}
              onChange={(date) => {
                if (date instanceof Date) {
                  setEndDate(moment(date).startOf('day'));
                }
              }}
              minDate={startDate.toDate()}
              dateFormat="dd.MM.yyyy"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

type TimeRange =
  | undefined
  | 'THIS_YEAR'
  | 'THIS_MONTH'
  | 'THIS_WEEK'
  | 'LAST_YEAR'
  | 'LAST_MONTH'
  | 'LAST_WEEK';

type TimeRangeOption = { value: TimeRange; label: string };

const TIME_RANGE_OPTIONS: TimeRangeOption[] = [
  { value: 'THIS_YEAR', label: 'Dieses Jahr' },
  { value: 'THIS_MONTH', label: 'Dieser Monat' },
  { value: 'THIS_WEEK', label: 'Diese Woche' },
  { value: 'LAST_YEAR', label: 'Letztes Jahr' },
  { value: 'LAST_MONTH', label: 'Letzter Monat' },
  { value: 'LAST_WEEK', label: 'Letzte Woche' },
];

export default TimeRangeSelect;
