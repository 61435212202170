import { Line } from '@ant-design/charts';
import { MarkerCfg } from '@antv/g2/lib/interface';
import gql from 'graphql-tag';
import moment from 'moment';
import { FC, useMemo } from 'react';
import {
  APPARTMENT_COLOR,
  APPARTMENT_LABEL,
  HOUSE_COLOR,
  HOUSE_LABEL,
  isAppartment,
  isHouse,
  isMultiHouse,
  isRentalAppartment,
  MULTI_HOUSE_COLOR,
  MULTI_HOUSE_LABEL,
  ObjectTypeInput,
  RENTAL_APPARTMENT_COLOR,
  RENTAL_APPARTMENT_LABEL,
} from '../../utils/object-type';
import Box from '../Box';

import DiagramWrapper from '../DiagramWrapper';
import Loading from '../Loading';
import { makeSquareMarker, TOTAL_COLOR } from '../../utils/chart-colors';
// eslint-disable-next-line import/no-unresolved
import { ObjectTypeTimeCardFragment } from './__generated__/ObjectTypeTimeCard';

type ObjectTypeTimeCardProps = {
  fragments: (ObjectTypeTimeCardFragment | undefined)[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  size?: number;
  loading?: boolean;
};

type ObjectTypeTime = ObjectTypeInput & { time?: string };

type ObjectTypeAggregate = {
  label: string;
  date: string;
  count: number;
};

const ObjectTypeTimeCard: FC<ObjectTypeTimeCardProps> = ({
  fragments,
  startDate,
  endDate,
  size = 12,
  loading,
}) => {
  const objectTypeTimes = useMemo(
    (): ObjectTypeTime[] =>
      fragments.map(
        (fragment): ObjectTypeTime => ({
          marketingType: fragment?.input?.marketing_type || undefined,
          objectType: fragment?.input?.object_type || undefined,
          time: fragment?.acceptance_date,
        })
      ),
    [fragments]
  );

  const data = useMemo((): ObjectTypeAggregate[] => {
    const dayCount = Math.max(endDate.diff(startDate, 'days') + 1, 1);

    const nestedData = Array.from(Array(dayCount).keys()).map((dayDiff) => {
      const date = startDate.clone().add(dayDiff, 'days').format('ll (dd)');
      return {
        total: { label: 'Gesamt', date, count: 0 },
        rentalAppartment: { label: RENTAL_APPARTMENT_LABEL, date, count: 0 },
        appartment: { label: APPARTMENT_LABEL, date, count: 0 },
        house: { label: HOUSE_LABEL, date, count: 0 },
        multiHouse: { label: MULTI_HOUSE_LABEL, date, count: 0 },
      };
    });

    objectTypeTimes.forEach((objectTypeTime) => {
      if (objectTypeTime.time === undefined) {
        return;
      }
      const dayDiff = moment(objectTypeTime.time).diff(
        moment(startDate),
        'days'
      );
      if (isRentalAppartment(objectTypeTime)) {
        nestedData[dayDiff].total.count++;
        nestedData[dayDiff].rentalAppartment.count++;
      }
      if (isAppartment(objectTypeTime)) {
        nestedData[dayDiff].total.count++;
        nestedData[dayDiff].appartment.count++;
      }
      if (isHouse(objectTypeTime)) {
        nestedData[dayDiff].total.count++;
        nestedData[dayDiff].house.count++;
      }
      if (isMultiHouse(objectTypeTime)) {
        nestedData[dayDiff].total.count++;
        nestedData[dayDiff].multiHouse.count++;
      }
    });

    return nestedData
      .map(({ total, rentalAppartment, appartment, house, multiHouse }) => [
        total,
        house,
        appartment,
        multiHouse,
        rentalAppartment,
      ])
      .flatMap((value) => value);
  }, [objectTypeTimes, startDate, endDate]);

  return (
    <Box
      size={size}
      title="Abrufe nach Objekttyp im zeitlichen Verlauf"
      tooltipMessage={TOOLTIP_MESSAGE}
    >
      {loading && <Loading />}
      {!loading && (
        <DiagramWrapper>
          <Line
            style={{ height: 300, marginBottom: 30 }}
            data={data}
            // isGroup
            xField="date"
            yField="count"
            seriesField="label"
            legend={{
              marker: (_name, _index, item) =>
                makeSquareMarker(item as MarkerCfg),
              position: 'bottom',
              flipPage: false,
              itemName: {
                style: { fontSize: 14 },
              },
            }}
            color={[
              TOTAL_COLOR,
              HOUSE_COLOR,
              APPARTMENT_COLOR,
              MULTI_HOUSE_COLOR,
              RENTAL_APPARTMENT_COLOR,
            ]}
          />
        </DiagramWrapper>
      )}
    </Box>
  );
};

export const OBJECT_TYPE_TIME_CARD_FRAGMENT = gql`
  fragment ObjectTypeTimeCard on wma_metadata {
    acceptance_date
    input {
      id: wma_id
      marketing_type
      object_type: object_data__category
    }
  }
`;

const TOOLTIP_MESSAGE = `
Auf dieser Zeitachse werden die einzelnen Objekttypen nach Häufigkeit der Abrufe angezeigt <br />
– wann wurde welcher Typ (Haus, Eigentumswohnung, Mietwohnung oder Mehrfamilienhaus) am häufigsten aufgerufen?
`;
export default ObjectTypeTimeCard;
