import { useAuth0 } from '@auth0/auth0-react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import jwtDecode from 'jwt-decode';
import { FC, ReactNode, useState } from 'react';
import { Row } from 'react-bootstrap';
import {
  FaBars,
  FaChevronUp,
  FaRoad,
  FaSignOutAlt,
  FaUser,
} from 'react-icons/fa';
import { ScrollTo } from 'react-scroll-to';
import Tour from 'reactour';
import useAccessToken from '../utils/use-access-token';
import { Navigation } from './Navigation';

type LayoutProps = {
  title?: string;
  children?: ReactNode;
};

const Layout: FC<LayoutProps> = ({ title, children }) => {
  const { logout } = useAuth0();
  const { accessToken } = useAccessToken();
  const userName = getUserName(accessToken);

  const [isTourOpen, setIsTourOpen] = useState(false);

  const handleMenuToggle = () => {
    const cls = 'mini-navbar';

    if (document.body.className.indexOf(cls) > -1) {
      document.body.className = document.body.className.replace(cls, '');
    } else {
      document.body.className = `${document.body.className} mini-navbar`;
    }
  };

  return (
    <div id="wrapper">
      <Tour
        steps={TOUR_STEPS}
        isOpen={isTourOpen}
        onAfterOpen={(target) => disableBodyScroll(target)}
        onBeforeClose={(target) => enableBodyScroll(target)}
        onRequestClose={() => setIsTourOpen(false)}
      />
      <nav className="navbar-default navbar-static-side" role="navigation">
        <div className="sidebar-collapse">
          <Navigation />
          <div
            style={{
              position: 'fixed',
              bottom: 20,
              left: 20,
              width: '10%',
            }}
          >
            <ScrollTo>
              {({ scrollTo }) => (
                <a
                  onClick={() =>
                    scrollTo({
                      x: 0,
                      y: 0,
                      smooth: true,
                    })
                  }
                >
                  <FaChevronUp size={24} className="text-white" />
                </a>
              )}
            </ScrollTo>
          </div>
        </div>
      </nav>

      <div id="page-wrapper" className="gray-bg">
        <Row
          className="border-bottom"
          style={{ marginRight: -15, marginLeft: -15 }}
        >
          <nav
            className="navbar navbar-static-top white-bg"
            role="navigation"
            style={{ marginBottom: 0 }}
          >
            <div className="navbar-header">
              <span
                id="toggleNav"
                className="navbar-minimalize minimalize-styl-2 btn btn-primary"
                onClick={handleMenuToggle}
              >
                <FaBars />
              </span>
            </div>
            <h1>{title}</h1>
            <ul className="nav navbar-top-links navbar-right">
              <li>
                <a
                  style={{ textDecoration: 'none' }}
                  href="http://portal.iib-institut.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaUser /> {userName}
                </a>
              </li>
              {window.location.pathname.search('reporting') !== -1 &&
                window.location.pathname.search('reporting_') === -1 && (
                  <li>
                    <a onClick={() => setIsTourOpen(true)}>
                      <FaRoad /> Tour starten
                    </a>
                  </li>
                )}
              <li>
                <a onClick={() => logout({ returnTo: window.location.origin })}>
                  <FaSignOutAlt /> Abmelden
                </a>
              </li>
            </ul>
          </nav>
        </Row>
        <div className="wrapper wrapper-content animated fadeInRight">
          {children}
        </div>
      </div>
    </div>
  );
};

const TOUR_STEPS = [
  {
    selector: '#overviewSite',
    content:
      'Willkommen auf der Startseite des ErfolgsCockpits. Hier finden Sie alle wichtigen Daten und Auswertungen auf einen Blick.',
  },
  {
    selector: '#toggleNav',
    content:
      'Mit dem Klick auf dieses Icon öffnet oder schließt sich die Navigationsleiste.',
  },
  {
    selector: '#statsFilter',
    content:
      'Legen Sie hier den Zeitraum fest, den Sie auswerten möchten. Hierzu können Sie unter ‚Zeitraum‘ eine voreingestellte Zeitspanne (1 Woche, 1 Monat,...) auswählen, oder in den Eingabefeldern ‚Von‘ ‚Bis‘ einen individuellen Zeitraum eingeben. Die Änderung hat Auswirkungen auf alle Grafiken dieser Seite.',
  },
  {
    selector: '#overviewRow',

    content:
      'Diese Grafiken und Schaubilder geben Ihnen detaillierte Informationen über die Abrufe in dem von Ihnen gesetzten Zeitraum. Genauere Informationen zu den einzelnen Schaubildern erhalten Sie, wenn Sie über das (I-Symbol) fahren.',
  },
  {
    selector: '#wAnalysis',
    content:
      'Hier beantworten weitere Statistiken die „W-Fragen“: Wann, Was, Wie, Wer, Wo. Nähere Informationen finden Sie auch hier über den i-Button.',
  },
  {
    selector: '#timing',
    content:
      'Unter diesem Reiter finden Sie Analysen über Ihre Abarbeitungszeiten – wie lange benötigen Sie und Ihre Kollegen, bis ein Kunde angerufen wird? Und wie viele Langfassungen wurden hierzu heruntergeladen?',
  },
  {
    selector: '#wohnmarktanalysen',
    content:
      'Hier finden Sie die gesamte Abruferliste des von Ihnen gefilterten Zeitraums. Sie können diese Liste neu filtern, via Excel exportieren, oder direkt in der Liste arbeiten. Über das „>“-Icon erhalten Sie mehr Informationen zu dem Kunden. Sie können jedoch auch direkt auf „Telefon Icon“ die Telefonnummer ermitteln und den Kunden anrufen. Zusätzlich bekommen Sie die wichtigsten Informationen über den Abrufer. Nach dem Telefonat können Sie Notizen zu dem Gespräch eintragen, den Kunden an das CRM übergeben oder den Status der Wohnmarktanalyse ändern und vieles mehr...',
  },
  {
    selector: '#kampagnenfilter',
    content:
      'Im Kampagnenfilter können Sie nach verschiedenen Sanierungsgraden filtern und sich beispielsweise nur jene Objekte anzeigen lassen, die sanierungsbedürftig sind – eine tolle Möglichkeit, den Kunden einen Finanzierungsplan zur Sanierung seiner Immobilie vorzuschlagen.',
  },
  {
    selector: '#onboarding',
    content:
      'Die wichtigsten Aufgaben für einen erfolgreichen Start mit der Wohnmarktanalyse sind hier aufgelistet. Diese können nach Zuständigkeitsbereich gefiltert werden. So haben Sie den gesamten Onboarding Prozess immer im Blick.',
  },
];

type UserNameToken = {
  'https://iib-it.de/email'?: string;
};

const getUserName = (accessToken?: string) => {
  if (!accessToken) return '';

  const decoded = jwtDecode<UserNameToken>(accessToken);
  return decoded['https://iib-it.de/email'];
};

export default Layout;
