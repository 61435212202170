import React, { FC } from 'react';
import CustomCheckbox from '../CustomCheckbox';

type HasUserConsentCheckProps = {
  hasUserConsent?: true; // yes, that is correct
  setHasUserConsent: (hasUserConsent?: true) => void;
};

const HasUserConsentCheck: FC<HasUserConsentCheckProps> = ({
  hasUserConsent,
  setHasUserConsent,
}) => {
  return (
    <div className="mb-2">
      <label>Marketingmaßnahmen</label>
      <CustomCheckbox
        label="Zeige nur Daten mit erteilter Werbeeinwilligung"
        checked={!!hasUserConsent}
        onCheck={(checked) =>
          checked ? setHasUserConsent(true) : setHasUserConsent(undefined)
        }
      />
    </div>
  );
};

export default HasUserConsentCheck;
