import Layout from '../components/Layout';
import * as S from './styles/ServiceKontakt';
import React, { FC } from 'react';

export const Servicekontakt: FC = () => {
  return (
    <Layout title="Servicekontakt">
      <S.Wrapper>
        <S.Card>
          <p>
            <b>Bei Fragen zum ErfolgsCockpit helfen wir Ihnen gerne</b>
          </p>
          <br />
          <br />
          <div style={{ display: 'flex' }}>
            <b>E-Mail:</b>
            <p>&nbsp;&nbsp; service@iib-institut.de</p>
          </div>
          <div style={{ display: 'flex' }}>
            <b>Telefon:</b>
            <p>&nbsp;06202 / 95 049 - 10</p>
          </div>
        </S.Card>
      </S.Wrapper>
    </Layout>
  );
};
